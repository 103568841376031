import type { Offer, WithContext, Thing } from 'schema-dts'
import {
  type BreadcrumbItem,
  type Price as BapiPrice,
  type Product as BapiProduct,
  type Value,
  type Product,
  type Variant,
  type Price,
} from '@scayle/storefront-nuxt'
import type { NuxtApp } from 'nuxt/app'
import type { MetaObject } from 'nuxt/schema'
import { flattenCategories } from '../rpcMethods'
import { toLocaleCurrency, toLocaleDateTimeString } from './locale'
import { getGrossPrice } from './price'
import {
  getFirstProductImage,
  getPdpPageTitle,
  getPdpPageDescription,
  getProductAttributeValue,
} from './product'

export interface SeoSocialCards {
  twitterCard?: string
  twitterTitle?: string
  twitterImage?: string
  ogType?: string
  ogTitle?: string
  ogDescription?: string
  ogImage?: string
}

const CANONICAL_PARAM_WHITELIST = [
  'page',
  'sort',
  'size',
  'brand',
  'color',
  'minPrice',
  'maxPrice',
  'categoryShopFilterSizes',
]

/**
 * Strips off all parameters (except page) from input canonical url string
 * @param url canonical
 * @returns url string
 */
export const sanitizeCanonical = (url: string | undefined): string => {
  if (!url) {
    return ''
  }
  const baseAndParams = url?.split('?')
  const baseUrl = baseAndParams?.[0]
  const parametersPart = baseAndParams?.[1] // part of url after '?'

  if (!parametersPart) {
    // no url parameters exist, so return the original url
    return url
  }
  const parametersAndValues = parametersPart.split('&')

  // For each key=value combination, check for page and filter attribute. Return the combination if found
  return `${baseUrl}?${parametersAndValues
    .filter((attribute) => !attribute.toLocaleLowerCase().includes('page=1'))
    .join('&')}`
}

/**
 * Prepares a canonical URL by removing unwanted parameters
 * @param url The URL to prepare
 * @returns The prepared URL
 */
export function prepareCanonicalURL(url: string): string {
  const [baseUrl, queryString] = url.split('?')
  if (!queryString) {
    return baseUrl
  }

  const paramStrings = queryString.split('&')

  const filteredParams = paramStrings.filter((paramString) => {
    const [paramName] = paramString.split('=')
    return CANONICAL_PARAM_WHITELIST.includes(paramName)
  })

  if (!filteredParams.length) {
    return baseUrl
  }
  return baseUrl + '?' + filteredParams.join('&')
}

export const generateSchemaProductOffers = ({
  price,
  isInStock = false,
  priceCurrency = 'EUR',
}: {
  price: string
  isInStock: boolean
  priceCurrency?: string
}): Offer => {
  return {
    '@type': 'Offer',
    priceCurrency,
    price,
    availability: isInStock
      ? 'https://schema.org/InStock'
      : 'https://schema.org/OutOfStock',
    ...(isInStock && { itemCondition: 'https://schema.org/NewCondition' }),
  }
}

/**
 * Constructs the MetaInfo object to be used in the Vue components
 * @returns head object
 * @param content
 */
export const seoFormatter = (content: {
  description?: string
  robots?: string
  canonical?: string
  socialCards?: SeoSocialCards
}) => {
  const meta = []
  const link = []

  if (content.description) {
    meta.push({
      name: 'description',
      content: content.description,
      hid: 'description',
    })
  }

  if (content.socialCards) {
    Object.entries(content.socialCards).forEach(([key, value]) => {
      const metaDescriptionPrefix = key.includes('twitter') ? 'twitter' : 'og'
      const name = key.toLocaleLowerCase().replace(metaDescriptionPrefix, '')
      const hid =
        metaDescriptionPrefix === 'twitter' ? `t-${name}` : `og-${name}`

      meta.push({
        name: `${metaDescriptionPrefix}:${name}`,
        content: value,
        hid,
      })
    })
  }

  if (content.robots) {
    meta.push({ name: 'robots', content: content.robots })
  }

  const sanitizedCanonical = sanitizeCanonical(content.canonical)
  if (sanitizedCanonical) {
    link.push({
      rel: 'canonical',
      key: 'canonical',
      href: String(sanitizedCanonical),
    })
  }

  return {
    meta,
    link,
  }
}

export type FimSeoTwitterCard = {
  twitterCard?: string
  twitterTitle?: string
  twitterImage?: string
  ogType?: string
  ogTitle?: string
  ogDescription?: string
  ogImage?: string
  twitterSite?: string
  twitterCreator?: string
  twitterDescription?: string
  twitterData1?: string
  twitterLabel1?: string
  twitterData2?: string
  twitterLabel2?: string
}

export const getMetaTagsFromProduct = (
  metaDescription: string,
  product: BapiProduct,
  currentShop: NuxtApp['$currentShop'],
  i18n: NuxtApp['$i18n'],
  imageBaseUrl: string,
  url: string,
  price?: BapiPrice,
  metaTitle?: string,
) => {
  const grossPrice = price ? getGrossPrice(price) : undefined
  const productPrice = price
    ? toLocaleCurrency(price?.withTax, currentShop)
    : undefined

  const productTitleLabel = product.attributes?.name?.values as
    | Value
    | undefined
  const productTitle = metaTitle ?? productTitleLabel?.label
  const categoryName = product.categories?.[0]?.[0]?.categoryName
  const productColorLabel = product.attributes?.frameColor?.values as
    | Value
    | undefined
  const productColor = productColorLabel?.label
  const currentDate = toLocaleDateTimeString(new Date(), currentShop)

  const twitterTitle = getPdpPageTitle(product, i18n)

  const twitterDescription = [
    productTitle,
    categoryName,
    productColor,
    productPrice,
    currentDate,
  ]
    .filter(Boolean)
    .join(' – ')
  const twitterData1 = productPrice
  const twitterData2 = productColor

  const images = product?.images.map((i) =>
    normalizeUrl(i.hash, imageBaseUrl).toString(),
  )

  const metaTags = metaTagGenerator({
    description: metaDescription,
    robots: 'index,follow',
    canonical: url,
    fimSocialCards: {
      twitterCard: i18n.t('pdp.seo.twitter_cards.product'),
      twitterSite: i18n.t('pdp.seo.twitter_cards.creator'),
      twitterCreator: i18n.t('pdp.seo.twitter_cards.creator'),
      twitterTitle,
      twitterDescription,
      twitterImage: normalizeUrl(
        getFirstProductImage(product).hash,
        imageBaseUrl,
      ).toString(),
      twitterData1,
      twitterLabel1: i18n.t('pdp.seo.twitter_cards.price'),
      ...(!!twitterData2 && { twitterData2 }),
      ...(!!twitterData2 && {
        twitterLabel2: i18n.t('pdp.seo.twitter_cards.color'),
      }),
    },
  })

  return {
    ...metaTags,
    meta: [
      ...(metaTags?.meta || []),
      ...generateOpenGraph({
        productName: productTitle || '',
        images,
        url,
        metaDescription,
        locale: currentShop.locale,
        price: grossPrice,
        currency: currentShop.currency,
      }),
    ],
  }
}

export interface IBreadcrumbItem {
  name: string
  item: string // url
}

export interface IBreadcrumbSchema {
  '@context': 'https://schema.org'
  '@type': 'BreadcrumbList'
  itemListElement: Array<
    IBreadcrumbItem & { '@type': 'ListItem'; position: number }
  >
}

export const metaTagGenerator = (content: {
  description?: string
  robots?: string
  canonical?: string
  fimSocialCards?: FimSeoTwitterCard
}) => {
  return seoFormatter({
    description: content.description,
    robots: content.robots,
    canonical: content.canonical,
    socialCards: content.fimSocialCards,
  })
}

export const getUrl = (domain: string, path: string) => {
  const baseUrl = domain.includes('://') ? domain : getProtocol() + domain
  const pathWithSlash = path.slice(-1) === '/' ? path : path + '/'
  const url = new URL(pathWithSlash, baseUrl)
  return url.toString()
}

export const generatePlpBreadcrumbSchema = (
  context: any,
): IBreadcrumbSchema => {
  if (!context) {
    return {} as IBreadcrumbSchema
  }
  const breadcrumb = []

  const { selectedCategory, categories, currentShop } = context
  if (!selectedCategory || !categories || !currentShop) {
    return {} as IBreadcrumbSchema
  }

  const { name, path, rootlineIds, id } = selectedCategory

  breadcrumb.push({
    name: categories.name,
    item: getUrl(currentShop.domain, categories.path),
  })

  const filteredRootlineIds = rootlineIds.filter(
    (rootlineId: number) => rootlineId !== id,
  )
  const flattenedChildren = flattenCategories(categories.children)
  flattenedChildren
    .filter((category) => filteredRootlineIds.includes(category.id))
    .forEach((category) => {
      breadcrumb.push({
        name: category.name,
        item: getUrl(currentShop.domain, category.path),
      })
    })

  if (name !== categories.name) {
    breadcrumb.push({
      name,
      item: getUrl(currentShop.domain, path),
    })
  }

  return generateBreadcrumbSchema(breadcrumb)
}

export const generateCmsBreadcrumbSchema = (
  context: any,
): IBreadcrumbSchema => {
  if (!context?.story) {
    return {} as IBreadcrumbSchema
  }

  const { story, currentShop, i18n } = context
  const { full_slug: fullSlug } = story

  const excludeFromPath = [...i18n.localeCodes, 'content', '']
  const cleanPath = fullSlug
    .split('/')
    .filter((i: string) => !excludeFromPath.includes(i))

  const breadcrumb = cleanPath.reduce(
    (result: IBreadcrumbItem[], pathItem: string): IBreadcrumbItem[] => {
      const previousPath = result.length
        ? result[result.length - 1].item
        : getUrl(currentShop.domain, '/')

      return [
        ...result,
        {
          name: (
            pathItem.charAt(0).toUpperCase() + pathItem.slice(1)
          ).replaceAll('-', ' '),
          item: `${previousPath}${pathItem}/`,
        },
      ]
    },
    [],
  )

  return generateBreadcrumbSchema(breadcrumb)
}

export const getBreadcrumbSchemaItems = (
  breadcrumbs: BreadcrumbItem[],
  domain: string,
  fullPath: string,
  metaTitle: string,
): IBreadcrumbItem[] => {
  const breadcrumb = breadcrumbs.map((i) => ({
    name: i.value,
    item: getUrl(domain, i.to),
  }))

  breadcrumb.push({
    name: metaTitle,
    item: getUrl(domain, fullPath),
  })

  return breadcrumb
}

export const generateBreadcrumbSchema = (
  items: IBreadcrumbItem[],
): IBreadcrumbSchema => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: items.map(({ name, item }, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name,
      item,
    })),
  }
}

export const getProtocol = () => {
  return process.env.NODE_ENV === 'development' ? 'http://' : 'https://'
}

export const generateOpenGraph = ({
  productName,
  images,
  url,
  metaDescription,
  locale,
  price,
  currency,
}: {
  productName: string
  images: string[]
  url: string
  metaDescription: string
  locale: string
  price?: string
  currency: string
}): { property: string; content: string }[] => {
  return [
    { property: 'og:type', content: 'og:product' },
    {
      property: 'og:title',
      content: productName,
    },
    {
      property: 'og:image:alt',
      content: productName,
    },
    {
      property: 'og:image',
      content: images[0],
    },
    { property: 'og:url', content: url },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:locale',
      content: locale,
    },
    {
      property: 'og:site_name',
      content: 'Fielmann',
    },
    ...(price
      ? [
          {
            property: 'product:price:amount',
            content: price,
          },
        ]
      : []),
    {
      property: 'product:price:currency',
      content: currency,
    },
  ]
}

export const getProductMetaTitle = (
  product: Product | undefined,
  variant: Variant | undefined,
  context: NuxtApp,
) => {
  const title = getProductAttributeValue(product, variant, 'metaTitle')
  if (title && title?.length > 0) {
    return title
  }

  return product ? getPdpPageTitle(product, context.$i18n) : ''
}

export const getProductMetaDescription = (
  product: Product | undefined,
  variant: Variant | undefined,
  context: NuxtApp,
) => {
  const description = getProductAttributeValue(
    product,
    variant,
    'metaDescription',
  ) as string
  if (description?.length > 0) {
    return description
  }

  return product ? getPdpPageDescription(product, context.$i18n) : ''
}

export const getProductMetaTags = (
  product: Product | undefined,
  variant: Variant | undefined,
  price: Price | undefined,
  context: NuxtApp,
) => {
  if (!product) {
    return {}
  }
  const url = getUrl(
    context.$currentShop.domain,
    context.$router.currentRoute.value.path,
  )

  const metaTitle = getProductMetaTitle(product, variant, context)
  const metaDescription = getProductMetaDescription(product, variant, context)

  return getMetaTagsFromProduct(
    metaDescription,
    product,
    context.$currentShop,
    context.$i18n,
    context.$config.public.imageBaseUrl,
    url,
    price,
    metaTitle,
  )
}
export const getProductHead = (
  product: Product | undefined,
  variant: Variant | undefined,
  price: Price | undefined,
  context: NuxtApp,
): Omit<MetaObject, 'title'> & { title?: string } => {
  if (!product) {
    return {}
  }

  const metaTitle = getProductMetaTitle(product, variant, context)
  const metaTags = getProductMetaTags(product, variant, price, context)

  return {
    title: metaTitle,
    ...metaTags,
  }
}

export const generateProductSchema = (
  product: Product | undefined,
  variant: Variant | undefined,
  price: Price | undefined,
  context: NuxtApp,
): WithContext<Thing> => {
  if (!product) {
    return {} as WithContext<Thing>
  }

  const grossPrice = price ? getGrossPrice(price) : undefined

  const url = getUrl(
    context.$currentShop.domain,
    context.$router.currentRoute.value.path,
  )
  const images = product?.images.map((i) =>
    normalizeUrl(i.hash, context.$config.public.imageBaseUrl).toString(),
  )

  const isAvailable = isProductAvailable(product)

  const offers: Offer = {
    '@type': 'Offer',
    url,
    priceCurrency: context.$currentShop.currency,
    price: grossPrice,
    availability: isAvailable
      ? 'https://schema.org/InStock'
      : 'https://schema.org/OutOfStock',
    itemCondition: isAvailable ? 'https://schema.org/NewCondition' : undefined,
  }

  const metaDescription = getProductMetaDescription(product, variant, context)

  return {
    '@context': 'https://schema.org',
    '@type': 'Product',
    '@id': url,
    name: getFirstAttributeLabel(product.attributes, 'name'),
    image: images,
    description: metaDescription,
    brand: {
      '@type': 'Brand',
      name: getFirstAttributeLabel(product.attributes, 'brand'),
    },
    offers,
  }
}
