import validate from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45guard_45global from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/middleware/authGuard.global.ts";
import page_45type_45global from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/middleware/pageType.global.ts";
import trailing_45slash_45redirect_45global from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/middleware/trailingSlashRedirect.global.ts";
import manifest_45route_45rule from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45guard_45global,
  page_45type_45global,
  trailing_45slash_45redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}