import type {
  TrackingEvent,
  TrackingPayload,
  TrackContentViewEventParams,
} from '../trackingTypes'

const useContentViewEvents = (
  track: (
    event: TrackingEvent,
    payload: TrackingPayload & { element?: HTMLButtonElement },
  ) => any,
) => ({
  trackContentView: ({
    eventName,
    contentName,
    title,
    pageType,
    pageTypeId,
    clickOrigin,
    element,
  }: TrackContentViewEventParams) => {
    track(eventName, {
      content_name: contentName,
      title,
      page_type: pageType,
      page_type_id: pageTypeId,
      click_origin: clickOrigin,
      element,
    })
  },
})

export default useContentViewEvents
