import type { RpcMethodParameters } from '@scayle/storefront-core'
import type { SbBlokData } from '@storyblok/vue'
import type { StoryblokStory } from 'storyblok-generate-ts'
import type { AsyncDataOptions } from '#app'
import { CmsBaseFolder } from '~/constants/cms'
import type { SbPage } from '~/storyblok/types/storyblok.gen'

type Options<T extends SbBlokData = SbPage> = Partial<{
  params: MaybeRefOrGetter<Partial<RpcMethodParameters<'getCmsStory'>>>
  options?: AsyncDataOptions<StoryblokStory<T>>
  key: string
}>

export const useCmsStory = <T extends SbBlokData = SbPage>({
  params,
  options,
}: Options<T> = {}) => {
  const currentShop = useCurrentShop()

  const localizedBaseFolder = getLocalizedBaseFolder(
    currentShop.value,
    toValue(params)?.baseFolder || CmsBaseFolder.Content,
  )

  const { isInEditorMode } = useStoryblokHelpers()

  const rpcParams = {
    ignoreNotFound: true,
    ...toValue(params),
    baseFolder: localizedBaseFolder,
    isCmsPreview: isInEditorMode.value,
    slug: toValue(params)?.slug || '',
  }

  return useAsyncRpc<
    'getCmsStory',
    RpcMethodParameters<'getCmsStory'>,
    StoryblokStory<T>
  >('getCmsStory', rpcParams, { immediate: true, server: true, ...options })
}
