import type { Variant } from '@scayle/storefront-nuxt'
import type {
  FimCTATrackingPayload,
  FimTrackingEvent,
  FimTrackingPayload,
  MapToTrackingPayloadOptions,
  PageType,
} from '../trackingTypes'

const useCTAEvents = (
  track: (
    event: FimTrackingEvent,
    payload: FimTrackingPayload,
    options?: MapToTrackingPayloadOptions,
  ) => any,
) => {
  return {
    trackCTAClick: ({
      productDetails,
      variant,
      element,
      overridePageType,
      applicationId,
    }: FimCTATrackingPayload & {
      variant?: Variant
      applicationId?: string
      overridePageType?: PageType
    }) => {
      track(
        'FielmannBasic_CTAClick',
        {
          element,
          ...(!!productDetails && { productDetails }),
          ...(applicationId && { meta: { applicationId } }),
        },
        { variant, overridePageType },
      )
    },
  }
}

export default useCTAEvents
