import type { ValuesType } from 'utility-types'

/* intentionally naming the variables the same as the type */

export const Size = {
  '4XS': '4xs',
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
} as const

export type Size = ValuesType<typeof Size>

export const HeadlineSize = {
  '4XL': '4xl',
  '2XL': '2xl',
  XL: 'xl',
  LG: 'lg',
  MD: 'base',
  SM: 'sm',
  XS: 'xs',
} as const

export type HeadlineSize = ValuesType<typeof HeadlineSize>

export const isHeadlineSize = (input: any): input is HeadlineSize => {
  const values: string[] = Object.values(HeadlineSize)
  return typeof input === 'string' && values.includes(input)!
}

export const HeadlineTag = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  P: 'p',
  DIV: 'div',
  SPAN: 'span',
} as const

export type HeadlineTag = ValuesType<typeof HeadlineTag>

export const isHeadlineTag = (input: any): input is HeadlineTag => {
  const values: string[] = Object.values(HeadlineTag)
  return typeof input === 'string' && values.includes(input)!
}

export const toHeadlineTag = (input: any, defaultValue?: HeadlineTag) =>
  isHeadlineTag(input) ? input : defaultValue

export const HeadlineLook = {
  H1_6XL: 'h1-6xl',
  H1_5XL: 'h1-5xl',
  H1_4XL: 'h1-4xl',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  P: 'p',
  Custom: 'custom',
} as const

export type HeadlineLook = ValuesType<typeof HeadlineLook>

export const isHeadlineLook = (input: any): input is HeadlineLook => {
  const values: string[] = Object.values(HeadlineLook)
  return typeof input === 'string' && values.includes(input)!
}

export const toHeadlineLook = (look: any, defaultValue?: HeadlineLook) =>
  isHeadlineLook(look) ? look : defaultValue

export const FontWeightType = {
  NORMAL: 'normal',
  MEDIUM: 'medium',
  SEMIBOLD: 'semi-bold',
  BOLD: 'bold',
  DEFAULT: '',
} as const

export type FontWeightType = ValuesType<typeof FontWeightType>

export const isFontWeightType = (input: any): input is FontWeightType => {
  const values: string[] = Object.values(FontWeightType)
  return typeof input === 'string' && values.includes(input)!
}

export const toFontWeightType = (input: any, defaultValue?: FontWeightType) =>
  isFontWeightType(input) ? input : defaultValue

export const SkeletonType = {
  BUTTON: 'button',
  HEADLINE: 'headline',
  CUSTOM: 'custom',
} as const

export type SkeletonType = ValuesType<typeof SkeletonType>

export const LinkVariant = {
  LOUD: 'loud',
  NORMAL: 'normal',
  WHISPER: 'whisper',
  QUIETER: 'quieter',
  QUIET: 'quiet',
} as const

export type LinkVariant = ValuesType<typeof LinkVariant>

export const ButtonType = {
  PRIMARY: 'primary',
  PRIMARYALTERNATIVE: 'primary-alternative',
  SECONDARY: 'secondary',
  SECONDARYALTERNATIVE: 'secondary-alternative',
  TERTIARY: 'tertiary',
  TERTIARYALTERNATIVE: 'tertiary-alternative',
  GHOST: 'ghost',
  RAW: 'raw',
  PLAY_STORE: 'play-store',
  APP_STORE: 'app-store',
  LINK: 'link',
  LINK_ALTERNATIVE: 'link-alternative',
} as const

export type ButtonType = ValuesType<typeof ButtonType>

export const isButtonType = (input: any): input is ButtonType => {
  const values: string[] = Object.values(ButtonType)
  return typeof input === 'string' && values.includes(input)!
}

export const toButtonType = (input: any, defaultValue?: ButtonType) =>
  isButtonType(input) ? input : defaultValue

export const SlideInType = {
  DEFAULT: 'default',
  FROM_BOTTOM: 'fromBottom',
} as const

export type SlideInType = ValuesType<typeof SlideInType>

export const DividerItemTag = {
  DEFAULT_LINK: 'DefaultLink',
  NUXT_LINK: 'NuxtLink',
  PARAGRAPH: 'p',
} as const

export type DividerItemTag = ValuesType<typeof DividerItemTag>

export const ProgressType = {
  SUCCESS: 'success',
  WARN: 'warn',
  DANGER: 'danger',
  NEUTRAL: 'neutral',
} as const

export type ProgressType = ValuesType<typeof ProgressType>

// don't allowing "none" here as it's technically working differently as expected and used in storyblok
export const ObjectFit = {
  CONTAIN: 'contain',
  COVER: 'cover',
  FILL: 'fill',
  SCALE_DOWN: 'scale-down',
} as const

export type ObjectFit = ValuesType<typeof ObjectFit>

export const isObjectFit = (input: any): input is ObjectFit => {
  const values: string[] = Object.values(ObjectFit)
  return typeof input === 'string' && values.includes(input)!
}

export const toObjectFit = (input: any, defaultValue?: ObjectFit) =>
  isObjectFit(input) ? input : defaultValue

export const ObjectPosition = {
  CENTER: 'center',
  TOP: 'top',
  TOP_RIGHT: 'top-right',
  RIGHT: 'right',
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM: 'bottom',
  BOTTOM_LEFT: 'bottom-left',
  LEFT: 'left',
  TOP_LEFT: 'top-left',
} as const

export type ObjectPosition = ValuesType<typeof ObjectPosition>

export const isObjectPosition = (input: any): input is ObjectPosition => {
  const values: string[] = Object.values(ObjectPosition)
  return typeof input === 'string' && values.includes(input)!
}

export const toObjectPosition = (input: any, defaultValue?: ObjectPosition) =>
  isObjectPosition(input) ? input : defaultValue

export const PictureLayout = {
  INTRINSIC: 'intrinsic',
  FIXED: 'fixed',
  RESPONSIVE: 'responsive',
  FILL: 'fill',
  RAW: 'raw',
} as const

export type PictureLayout = ValuesType<typeof PictureLayout>

export const isPictureLayout = (input: any): input is PictureLayout => {
  const values: string[] = Object.values(PictureLayout)
  return typeof input === 'string' && values.includes(input)!
}

export const toPictureLayout = (input: any, defaultValue?: PictureLayout) =>
  isPictureLayout(input) ? input : defaultValue
