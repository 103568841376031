import { rxLensQualityRefKeys } from '~/constants/rx'
import { type FimRxConfigurationMapped } from '~/composables/tracking/trackingTypes'
import { ProductCategories } from '~/utils/product'

export const lensTypeReferenceKeyToTrackingValue: Record<
  string,
  FimRxConfigurationMapped['configuration']['prescriptionType']
> = {
  type_ei: 'Prescription Glasses',
  type_none: 'Non Prescription Glasses',
}

// Keep for future use
export const glassTypeReferenceKeyToTrackingValue: Record<
  string,
  FimRxConfigurationMapped['configuration']['glassType']
> = {
  [rxLensQualityRefKeys.ENTRY_NOCOAT]: 'Basic',
  [rxLensQualityRefKeys.OMNICHANNEL_ENTRY_NOCOAT]: 'Basic',
  [rxLensQualityRefKeys.COMPACT_ENTRY_NOCOAT]: 'Basic',

  [rxLensQualityRefKeys.ENTRY]: 'Basic Plus',
  [rxLensQualityRefKeys.OMNICHANNEL_ENTRY]: 'Basic Plus',
  [rxLensQualityRefKeys.COMPACT_ENTRY]: 'Basic Plus',

  [rxLensQualityRefKeys.STANDARD]: 'Smart',
  [rxLensQualityRefKeys.OMNICHANNEL_STANDARD]: 'Smart',
  [rxLensQualityRefKeys.COMPACT_STANDARD]: 'Smart',

  [rxLensQualityRefKeys.STANDARD_PLUS]: 'Select',
  [rxLensQualityRefKeys.OMNICHANNEL_STANDARD_PLUS]: 'Select',
  [rxLensQualityRefKeys.COMPACT_STANDARD_PLUS]: 'Select',

  [rxLensQualityRefKeys.PREMIUM]: 'Excellence',
  [rxLensQualityRefKeys.OMNICHANNEL_PREMIUM]: 'Excellence',
  [rxLensQualityRefKeys.COMPACT_PREMIUM]: 'Excellence',
}

export const tintingTypeReferenceKeyToTrackingValue: Record<
  string,
  FimRxConfigurationMapped['configuration']['extras']
> = {
  tinting_fbl: 'Plain',
  'tinting_fbl-shc': 'Plain',
  'tinting_fbl-busc': 'Blue Blocking',
  // Self-Tinting Lenses
  'tinting_pht-br85': 'Tinted | Brown',
  'tinting_pht-gr85': 'Tinted | Grey',
  // Polarizing Lenses
  'tinting_pol-br85': 'Tinted | Brown',
  'tinting_pol-gr85': 'Tinted | Grey',
  'tinting_pol-g185': 'Tinted | Green',
  // Sunglasses
  'tinting_col-br85': 'Tinted | Brown',
  'tinting_col-gr85': 'Tinted | Grey',
  'tinting_col-g185': 'Tinted | Green',
  // Filter Tint
  'tinting_fil-br': 'Tinted | Brown',
  'tinting_fil-gr': 'Tinted | Grey',
  'tinting_fil-g1': 'Tinted | Green',
}

// https://aboutyou.atlassian.net/wiki/spaces/AYC/pages/1174242134/AT+Tracking+Item+Category+Mappings
export const categoryTrackingAttributes: Record<
  keyof typeof ProductCategories,
  string[]
> = {
  [ProductCategories.glasses]: ['shape', 'sapMaterial', 'rimType'],
  [ProductCategories.readyMadeGlasses]: ['shape', 'sapMaterial', 'rimType'],
  [ProductCategories.sunglasses]: ['shape', 'sapMaterial', 'rimType'],
  [ProductCategories.contactLenses]: [
    'wearingTime',
    'productType',
    'materialClass',
    'colorEffect',
  ],
  [ProductCategories.careproducts]: [
    'eyeCareProductType',
    'suitableFor',
    'packingType',
    'preservativeFree',
  ],
  [ProductCategories.merch]: ['subCategory'],
  [ProductCategories.readingAidGlasses]: ['shape', 'sapMaterial', 'rimType'],
}

export const staticCategoryTrackingValues: Record<
  keyof typeof ProductCategories,
  string[]
> = {
  [ProductCategories.glasses]: ['glasses', 'rx glasses'],
  [ProductCategories.sunglasses]: ['glasses', 'sunglasses'],
  [ProductCategories.contactLenses]: ['contact lenses'],
  [ProductCategories.careproducts]: ['care products'],
  [ProductCategories.merch]: ['accessories'],
  [ProductCategories.readyMadeGlasses]: ['glasses', 'blue light glasses'],
  [ProductCategories.readingAidGlasses]: ['glasses', 'Reading Aid Glasses'],
}
