import { type RpcMethodParameters, rpcCall } from '@scayle/storefront-nuxt'
import { type NuxtApp } from 'nuxt/app'
import type { RouteLocationNormalizedLoaded } from '#vue-router'
import type { IStore } from '~/rpcMethods'

const search = ref<string>()
const searched = ref(false)
const dirty = ref(false)

const getSearchOptionFromRoute = (
  i18n: NuxtApp['$i18n'],
  route: RouteLocationNormalizedLoaded,
) => {
  const serviceTypeQuery = route.query.services as string
  return STORE_SERVICE_TYPES.includes(serviceTypeQuery)
    ? serviceTypeQuery
    : 'ALL'
}

export const useAllShopStores = () => {
  const nuxtApp = useNuxtApp()
  const wrappedCall = rpcCall(
    nuxtApp,
    'getAllShopStores',
    toValue(nuxtApp.$currentShop),
  )

  const countryCode = getIsoCountry(nuxtApp.$currentShop.locale)
  const key = `AllShopStores-${countryCode}`
  const {
    data,
    execute: fetch,
    status,
  } = useLazyAsyncData(key, () => wrappedCall())

  return {
    data,
    fetch,
    status,
  }
}

export function useStores(key = 'useStores') {
  const { data: dataRaw, fetching, fetch } = useFimRpc('getStores', key)

  const { $i18n, $featureToggle } = useNuxtApp()
  const route = useRoute()

  const searchOption = ref<string>(getSearchOptionFromRoute($i18n, route))
  const { ropoConfig } = $featureToggle

  const evaluating = ref(false)

  const data = computed(() => {
    return dataRaw.value as IStore[] | null
  })

  return {
    data,
    fetch,
    fetching: computed(() => fetching.value || evaluating.value),
    search,
    searched,
    dirty,
    searchOption,
    stores: computed(() => {
      return (data.value || []).map((store) => {
        return {
          ...store,
          isRopo:
            ropoConfig?.value?.enabled &&
            ropoConfig?.value?.supportedStores?.includes(store.recordsetID),
        }
      })
    }),
  }
}

type GetStoreOptions = Partial<{
  params: MaybeRefOrGetter<RpcMethodParameters<'getStore'>>
  options?: Parameters<typeof useRpc>[3]
  key: string
}>

export async function useStore({
  params,
  options,
  key = 'useStore',
}: GetStoreOptions = {}) {
  const { data, fetching, fetch, error } = await useRpc(
    'getStore',
    key,
    params,
    options,
  )

  return {
    data,
    fetching,
    fetch,
    error,
  }
}
