import {
  type IRxStepNameKey,
  type IRxStepNameValue,
} from '~/components/rx/RxConfigurator/RxConfigurator'
import { type ISightPrescriptionUnit } from '~/components/rx/SightPrescription/SightPrescriptionStep'
import { type FormattableLensesPrescriptionProp } from '~/utils/rx'
import { type IPrescriptionProp } from '~/utils/rxTypes'

export const rxLensProductMasterKeys = [
  'rx_tinting_types',
  'rx_lens_quality_index',
  'rx_lens_types',
  'rx_services',
]

export const rxLensQualityRefKeys = {
  ENTRY_NOCOAT: 'index_cr_nocoat',
  OMNICHANNEL_ENTRY_NOCOAT: 'omnichannel_index_cr_nocoat',
  COMPACT_ENTRY_NOCOAT: 'compact_index_cr_nocoat',

  ENTRY: 'index_cr',
  OMNICHANNEL_ENTRY: 'omnichannel_index_cr',
  COMPACT_ENTRY: 'compact_index_cr',

  STANDARD: 'index_ch',
  OMNICHANNEL_STANDARD: 'omnichannel_index_ch',
  COMPACT_STANDARD: 'compact_index_ch',

  STANDARD_PLUS: 'index_ad',
  OMNICHANNEL_STANDARD_PLUS: 'omnichannel_index_ad',
  COMPACT_STANDARD_PLUS: 'compact_index_ad',

  PREMIUM: 'index_ax',
  OMNICHANNEL_PREMIUM: 'omnichannel_index_ax',
  COMPACT_PREMIUM: 'compact_index_ax',
}

export const rxLensQualityHexCodes = {
  ENTRY_NOCOAT: '#E4F0FB',
  ENTRY: '#A4CDF3',
  STANDARD: '#4A9CE7',
  STANDARD_PLUS: '#0058A9',
  PREMIUM: '#0058A9',
  RECOMMENDED: '#FF922A',
}

export const rxLensQualityColors = {
  [rxLensQualityRefKeys.COMPACT_STANDARD_PLUS]:
    rxLensQualityHexCodes.STANDARD_PLUS,
  [rxLensQualityRefKeys.COMPACT_STANDARD]: rxLensQualityHexCodes.STANDARD,
  [rxLensQualityRefKeys.COMPACT_ENTRY]: rxLensQualityHexCodes.ENTRY,
  [rxLensQualityRefKeys.OMNICHANNEL_STANDARD_PLUS]:
    rxLensQualityHexCodes.STANDARD_PLUS,
  [rxLensQualityRefKeys.OMNICHANNEL_STANDARD]: rxLensQualityHexCodes.STANDARD,
  [rxLensQualityRefKeys.OMNICHANNEL_ENTRY]: rxLensQualityHexCodes.ENTRY,
  [rxLensQualityRefKeys.COMPACT_ENTRY_NOCOAT]: rxLensQualityHexCodes.ENTRY,
  [rxLensQualityRefKeys.STANDARD_PLUS]: rxLensQualityHexCodes.STANDARD_PLUS,
  [rxLensQualityRefKeys.STANDARD]: rxLensQualityHexCodes.STANDARD,
  [rxLensQualityRefKeys.ENTRY]: rxLensQualityHexCodes.ENTRY,
  [rxLensQualityRefKeys.PREMIUM]: rxLensQualityHexCodes.PREMIUM,
  [rxLensQualityRefKeys.OMNICHANNEL_PREMIUM]: rxLensQualityHexCodes.PREMIUM,
  [rxLensQualityRefKeys.COMPACT_PREMIUM]: rxLensQualityHexCodes.PREMIUM,
}

export const rxTintingRefKeys = {
  // deprecated, keep for existing orders
  CLEAR: 'tinting_fbl-shc',
  BLUE_LIGHT_FILTER: 'tinting_fbl-busc',
  TINTED: 'tinting_fil',
  SUNGLASSES: 'tinting_col',
  POLARIZED: 'tinting_pol',
  TRANSITION: 'tinting_pht',
  // nocoat
  ENTRY_NOCOAT_CLEAR: 'cr_nocoat_tinting_fbl',
  ENTRY_NOCOAT_BLUE_LIGHT_FILTER: 'cr_nocoat_tinting_fbl-busc',
  ENTRY_NOCOAT_TINTED: 'cr_nocoat_tinting_fil',
  ENTRY_NOCOAT_SUNGLASSES: 'cr_nocoat_tinting_col',
  ENTRY_NOCOAT_POLARIZED: 'cr_nocoat_tinting_pol',
  ENTRY_NOCOAT_TRANSITION: 'cr_nocoat_tinting_pht',

  // entry
  ENTRY_CLEAR: 'cr_tinting_fbl-shc',
  ENTRY_BLUE_LIGHT_FILTER: 'cr_tinting_fbl-busc',
  ENTRY_TINTED: 'cr_tinting_fil',
  ENTRY_SUNGLASSES: 'cr_tinting_col',
  ENTRY_POLARIZED: 'cr_tinting_pol',
  ENTRY_TRANSITION: 'cr_tinting_pht',

  // standard
  STANDARD_CLEAR: 'ch_tinting_fbl-shc',
  STANDARD_BLUE_LIGHT_FILTER: 'ch_tinting_fbl-busc',
  STANDARD_TINTED: 'ch_tinting_fil',
  STANDARD_SUNGLASSES: 'ch_tinting_col',
  STANDARD_POLARIZED: 'ch_tinting_pol',
  STANDARD_TRANSITION: 'ch_tinting_pht',

  // standard plus
  STANDARD_PLUS_CLEAR: 'ad_tinting_fbl-shc',
  STANDARD_PLUS_BLUE_LIGHT_FILTER: 'ad_tinting_fbl-busc',
  STANDARD_PLUS_TINTED: 'ad_tinting_fil',
  STANDARD_PLUS_SUNGLASSES: 'ad_tinting_col',
  STANDARD_PLUS_POLARIZED: 'ad_tinting_pol',
  STANDARD_PLUS_TRANSITION: 'ad_tinting_pht',

  // premium
  PREMIUM_CLEAR: 'ax_tinting_fbl-shc',
  PREMIUM_BLUE_LIGHT_FILTER: 'ax_tinting_fbl-busc',
  PREMIUM_TINTED: 'ax_tinting_fil',
  PREMIUM_SUNGLASSES: 'ax_tinting_col',
  PREMIUM_POLARIZED: 'ax_tinting_pol',
  PREMIUM_TRANSITION: 'ax_tinting_pht',
}

export const entryGlassIncompatibleRimtypes = [
  'rimless',
  'nylor',
  'inline_grooves',
  'randlos',
  'gerillt',
]

export const defaultLensQualityRefs = [
  rxLensQualityRefKeys.ENTRY,
  rxLensQualityRefKeys.STANDARD,
  rxLensQualityRefKeys.STANDARD_PLUS,
]

export const tintingOptionsFallback = [
  rxTintingRefKeys.CLEAR,
  rxTintingRefKeys.BLUE_LIGHT_FILTER,
  rxTintingRefKeys.TINTED,
  rxTintingRefKeys.SUNGLASSES,
  rxTintingRefKeys.POLARIZED,
  rxTintingRefKeys.TRANSITION,
]

export const tintingOptionsFilterMap = {
  [rxLensQualityRefKeys.ENTRY_NOCOAT]: [
    rxTintingRefKeys.ENTRY_NOCOAT_CLEAR,
    rxTintingRefKeys.ENTRY_NOCOAT_BLUE_LIGHT_FILTER,
    rxTintingRefKeys.ENTRY_NOCOAT_TINTED,
    rxTintingRefKeys.ENTRY_NOCOAT_SUNGLASSES,
    rxTintingRefKeys.ENTRY_NOCOAT_POLARIZED,
    rxTintingRefKeys.ENTRY_NOCOAT_TRANSITION,
  ],
  [rxLensQualityRefKeys.ENTRY]: [
    rxTintingRefKeys.ENTRY_CLEAR,
    rxTintingRefKeys.ENTRY_BLUE_LIGHT_FILTER,
    rxTintingRefKeys.ENTRY_TINTED,
    rxTintingRefKeys.ENTRY_SUNGLASSES,
    rxTintingRefKeys.ENTRY_POLARIZED,
    rxTintingRefKeys.ENTRY_TRANSITION,
  ],
  [rxLensQualityRefKeys.STANDARD]: [
    rxTintingRefKeys.STANDARD_CLEAR,
    rxTintingRefKeys.STANDARD_BLUE_LIGHT_FILTER,
    rxTintingRefKeys.STANDARD_TINTED,
    rxTintingRefKeys.STANDARD_SUNGLASSES,
    rxTintingRefKeys.STANDARD_POLARIZED,
    rxTintingRefKeys.STANDARD_TRANSITION,
  ],
  [rxLensQualityRefKeys.STANDARD_PLUS]: [
    rxTintingRefKeys.STANDARD_PLUS_CLEAR,
    rxTintingRefKeys.STANDARD_PLUS_BLUE_LIGHT_FILTER,
    rxTintingRefKeys.STANDARD_PLUS_TINTED,
    rxTintingRefKeys.STANDARD_PLUS_SUNGLASSES,
    rxTintingRefKeys.STANDARD_PLUS_POLARIZED,
    rxTintingRefKeys.STANDARD_PLUS_TRANSITION,
  ],
  [rxLensQualityRefKeys.PREMIUM]: [
    rxTintingRefKeys.PREMIUM_CLEAR,
    rxTintingRefKeys.PREMIUM_BLUE_LIGHT_FILTER,
    rxTintingRefKeys.PREMIUM_TINTED,
    rxTintingRefKeys.PREMIUM_SUNGLASSES,
    rxTintingRefKeys.PREMIUM_POLARIZED,
    rxTintingRefKeys.PREMIUM_TRANSITION,
  ],
}

tintingOptionsFilterMap[rxLensQualityRefKeys.COMPACT_ENTRY_NOCOAT] =
  tintingOptionsFilterMap[rxLensQualityRefKeys.ENTRY_NOCOAT]
tintingOptionsFilterMap[rxLensQualityRefKeys.OMNICHANNEL_ENTRY_NOCOAT] =
  tintingOptionsFilterMap[rxLensQualityRefKeys.ENTRY_NOCOAT]

tintingOptionsFilterMap[rxLensQualityRefKeys.COMPACT_ENTRY] =
  tintingOptionsFilterMap[rxLensQualityRefKeys.ENTRY]
tintingOptionsFilterMap[rxLensQualityRefKeys.OMNICHANNEL_ENTRY] =
  tintingOptionsFilterMap[rxLensQualityRefKeys.ENTRY]

tintingOptionsFilterMap[rxLensQualityRefKeys.COMPACT_STANDARD] =
  tintingOptionsFilterMap[rxLensQualityRefKeys.STANDARD]
tintingOptionsFilterMap[rxLensQualityRefKeys.OMNICHANNEL_STANDARD] =
  tintingOptionsFilterMap[rxLensQualityRefKeys.STANDARD]

tintingOptionsFilterMap[rxLensQualityRefKeys.COMPACT_STANDARD_PLUS] =
  tintingOptionsFilterMap[rxLensQualityRefKeys.STANDARD_PLUS]
tintingOptionsFilterMap[rxLensQualityRefKeys.OMNICHANNEL_STANDARD_PLUS] =
  tintingOptionsFilterMap[rxLensQualityRefKeys.STANDARD_PLUS]

tintingOptionsFilterMap[rxLensQualityRefKeys.COMPACT_PREMIUM] =
  tintingOptionsFilterMap[rxLensQualityRefKeys.PREMIUM]
tintingOptionsFilterMap[rxLensQualityRefKeys.OMNICHANNEL_PREMIUM] =
  tintingOptionsFilterMap[rxLensQualityRefKeys.PREMIUM]

export const FACTOR_ENTRY = '1.5'
export const FACTOR_STANDARD = '1.6'
export const FACTOR_STANDARD_PLUS = '1.67'

export const rxRecommendationMapping = {
  [FACTOR_ENTRY]: [
    rxLensQualityRefKeys.ENTRY,
    rxLensQualityRefKeys.OMNICHANNEL_ENTRY,
    rxLensQualityRefKeys.COMPACT_ENTRY,
  ],
  [FACTOR_STANDARD]: [
    rxLensQualityRefKeys.STANDARD,
    rxLensQualityRefKeys.OMNICHANNEL_STANDARD,
    rxLensQualityRefKeys.COMPACT_STANDARD,
  ],
  [FACTOR_STANDARD_PLUS]: [
    rxLensQualityRefKeys.STANDARD_PLUS,
    rxLensQualityRefKeys.OMNICHANNEL_STANDARD_PLUS,
    rxLensQualityRefKeys.COMPACT_STANDARD_PLUS,
  ],
}

export const RxStepNames: Record<IRxStepNameKey, IRxStepNameValue> = {
  PRESCRIPTION_TYPE: 'Prescription Type',
  PRESCRIPTION_VALUES: 'Prescription Values',
  GLASS_TYPE: 'Glass Type',
  EXTRAS: 'Extras',
  SUMMARY: 'Summary',
}

export const rxLoadingAnimationDuration = 0.4

export const prescriptionFormatter: Record<
  FormattableLensesPrescriptionProp,
  Intl.NumberFormatOptions
> = {
  sphere: {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    signDisplay: 'exceptZero',
    style: 'decimal',
  },
  cylinder: {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    signDisplay: 'exceptZero',
    style: 'decimal',
  },
  axis: {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    signDisplay: 'never',
    style: 'unit',
    unit: 'degree',
  },
  pd: {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
    signDisplay: 'never',
    style: 'unit',
    unit: 'millimeter',
  },
  addition: {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    signDisplay: 'never',
    style: 'decimal',
  },
  baseCurve: {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
    signDisplay: 'never',
    style: 'decimal',
  },
  diameter: {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
    signDisplay: 'never',
    style: 'decimal',
  },
}

export const prescriptionPropUnits: Record<
  IPrescriptionProp,
  ISightPrescriptionUnit
> = {
  axis: '°',
  sphere: 'dpt',
  cylinder: 'dpt',
  pd: 'mm',
}

// fake id to be used in getProductConfigRxItems to identify
// the frame price later when calculating the product price
export const RX_CONFIGURATION_ITEM_FRAME_ID = -1
