import { default as _91_46_46_46category_93wajVM5mVB2Meta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/[...category].vue?macro=true";
import { default as appointmentsnQe25JyT3jMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/appointments.vue?macro=true";
import { default as _91id_93vGVk2DH5NXMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/[id].vue?macro=true";
import { default as indexN3BTxtD5udMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/index.vue?macro=true";
import { default as index9ANNBGI2VAMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/index.vue?macro=true";
import { default as _91id_93kha0WvJKaZMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/[id].vue?macro=true";
import { default as indexIGobx1FVypMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/index.vue?macro=true";
import { default as userKpi6tdnpgTMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/user.vue?macro=true";
import { default as basketmMz9qgirJNMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/basket.vue?macro=true";
import { default as checkout2B6rf1bgsiMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/checkout.vue?macro=true";
import { default as indexETRsKDyRDTMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/index.vue?macro=true";
import { default as _91id_93p7qzOm8Z4xMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/[city]/[id].vue?macro=true";
import { default as indexrXysU5diG6Meta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/index.vue?macro=true";
import { default as _91city_93h5pLR7DvYsMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/stadt/[county]/[city].vue?macro=true";
import { default as _91slug_93rWFJWKIGJdMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/[slug].vue?macro=true";
import { default as cleaning_45fluid_45pdpd4pz91It7uMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/cleaning-fluid-pdp.vue?macro=true";
import { default as contact_45lens_45pdpIA5RwyTHKLMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/contact-lens-pdp.vue?macro=true";
import { default as glasses_45pdprxs24d0dlyMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/glasses-pdp.vue?macro=true";
import { default as merch_45pdppSfWTlodf1Meta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/merch-pdp.vue?macro=true";
import { default as rx_45glasses_45pdpSBAAcBoEqFMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/rx-glasses-pdp.vue?macro=true";
import { default as ropo_45basketmTSzJt8DH9Meta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/ropo-basket.vue?macro=true";
import { default as ropo_45plpVOtPSZhmJtMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/ropo-plp.vue?macro=true";
import { default as searchFHwENevToFMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/search.vue?macro=true";
import { default as auftragsstatusVXzmIvJBbHMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/auftragsstatus.vue?macro=true";
import { default as gebrauchsanweisungenakdmtwld5eMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/gebrauchsanweisungen.vue?macro=true";
import { default as konformitaetserklaerungenoPVdGOzYRfMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/konformitaetserklaerungen.vue?macro=true";
import { default as signinHDEBWpeZkmMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/signin.vue?macro=true";
import { default as successZCNJSmmWCtMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/success.vue?macro=true";
import { default as wishlists2nZdBkxyIMeta } from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/wishlist.vue?macro=true";
export default [
  {
    name: _91_46_46_46category_93wajVM5mVB2Meta?.name ?? "category___de-at",
    path: _91_46_46_46category_93wajVM5mVB2Meta?.path ?? "/:category(.*)*/",
    meta: _91_46_46_46category_93wajVM5mVB2Meta || {},
    alias: _91_46_46_46category_93wajVM5mVB2Meta?.alias || [],
    redirect: _91_46_46_46category_93wajVM5mVB2Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/[...category].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46category_93wajVM5mVB2Meta?.name ?? "category___de-de",
    path: _91_46_46_46category_93wajVM5mVB2Meta?.path ?? "/:category(.*)*/",
    meta: _91_46_46_46category_93wajVM5mVB2Meta || {},
    alias: _91_46_46_46category_93wajVM5mVB2Meta?.alias || [],
    redirect: _91_46_46_46category_93wajVM5mVB2Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/[...category].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46category_93wajVM5mVB2Meta?.name ?? "category___cs-cz",
    path: _91_46_46_46category_93wajVM5mVB2Meta?.path ?? "/:category(.*)*/",
    meta: _91_46_46_46category_93wajVM5mVB2Meta || {},
    alias: _91_46_46_46category_93wajVM5mVB2Meta?.alias || [],
    redirect: _91_46_46_46category_93wajVM5mVB2Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/[...category].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46category_93wajVM5mVB2Meta?.name ?? "category___pl-pl",
    path: _91_46_46_46category_93wajVM5mVB2Meta?.path ?? "/:category(.*)*/",
    meta: _91_46_46_46category_93wajVM5mVB2Meta || {},
    alias: _91_46_46_46category_93wajVM5mVB2Meta?.alias || [],
    redirect: _91_46_46_46category_93wajVM5mVB2Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/[...category].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46category_93wajVM5mVB2Meta?.name ?? "category___de-ch",
    path: _91_46_46_46category_93wajVM5mVB2Meta?.path ?? "/:category(.*)*/",
    meta: _91_46_46_46category_93wajVM5mVB2Meta || {},
    alias: _91_46_46_46category_93wajVM5mVB2Meta?.alias || [],
    redirect: _91_46_46_46category_93wajVM5mVB2Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/[...category].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46category_93wajVM5mVB2Meta?.name ?? "category___fr-ch",
    path: _91_46_46_46category_93wajVM5mVB2Meta?.path ?? "/:category(.*)*/",
    meta: _91_46_46_46category_93wajVM5mVB2Meta || {},
    alias: _91_46_46_46category_93wajVM5mVB2Meta?.alias || [],
    redirect: _91_46_46_46category_93wajVM5mVB2Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/[...category].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46category_93wajVM5mVB2Meta?.name ?? "category___it-ch",
    path: _91_46_46_46category_93wajVM5mVB2Meta?.path ?? "/:category(.*)*/",
    meta: _91_46_46_46category_93wajVM5mVB2Meta || {},
    alias: _91_46_46_46category_93wajVM5mVB2Meta?.alias || [],
    redirect: _91_46_46_46category_93wajVM5mVB2Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/[...category].vue").then(m => m.default || m)
  },
  {
    name: appointmentsnQe25JyT3jMeta?.name ?? "account-appointments___de-at",
    path: appointmentsnQe25JyT3jMeta?.path ?? "/account/appointments/",
    meta: appointmentsnQe25JyT3jMeta || {},
    alias: appointmentsnQe25JyT3jMeta?.alias || [],
    redirect: appointmentsnQe25JyT3jMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/appointments.vue").then(m => m.default || m)
  },
  {
    name: appointmentsnQe25JyT3jMeta?.name ?? "account-appointments___de-de",
    path: appointmentsnQe25JyT3jMeta?.path ?? "/account/appointments/",
    meta: appointmentsnQe25JyT3jMeta || {},
    alias: appointmentsnQe25JyT3jMeta?.alias || [],
    redirect: appointmentsnQe25JyT3jMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/appointments.vue").then(m => m.default || m)
  },
  {
    name: appointmentsnQe25JyT3jMeta?.name ?? "account-appointments___cs-cz",
    path: appointmentsnQe25JyT3jMeta?.path ?? "/account/appointments/",
    meta: appointmentsnQe25JyT3jMeta || {},
    alias: appointmentsnQe25JyT3jMeta?.alias || [],
    redirect: appointmentsnQe25JyT3jMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/appointments.vue").then(m => m.default || m)
  },
  {
    name: appointmentsnQe25JyT3jMeta?.name ?? "account-appointments___pl-pl",
    path: appointmentsnQe25JyT3jMeta?.path ?? "/account/appointments/",
    meta: appointmentsnQe25JyT3jMeta || {},
    alias: appointmentsnQe25JyT3jMeta?.alias || [],
    redirect: appointmentsnQe25JyT3jMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/appointments.vue").then(m => m.default || m)
  },
  {
    name: appointmentsnQe25JyT3jMeta?.name ?? "account-appointments___de-ch",
    path: appointmentsnQe25JyT3jMeta?.path ?? "/account/appointments/",
    meta: appointmentsnQe25JyT3jMeta || {},
    alias: appointmentsnQe25JyT3jMeta?.alias || [],
    redirect: appointmentsnQe25JyT3jMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/appointments.vue").then(m => m.default || m)
  },
  {
    name: appointmentsnQe25JyT3jMeta?.name ?? "account-appointments___fr-ch",
    path: appointmentsnQe25JyT3jMeta?.path ?? "/account/appointments/",
    meta: appointmentsnQe25JyT3jMeta || {},
    alias: appointmentsnQe25JyT3jMeta?.alias || [],
    redirect: appointmentsnQe25JyT3jMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/appointments.vue").then(m => m.default || m)
  },
  {
    name: appointmentsnQe25JyT3jMeta?.name ?? "account-appointments___it-ch",
    path: appointmentsnQe25JyT3jMeta?.path ?? "/account/appointments/",
    meta: appointmentsnQe25JyT3jMeta || {},
    alias: appointmentsnQe25JyT3jMeta?.alias || [],
    redirect: appointmentsnQe25JyT3jMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/appointments.vue").then(m => m.default || m)
  },
  {
    name: _91id_93vGVk2DH5NXMeta?.name ?? "account-branch-order-id___de-at",
    path: _91id_93vGVk2DH5NXMeta?.path ?? "/account/branch-order/:id()/",
    meta: _91id_93vGVk2DH5NXMeta || {},
    alias: _91id_93vGVk2DH5NXMeta?.alias || [],
    redirect: _91id_93vGVk2DH5NXMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vGVk2DH5NXMeta?.name ?? "account-branch-order-id___de-de",
    path: _91id_93vGVk2DH5NXMeta?.path ?? "/account/branch-order/:id()/",
    meta: _91id_93vGVk2DH5NXMeta || {},
    alias: _91id_93vGVk2DH5NXMeta?.alias || [],
    redirect: _91id_93vGVk2DH5NXMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vGVk2DH5NXMeta?.name ?? "account-branch-order-id___cs-cz",
    path: _91id_93vGVk2DH5NXMeta?.path ?? "/account/branch-order/:id()/",
    meta: _91id_93vGVk2DH5NXMeta || {},
    alias: _91id_93vGVk2DH5NXMeta?.alias || [],
    redirect: _91id_93vGVk2DH5NXMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vGVk2DH5NXMeta?.name ?? "account-branch-order-id___pl-pl",
    path: _91id_93vGVk2DH5NXMeta?.path ?? "/account/branch-order/:id()/",
    meta: _91id_93vGVk2DH5NXMeta || {},
    alias: _91id_93vGVk2DH5NXMeta?.alias || [],
    redirect: _91id_93vGVk2DH5NXMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vGVk2DH5NXMeta?.name ?? "account-branch-order-id___de-ch",
    path: _91id_93vGVk2DH5NXMeta?.path ?? "/account/branch-order/:id()/",
    meta: _91id_93vGVk2DH5NXMeta || {},
    alias: _91id_93vGVk2DH5NXMeta?.alias || [],
    redirect: _91id_93vGVk2DH5NXMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vGVk2DH5NXMeta?.name ?? "account-branch-order-id___fr-ch",
    path: _91id_93vGVk2DH5NXMeta?.path ?? "/account/branch-order/:id()/",
    meta: _91id_93vGVk2DH5NXMeta || {},
    alias: _91id_93vGVk2DH5NXMeta?.alias || [],
    redirect: _91id_93vGVk2DH5NXMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vGVk2DH5NXMeta?.name ?? "account-branch-order-id___it-ch",
    path: _91id_93vGVk2DH5NXMeta?.path ?? "/account/branch-order/:id()/",
    meta: _91id_93vGVk2DH5NXMeta || {},
    alias: _91id_93vGVk2DH5NXMeta?.alias || [],
    redirect: _91id_93vGVk2DH5NXMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/[id].vue").then(m => m.default || m)
  },
  {
    name: indexN3BTxtD5udMeta?.name ?? "account-branch-order___de-at",
    path: indexN3BTxtD5udMeta?.path ?? "/account/branch-order/",
    meta: indexN3BTxtD5udMeta || {},
    alias: indexN3BTxtD5udMeta?.alias || [],
    redirect: indexN3BTxtD5udMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/index.vue").then(m => m.default || m)
  },
  {
    name: indexN3BTxtD5udMeta?.name ?? "account-branch-order___de-de",
    path: indexN3BTxtD5udMeta?.path ?? "/account/branch-order/",
    meta: indexN3BTxtD5udMeta || {},
    alias: indexN3BTxtD5udMeta?.alias || [],
    redirect: indexN3BTxtD5udMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/index.vue").then(m => m.default || m)
  },
  {
    name: indexN3BTxtD5udMeta?.name ?? "account-branch-order___cs-cz",
    path: indexN3BTxtD5udMeta?.path ?? "/account/branch-order/",
    meta: indexN3BTxtD5udMeta || {},
    alias: indexN3BTxtD5udMeta?.alias || [],
    redirect: indexN3BTxtD5udMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/index.vue").then(m => m.default || m)
  },
  {
    name: indexN3BTxtD5udMeta?.name ?? "account-branch-order___pl-pl",
    path: indexN3BTxtD5udMeta?.path ?? "/account/branch-order/",
    meta: indexN3BTxtD5udMeta || {},
    alias: indexN3BTxtD5udMeta?.alias || [],
    redirect: indexN3BTxtD5udMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/index.vue").then(m => m.default || m)
  },
  {
    name: indexN3BTxtD5udMeta?.name ?? "account-branch-order___de-ch",
    path: indexN3BTxtD5udMeta?.path ?? "/account/branch-order/",
    meta: indexN3BTxtD5udMeta || {},
    alias: indexN3BTxtD5udMeta?.alias || [],
    redirect: indexN3BTxtD5udMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/index.vue").then(m => m.default || m)
  },
  {
    name: indexN3BTxtD5udMeta?.name ?? "account-branch-order___fr-ch",
    path: indexN3BTxtD5udMeta?.path ?? "/account/branch-order/",
    meta: indexN3BTxtD5udMeta || {},
    alias: indexN3BTxtD5udMeta?.alias || [],
    redirect: indexN3BTxtD5udMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/index.vue").then(m => m.default || m)
  },
  {
    name: indexN3BTxtD5udMeta?.name ?? "account-branch-order___it-ch",
    path: indexN3BTxtD5udMeta?.path ?? "/account/branch-order/",
    meta: indexN3BTxtD5udMeta || {},
    alias: indexN3BTxtD5udMeta?.alias || [],
    redirect: indexN3BTxtD5udMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/branch-order/index.vue").then(m => m.default || m)
  },
  {
    name: index9ANNBGI2VAMeta?.name ?? "account___de-at",
    path: index9ANNBGI2VAMeta?.path ?? "/account/",
    meta: index9ANNBGI2VAMeta || {},
    alias: index9ANNBGI2VAMeta?.alias || [],
    redirect: index9ANNBGI2VAMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index9ANNBGI2VAMeta?.name ?? "account___de-de",
    path: index9ANNBGI2VAMeta?.path ?? "/account/",
    meta: index9ANNBGI2VAMeta || {},
    alias: index9ANNBGI2VAMeta?.alias || [],
    redirect: index9ANNBGI2VAMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index9ANNBGI2VAMeta?.name ?? "account___cs-cz",
    path: index9ANNBGI2VAMeta?.path ?? "/account/",
    meta: index9ANNBGI2VAMeta || {},
    alias: index9ANNBGI2VAMeta?.alias || [],
    redirect: index9ANNBGI2VAMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index9ANNBGI2VAMeta?.name ?? "account___pl-pl",
    path: index9ANNBGI2VAMeta?.path ?? "/account/",
    meta: index9ANNBGI2VAMeta || {},
    alias: index9ANNBGI2VAMeta?.alias || [],
    redirect: index9ANNBGI2VAMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index9ANNBGI2VAMeta?.name ?? "account___de-ch",
    path: index9ANNBGI2VAMeta?.path ?? "/account/",
    meta: index9ANNBGI2VAMeta || {},
    alias: index9ANNBGI2VAMeta?.alias || [],
    redirect: index9ANNBGI2VAMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index9ANNBGI2VAMeta?.name ?? "account___fr-ch",
    path: index9ANNBGI2VAMeta?.path ?? "/account/",
    meta: index9ANNBGI2VAMeta || {},
    alias: index9ANNBGI2VAMeta?.alias || [],
    redirect: index9ANNBGI2VAMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index9ANNBGI2VAMeta?.name ?? "account___it-ch",
    path: index9ANNBGI2VAMeta?.path ?? "/account/",
    meta: index9ANNBGI2VAMeta || {},
    alias: index9ANNBGI2VAMeta?.alias || [],
    redirect: index9ANNBGI2VAMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93kha0WvJKaZMeta?.name ?? "account-orders-id___de-at",
    path: _91id_93kha0WvJKaZMeta?.path ?? "/account/orders/:id()/",
    meta: _91id_93kha0WvJKaZMeta || {},
    alias: _91id_93kha0WvJKaZMeta?.alias || [],
    redirect: _91id_93kha0WvJKaZMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93kha0WvJKaZMeta?.name ?? "account-orders-id___de-de",
    path: _91id_93kha0WvJKaZMeta?.path ?? "/account/orders/:id()/",
    meta: _91id_93kha0WvJKaZMeta || {},
    alias: _91id_93kha0WvJKaZMeta?.alias || [],
    redirect: _91id_93kha0WvJKaZMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93kha0WvJKaZMeta?.name ?? "account-orders-id___cs-cz",
    path: _91id_93kha0WvJKaZMeta?.path ?? "/account/orders/:id()/",
    meta: _91id_93kha0WvJKaZMeta || {},
    alias: _91id_93kha0WvJKaZMeta?.alias || [],
    redirect: _91id_93kha0WvJKaZMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93kha0WvJKaZMeta?.name ?? "account-orders-id___pl-pl",
    path: _91id_93kha0WvJKaZMeta?.path ?? "/account/orders/:id()/",
    meta: _91id_93kha0WvJKaZMeta || {},
    alias: _91id_93kha0WvJKaZMeta?.alias || [],
    redirect: _91id_93kha0WvJKaZMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93kha0WvJKaZMeta?.name ?? "account-orders-id___de-ch",
    path: _91id_93kha0WvJKaZMeta?.path ?? "/account/orders/:id()/",
    meta: _91id_93kha0WvJKaZMeta || {},
    alias: _91id_93kha0WvJKaZMeta?.alias || [],
    redirect: _91id_93kha0WvJKaZMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93kha0WvJKaZMeta?.name ?? "account-orders-id___fr-ch",
    path: _91id_93kha0WvJKaZMeta?.path ?? "/account/orders/:id()/",
    meta: _91id_93kha0WvJKaZMeta || {},
    alias: _91id_93kha0WvJKaZMeta?.alias || [],
    redirect: _91id_93kha0WvJKaZMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93kha0WvJKaZMeta?.name ?? "account-orders-id___it-ch",
    path: _91id_93kha0WvJKaZMeta?.path ?? "/account/orders/:id()/",
    meta: _91id_93kha0WvJKaZMeta || {},
    alias: _91id_93kha0WvJKaZMeta?.alias || [],
    redirect: _91id_93kha0WvJKaZMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexIGobx1FVypMeta?.name ?? "account-orders___de-at",
    path: indexIGobx1FVypMeta?.path ?? "/account/orders/",
    meta: indexIGobx1FVypMeta || {},
    alias: indexIGobx1FVypMeta?.alias || [],
    redirect: indexIGobx1FVypMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexIGobx1FVypMeta?.name ?? "account-orders___de-de",
    path: indexIGobx1FVypMeta?.path ?? "/account/orders/",
    meta: indexIGobx1FVypMeta || {},
    alias: indexIGobx1FVypMeta?.alias || [],
    redirect: indexIGobx1FVypMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexIGobx1FVypMeta?.name ?? "account-orders___cs-cz",
    path: indexIGobx1FVypMeta?.path ?? "/account/orders/",
    meta: indexIGobx1FVypMeta || {},
    alias: indexIGobx1FVypMeta?.alias || [],
    redirect: indexIGobx1FVypMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexIGobx1FVypMeta?.name ?? "account-orders___pl-pl",
    path: indexIGobx1FVypMeta?.path ?? "/account/orders/",
    meta: indexIGobx1FVypMeta || {},
    alias: indexIGobx1FVypMeta?.alias || [],
    redirect: indexIGobx1FVypMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexIGobx1FVypMeta?.name ?? "account-orders___de-ch",
    path: indexIGobx1FVypMeta?.path ?? "/account/orders/",
    meta: indexIGobx1FVypMeta || {},
    alias: indexIGobx1FVypMeta?.alias || [],
    redirect: indexIGobx1FVypMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexIGobx1FVypMeta?.name ?? "account-orders___fr-ch",
    path: indexIGobx1FVypMeta?.path ?? "/account/orders/",
    meta: indexIGobx1FVypMeta || {},
    alias: indexIGobx1FVypMeta?.alias || [],
    redirect: indexIGobx1FVypMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexIGobx1FVypMeta?.name ?? "account-orders___it-ch",
    path: indexIGobx1FVypMeta?.path ?? "/account/orders/",
    meta: indexIGobx1FVypMeta || {},
    alias: indexIGobx1FVypMeta?.alias || [],
    redirect: indexIGobx1FVypMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: userKpi6tdnpgTMeta?.name ?? "account-user___de-at",
    path: userKpi6tdnpgTMeta?.path ?? "/account/user/",
    meta: userKpi6tdnpgTMeta || {},
    alias: userKpi6tdnpgTMeta?.alias || [],
    redirect: userKpi6tdnpgTMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/user.vue").then(m => m.default || m)
  },
  {
    name: userKpi6tdnpgTMeta?.name ?? "account-user___de-de",
    path: userKpi6tdnpgTMeta?.path ?? "/account/user/",
    meta: userKpi6tdnpgTMeta || {},
    alias: userKpi6tdnpgTMeta?.alias || [],
    redirect: userKpi6tdnpgTMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/user.vue").then(m => m.default || m)
  },
  {
    name: userKpi6tdnpgTMeta?.name ?? "account-user___cs-cz",
    path: userKpi6tdnpgTMeta?.path ?? "/account/user/",
    meta: userKpi6tdnpgTMeta || {},
    alias: userKpi6tdnpgTMeta?.alias || [],
    redirect: userKpi6tdnpgTMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/user.vue").then(m => m.default || m)
  },
  {
    name: userKpi6tdnpgTMeta?.name ?? "account-user___pl-pl",
    path: userKpi6tdnpgTMeta?.path ?? "/account/user/",
    meta: userKpi6tdnpgTMeta || {},
    alias: userKpi6tdnpgTMeta?.alias || [],
    redirect: userKpi6tdnpgTMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/user.vue").then(m => m.default || m)
  },
  {
    name: userKpi6tdnpgTMeta?.name ?? "account-user___de-ch",
    path: userKpi6tdnpgTMeta?.path ?? "/account/user/",
    meta: userKpi6tdnpgTMeta || {},
    alias: userKpi6tdnpgTMeta?.alias || [],
    redirect: userKpi6tdnpgTMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/user.vue").then(m => m.default || m)
  },
  {
    name: userKpi6tdnpgTMeta?.name ?? "account-user___fr-ch",
    path: userKpi6tdnpgTMeta?.path ?? "/account/user/",
    meta: userKpi6tdnpgTMeta || {},
    alias: userKpi6tdnpgTMeta?.alias || [],
    redirect: userKpi6tdnpgTMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/user.vue").then(m => m.default || m)
  },
  {
    name: userKpi6tdnpgTMeta?.name ?? "account-user___it-ch",
    path: userKpi6tdnpgTMeta?.path ?? "/account/user/",
    meta: userKpi6tdnpgTMeta || {},
    alias: userKpi6tdnpgTMeta?.alias || [],
    redirect: userKpi6tdnpgTMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/account/user.vue").then(m => m.default || m)
  },
  {
    name: basketmMz9qgirJNMeta?.name ?? "basket___de-at",
    path: basketmMz9qgirJNMeta?.path ?? "/basket/",
    meta: basketmMz9qgirJNMeta || {},
    alias: basketmMz9qgirJNMeta?.alias || [],
    redirect: basketmMz9qgirJNMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: basketmMz9qgirJNMeta?.name ?? "basket___de-de",
    path: basketmMz9qgirJNMeta?.path ?? "/basket/",
    meta: basketmMz9qgirJNMeta || {},
    alias: basketmMz9qgirJNMeta?.alias || [],
    redirect: basketmMz9qgirJNMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: basketmMz9qgirJNMeta?.name ?? "basket___cs-cz",
    path: basketmMz9qgirJNMeta?.path ?? "/basket/",
    meta: basketmMz9qgirJNMeta || {},
    alias: basketmMz9qgirJNMeta?.alias || [],
    redirect: basketmMz9qgirJNMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: basketmMz9qgirJNMeta?.name ?? "basket___pl-pl",
    path: basketmMz9qgirJNMeta?.path ?? "/basket/",
    meta: basketmMz9qgirJNMeta || {},
    alias: basketmMz9qgirJNMeta?.alias || [],
    redirect: basketmMz9qgirJNMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: basketmMz9qgirJNMeta?.name ?? "basket___de-ch",
    path: basketmMz9qgirJNMeta?.path ?? "/basket/",
    meta: basketmMz9qgirJNMeta || {},
    alias: basketmMz9qgirJNMeta?.alias || [],
    redirect: basketmMz9qgirJNMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: basketmMz9qgirJNMeta?.name ?? "basket___fr-ch",
    path: basketmMz9qgirJNMeta?.path ?? "/basket/",
    meta: basketmMz9qgirJNMeta || {},
    alias: basketmMz9qgirJNMeta?.alias || [],
    redirect: basketmMz9qgirJNMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: basketmMz9qgirJNMeta?.name ?? "basket___it-ch",
    path: basketmMz9qgirJNMeta?.path ?? "/basket/",
    meta: basketmMz9qgirJNMeta || {},
    alias: basketmMz9qgirJNMeta?.alias || [],
    redirect: basketmMz9qgirJNMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: checkout2B6rf1bgsiMeta?.name ?? "checkout___de-at",
    path: checkout2B6rf1bgsiMeta?.path ?? "/checkout/",
    meta: checkout2B6rf1bgsiMeta || {},
    alias: checkout2B6rf1bgsiMeta?.alias || [],
    redirect: checkout2B6rf1bgsiMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkout2B6rf1bgsiMeta?.name ?? "checkout___de-de",
    path: checkout2B6rf1bgsiMeta?.path ?? "/checkout/",
    meta: checkout2B6rf1bgsiMeta || {},
    alias: checkout2B6rf1bgsiMeta?.alias || [],
    redirect: checkout2B6rf1bgsiMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkout2B6rf1bgsiMeta?.name ?? "checkout___cs-cz",
    path: checkout2B6rf1bgsiMeta?.path ?? "/checkout/",
    meta: checkout2B6rf1bgsiMeta || {},
    alias: checkout2B6rf1bgsiMeta?.alias || [],
    redirect: checkout2B6rf1bgsiMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkout2B6rf1bgsiMeta?.name ?? "checkout___pl-pl",
    path: checkout2B6rf1bgsiMeta?.path ?? "/checkout/",
    meta: checkout2B6rf1bgsiMeta || {},
    alias: checkout2B6rf1bgsiMeta?.alias || [],
    redirect: checkout2B6rf1bgsiMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkout2B6rf1bgsiMeta?.name ?? "checkout___de-ch",
    path: checkout2B6rf1bgsiMeta?.path ?? "/checkout/",
    meta: checkout2B6rf1bgsiMeta || {},
    alias: checkout2B6rf1bgsiMeta?.alias || [],
    redirect: checkout2B6rf1bgsiMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkout2B6rf1bgsiMeta?.name ?? "checkout___fr-ch",
    path: checkout2B6rf1bgsiMeta?.path ?? "/checkout/",
    meta: checkout2B6rf1bgsiMeta || {},
    alias: checkout2B6rf1bgsiMeta?.alias || [],
    redirect: checkout2B6rf1bgsiMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkout2B6rf1bgsiMeta?.name ?? "checkout___it-ch",
    path: checkout2B6rf1bgsiMeta?.path ?? "/checkout/",
    meta: checkout2B6rf1bgsiMeta || {},
    alias: checkout2B6rf1bgsiMeta?.alias || [],
    redirect: checkout2B6rf1bgsiMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: indexETRsKDyRDTMeta?.name ?? "index___de-at",
    path: indexETRsKDyRDTMeta?.path ?? "/",
    meta: indexETRsKDyRDTMeta || {},
    alias: indexETRsKDyRDTMeta?.alias || [],
    redirect: indexETRsKDyRDTMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexETRsKDyRDTMeta?.name ?? "index___de-de",
    path: indexETRsKDyRDTMeta?.path ?? "/",
    meta: indexETRsKDyRDTMeta || {},
    alias: indexETRsKDyRDTMeta?.alias || [],
    redirect: indexETRsKDyRDTMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexETRsKDyRDTMeta?.name ?? "index___cs-cz",
    path: indexETRsKDyRDTMeta?.path ?? "/",
    meta: indexETRsKDyRDTMeta || {},
    alias: indexETRsKDyRDTMeta?.alias || [],
    redirect: indexETRsKDyRDTMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexETRsKDyRDTMeta?.name ?? "index___pl-pl",
    path: indexETRsKDyRDTMeta?.path ?? "/",
    meta: indexETRsKDyRDTMeta || {},
    alias: indexETRsKDyRDTMeta?.alias || [],
    redirect: indexETRsKDyRDTMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexETRsKDyRDTMeta?.name ?? "index___de-ch",
    path: indexETRsKDyRDTMeta?.path ?? "/",
    meta: indexETRsKDyRDTMeta || {},
    alias: indexETRsKDyRDTMeta?.alias || [],
    redirect: indexETRsKDyRDTMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexETRsKDyRDTMeta?.name ?? "index___fr-ch",
    path: indexETRsKDyRDTMeta?.path ?? "/",
    meta: indexETRsKDyRDTMeta || {},
    alias: indexETRsKDyRDTMeta?.alias || [],
    redirect: indexETRsKDyRDTMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexETRsKDyRDTMeta?.name ?? "index___it-ch",
    path: indexETRsKDyRDTMeta?.path ?? "/",
    meta: indexETRsKDyRDTMeta || {},
    alias: indexETRsKDyRDTMeta?.alias || [],
    redirect: indexETRsKDyRDTMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93p7qzOm8Z4xMeta?.name ?? "niederlassungen-city-id___de-at",
    path: _91id_93p7qzOm8Z4xMeta?.path ?? "/niederlassungen/:city()/:id()/",
    meta: _91id_93p7qzOm8Z4xMeta || {},
    alias: _91id_93p7qzOm8Z4xMeta?.alias || [],
    redirect: _91id_93p7qzOm8Z4xMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/[city]/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93p7qzOm8Z4xMeta?.name ?? "niederlassungen-city-id___de-de",
    path: _91id_93p7qzOm8Z4xMeta?.path ?? "/niederlassungen/:city()/:id()/",
    meta: _91id_93p7qzOm8Z4xMeta || {},
    alias: _91id_93p7qzOm8Z4xMeta?.alias || [],
    redirect: _91id_93p7qzOm8Z4xMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/[city]/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93p7qzOm8Z4xMeta?.name ?? "niederlassungen-city-id___cs-cz",
    path: _91id_93p7qzOm8Z4xMeta?.path ?? "/pobocky/:city()/:id()/",
    meta: _91id_93p7qzOm8Z4xMeta || {},
    alias: _91id_93p7qzOm8Z4xMeta?.alias || [],
    redirect: _91id_93p7qzOm8Z4xMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/[city]/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93p7qzOm8Z4xMeta?.name ?? "niederlassungen-city-id___pl-pl",
    path: _91id_93p7qzOm8Z4xMeta?.path ?? "/salony/:city()/:id()/",
    meta: _91id_93p7qzOm8Z4xMeta || {},
    alias: _91id_93p7qzOm8Z4xMeta?.alias || [],
    redirect: _91id_93p7qzOm8Z4xMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/[city]/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93p7qzOm8Z4xMeta?.name ?? "niederlassungen-city-id___de-ch",
    path: _91id_93p7qzOm8Z4xMeta?.path ?? "/niederlassungen/:city()/:id()/",
    meta: _91id_93p7qzOm8Z4xMeta || {},
    alias: _91id_93p7qzOm8Z4xMeta?.alias || [],
    redirect: _91id_93p7qzOm8Z4xMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/[city]/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93p7qzOm8Z4xMeta?.name ?? "niederlassungen-city-id___fr-ch",
    path: _91id_93p7qzOm8Z4xMeta?.path ?? "/succursales/:city()/:id()/",
    meta: _91id_93p7qzOm8Z4xMeta || {},
    alias: _91id_93p7qzOm8Z4xMeta?.alias || [],
    redirect: _91id_93p7qzOm8Z4xMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/[city]/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93p7qzOm8Z4xMeta?.name ?? "niederlassungen-city-id___it-ch",
    path: _91id_93p7qzOm8Z4xMeta?.path ?? "/filiali/:city()/:id()/",
    meta: _91id_93p7qzOm8Z4xMeta || {},
    alias: _91id_93p7qzOm8Z4xMeta?.alias || [],
    redirect: _91id_93p7qzOm8Z4xMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/[city]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexrXysU5diG6Meta?.name ?? "niederlassungen___de-at",
    path: indexrXysU5diG6Meta?.path ?? "/niederlassungen/",
    meta: indexrXysU5diG6Meta || {},
    alias: indexrXysU5diG6Meta?.alias || [],
    redirect: indexrXysU5diG6Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/index.vue").then(m => m.default || m)
  },
  {
    name: indexrXysU5diG6Meta?.name ?? "niederlassungen___de-de",
    path: indexrXysU5diG6Meta?.path ?? "/niederlassungen/",
    meta: indexrXysU5diG6Meta || {},
    alias: indexrXysU5diG6Meta?.alias || [],
    redirect: indexrXysU5diG6Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/index.vue").then(m => m.default || m)
  },
  {
    name: indexrXysU5diG6Meta?.name ?? "niederlassungen___cs-cz",
    path: indexrXysU5diG6Meta?.path ?? "/pobocky/",
    meta: indexrXysU5diG6Meta || {},
    alias: indexrXysU5diG6Meta?.alias || [],
    redirect: indexrXysU5diG6Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/index.vue").then(m => m.default || m)
  },
  {
    name: indexrXysU5diG6Meta?.name ?? "niederlassungen___pl-pl",
    path: indexrXysU5diG6Meta?.path ?? "/salony/",
    meta: indexrXysU5diG6Meta || {},
    alias: indexrXysU5diG6Meta?.alias || [],
    redirect: indexrXysU5diG6Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/index.vue").then(m => m.default || m)
  },
  {
    name: indexrXysU5diG6Meta?.name ?? "niederlassungen___de-ch",
    path: indexrXysU5diG6Meta?.path ?? "/niederlassungen/",
    meta: indexrXysU5diG6Meta || {},
    alias: indexrXysU5diG6Meta?.alias || [],
    redirect: indexrXysU5diG6Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/index.vue").then(m => m.default || m)
  },
  {
    name: indexrXysU5diG6Meta?.name ?? "niederlassungen___fr-ch",
    path: indexrXysU5diG6Meta?.path ?? "/succursales/",
    meta: indexrXysU5diG6Meta || {},
    alias: indexrXysU5diG6Meta?.alias || [],
    redirect: indexrXysU5diG6Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/index.vue").then(m => m.default || m)
  },
  {
    name: indexrXysU5diG6Meta?.name ?? "niederlassungen___it-ch",
    path: indexrXysU5diG6Meta?.path ?? "/filiali/",
    meta: indexrXysU5diG6Meta || {},
    alias: indexrXysU5diG6Meta?.alias || [],
    redirect: indexrXysU5diG6Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/index.vue").then(m => m.default || m)
  },
  {
    name: _91city_93h5pLR7DvYsMeta?.name ?? "niederlassungen-stadt-county-city___de-at",
    path: _91city_93h5pLR7DvYsMeta?.path ?? "/niederlassungen/stadt/:county()/:city()/",
    meta: _91city_93h5pLR7DvYsMeta || {},
    alias: _91city_93h5pLR7DvYsMeta?.alias || [],
    redirect: _91city_93h5pLR7DvYsMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/stadt/[county]/[city].vue").then(m => m.default || m)
  },
  {
    name: _91city_93h5pLR7DvYsMeta?.name ?? "niederlassungen-stadt-county-city___de-de",
    path: _91city_93h5pLR7DvYsMeta?.path ?? "/niederlassungen/stadt/:county()/:city()/",
    meta: _91city_93h5pLR7DvYsMeta || {},
    alias: _91city_93h5pLR7DvYsMeta?.alias || [],
    redirect: _91city_93h5pLR7DvYsMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/stadt/[county]/[city].vue").then(m => m.default || m)
  },
  {
    name: _91city_93h5pLR7DvYsMeta?.name ?? "niederlassungen-stadt-county-city___cs-cz",
    path: _91city_93h5pLR7DvYsMeta?.path ?? "/pobocky/mesto/:county()/:city()/",
    meta: _91city_93h5pLR7DvYsMeta || {},
    alias: _91city_93h5pLR7DvYsMeta?.alias || [],
    redirect: _91city_93h5pLR7DvYsMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/stadt/[county]/[city].vue").then(m => m.default || m)
  },
  {
    name: _91city_93h5pLR7DvYsMeta?.name ?? "niederlassungen-stadt-county-city___pl-pl",
    path: _91city_93h5pLR7DvYsMeta?.path ?? "/salony/miasto/:county()/:city()/",
    meta: _91city_93h5pLR7DvYsMeta || {},
    alias: _91city_93h5pLR7DvYsMeta?.alias || [],
    redirect: _91city_93h5pLR7DvYsMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/stadt/[county]/[city].vue").then(m => m.default || m)
  },
  {
    name: _91city_93h5pLR7DvYsMeta?.name ?? "niederlassungen-stadt-county-city___de-ch",
    path: _91city_93h5pLR7DvYsMeta?.path ?? "/niederlassungen/stadt/:county()/:city()/",
    meta: _91city_93h5pLR7DvYsMeta || {},
    alias: _91city_93h5pLR7DvYsMeta?.alias || [],
    redirect: _91city_93h5pLR7DvYsMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/stadt/[county]/[city].vue").then(m => m.default || m)
  },
  {
    name: _91city_93h5pLR7DvYsMeta?.name ?? "niederlassungen-stadt-county-city___fr-ch",
    path: _91city_93h5pLR7DvYsMeta?.path ?? "/succursales/ville/:county()/:city()/",
    meta: _91city_93h5pLR7DvYsMeta || {},
    alias: _91city_93h5pLR7DvYsMeta?.alias || [],
    redirect: _91city_93h5pLR7DvYsMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/stadt/[county]/[city].vue").then(m => m.default || m)
  },
  {
    name: _91city_93h5pLR7DvYsMeta?.name ?? "niederlassungen-stadt-county-city___it-ch",
    path: _91city_93h5pLR7DvYsMeta?.path ?? "/filiali/citta/:county()/:city()/",
    meta: _91city_93h5pLR7DvYsMeta || {},
    alias: _91city_93h5pLR7DvYsMeta?.alias || [],
    redirect: _91city_93h5pLR7DvYsMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/niederlassungen/stadt/[county]/[city].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rWFJWKIGJdMeta?.name ?? "p-slug___de-at",
    path: _91slug_93rWFJWKIGJdMeta?.path ?? "/p/:slug()/",
    meta: _91slug_93rWFJWKIGJdMeta || {},
    alias: _91slug_93rWFJWKIGJdMeta?.alias || [],
    redirect: _91slug_93rWFJWKIGJdMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rWFJWKIGJdMeta?.name ?? "p-slug___de-de",
    path: _91slug_93rWFJWKIGJdMeta?.path ?? "/p/:slug()/",
    meta: _91slug_93rWFJWKIGJdMeta || {},
    alias: _91slug_93rWFJWKIGJdMeta?.alias || [],
    redirect: _91slug_93rWFJWKIGJdMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rWFJWKIGJdMeta?.name ?? "p-slug___cs-cz",
    path: _91slug_93rWFJWKIGJdMeta?.path ?? "/p/:slug()/",
    meta: _91slug_93rWFJWKIGJdMeta || {},
    alias: _91slug_93rWFJWKIGJdMeta?.alias || [],
    redirect: _91slug_93rWFJWKIGJdMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rWFJWKIGJdMeta?.name ?? "p-slug___pl-pl",
    path: _91slug_93rWFJWKIGJdMeta?.path ?? "/p/:slug()/",
    meta: _91slug_93rWFJWKIGJdMeta || {},
    alias: _91slug_93rWFJWKIGJdMeta?.alias || [],
    redirect: _91slug_93rWFJWKIGJdMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rWFJWKIGJdMeta?.name ?? "p-slug___de-ch",
    path: _91slug_93rWFJWKIGJdMeta?.path ?? "/p/:slug()/",
    meta: _91slug_93rWFJWKIGJdMeta || {},
    alias: _91slug_93rWFJWKIGJdMeta?.alias || [],
    redirect: _91slug_93rWFJWKIGJdMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rWFJWKIGJdMeta?.name ?? "p-slug___fr-ch",
    path: _91slug_93rWFJWKIGJdMeta?.path ?? "/p/:slug()/",
    meta: _91slug_93rWFJWKIGJdMeta || {},
    alias: _91slug_93rWFJWKIGJdMeta?.alias || [],
    redirect: _91slug_93rWFJWKIGJdMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rWFJWKIGJdMeta?.name ?? "p-slug___it-ch",
    path: _91slug_93rWFJWKIGJdMeta?.path ?? "/p/:slug()/",
    meta: _91slug_93rWFJWKIGJdMeta || {},
    alias: _91slug_93rWFJWKIGJdMeta?.alias || [],
    redirect: _91slug_93rWFJWKIGJdMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/[slug].vue").then(m => m.default || m)
  },
  {
    name: cleaning_45fluid_45pdpd4pz91It7uMeta?.name ?? "p-cleaning-fluid-pdp___de-at",
    path: cleaning_45fluid_45pdpd4pz91It7uMeta?.path ?? "/p/cleaning-fluid-pdp/",
    meta: cleaning_45fluid_45pdpd4pz91It7uMeta || {},
    alias: cleaning_45fluid_45pdpd4pz91It7uMeta?.alias || [],
    redirect: cleaning_45fluid_45pdpd4pz91It7uMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/cleaning-fluid-pdp.vue").then(m => m.default || m)
  },
  {
    name: cleaning_45fluid_45pdpd4pz91It7uMeta?.name ?? "p-cleaning-fluid-pdp___de-de",
    path: cleaning_45fluid_45pdpd4pz91It7uMeta?.path ?? "/p/cleaning-fluid-pdp/",
    meta: cleaning_45fluid_45pdpd4pz91It7uMeta || {},
    alias: cleaning_45fluid_45pdpd4pz91It7uMeta?.alias || [],
    redirect: cleaning_45fluid_45pdpd4pz91It7uMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/cleaning-fluid-pdp.vue").then(m => m.default || m)
  },
  {
    name: cleaning_45fluid_45pdpd4pz91It7uMeta?.name ?? "p-cleaning-fluid-pdp___cs-cz",
    path: cleaning_45fluid_45pdpd4pz91It7uMeta?.path ?? "/p/cleaning-fluid-pdp/",
    meta: cleaning_45fluid_45pdpd4pz91It7uMeta || {},
    alias: cleaning_45fluid_45pdpd4pz91It7uMeta?.alias || [],
    redirect: cleaning_45fluid_45pdpd4pz91It7uMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/cleaning-fluid-pdp.vue").then(m => m.default || m)
  },
  {
    name: cleaning_45fluid_45pdpd4pz91It7uMeta?.name ?? "p-cleaning-fluid-pdp___pl-pl",
    path: cleaning_45fluid_45pdpd4pz91It7uMeta?.path ?? "/p/cleaning-fluid-pdp/",
    meta: cleaning_45fluid_45pdpd4pz91It7uMeta || {},
    alias: cleaning_45fluid_45pdpd4pz91It7uMeta?.alias || [],
    redirect: cleaning_45fluid_45pdpd4pz91It7uMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/cleaning-fluid-pdp.vue").then(m => m.default || m)
  },
  {
    name: cleaning_45fluid_45pdpd4pz91It7uMeta?.name ?? "p-cleaning-fluid-pdp___de-ch",
    path: cleaning_45fluid_45pdpd4pz91It7uMeta?.path ?? "/p/cleaning-fluid-pdp/",
    meta: cleaning_45fluid_45pdpd4pz91It7uMeta || {},
    alias: cleaning_45fluid_45pdpd4pz91It7uMeta?.alias || [],
    redirect: cleaning_45fluid_45pdpd4pz91It7uMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/cleaning-fluid-pdp.vue").then(m => m.default || m)
  },
  {
    name: cleaning_45fluid_45pdpd4pz91It7uMeta?.name ?? "p-cleaning-fluid-pdp___fr-ch",
    path: cleaning_45fluid_45pdpd4pz91It7uMeta?.path ?? "/p/cleaning-fluid-pdp/",
    meta: cleaning_45fluid_45pdpd4pz91It7uMeta || {},
    alias: cleaning_45fluid_45pdpd4pz91It7uMeta?.alias || [],
    redirect: cleaning_45fluid_45pdpd4pz91It7uMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/cleaning-fluid-pdp.vue").then(m => m.default || m)
  },
  {
    name: cleaning_45fluid_45pdpd4pz91It7uMeta?.name ?? "p-cleaning-fluid-pdp___it-ch",
    path: cleaning_45fluid_45pdpd4pz91It7uMeta?.path ?? "/p/cleaning-fluid-pdp/",
    meta: cleaning_45fluid_45pdpd4pz91It7uMeta || {},
    alias: cleaning_45fluid_45pdpd4pz91It7uMeta?.alias || [],
    redirect: cleaning_45fluid_45pdpd4pz91It7uMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/cleaning-fluid-pdp.vue").then(m => m.default || m)
  },
  {
    name: contact_45lens_45pdpIA5RwyTHKLMeta?.name ?? "p-contact-lens-pdp___de-at",
    path: contact_45lens_45pdpIA5RwyTHKLMeta?.path ?? "/p/contact-lens-pdp/",
    meta: contact_45lens_45pdpIA5RwyTHKLMeta || {},
    alias: contact_45lens_45pdpIA5RwyTHKLMeta?.alias || [],
    redirect: contact_45lens_45pdpIA5RwyTHKLMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/contact-lens-pdp.vue").then(m => m.default || m)
  },
  {
    name: contact_45lens_45pdpIA5RwyTHKLMeta?.name ?? "p-contact-lens-pdp___de-de",
    path: contact_45lens_45pdpIA5RwyTHKLMeta?.path ?? "/p/contact-lens-pdp/",
    meta: contact_45lens_45pdpIA5RwyTHKLMeta || {},
    alias: contact_45lens_45pdpIA5RwyTHKLMeta?.alias || [],
    redirect: contact_45lens_45pdpIA5RwyTHKLMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/contact-lens-pdp.vue").then(m => m.default || m)
  },
  {
    name: contact_45lens_45pdpIA5RwyTHKLMeta?.name ?? "p-contact-lens-pdp___cs-cz",
    path: contact_45lens_45pdpIA5RwyTHKLMeta?.path ?? "/p/contact-lens-pdp/",
    meta: contact_45lens_45pdpIA5RwyTHKLMeta || {},
    alias: contact_45lens_45pdpIA5RwyTHKLMeta?.alias || [],
    redirect: contact_45lens_45pdpIA5RwyTHKLMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/contact-lens-pdp.vue").then(m => m.default || m)
  },
  {
    name: contact_45lens_45pdpIA5RwyTHKLMeta?.name ?? "p-contact-lens-pdp___pl-pl",
    path: contact_45lens_45pdpIA5RwyTHKLMeta?.path ?? "/p/contact-lens-pdp/",
    meta: contact_45lens_45pdpIA5RwyTHKLMeta || {},
    alias: contact_45lens_45pdpIA5RwyTHKLMeta?.alias || [],
    redirect: contact_45lens_45pdpIA5RwyTHKLMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/contact-lens-pdp.vue").then(m => m.default || m)
  },
  {
    name: contact_45lens_45pdpIA5RwyTHKLMeta?.name ?? "p-contact-lens-pdp___de-ch",
    path: contact_45lens_45pdpIA5RwyTHKLMeta?.path ?? "/p/contact-lens-pdp/",
    meta: contact_45lens_45pdpIA5RwyTHKLMeta || {},
    alias: contact_45lens_45pdpIA5RwyTHKLMeta?.alias || [],
    redirect: contact_45lens_45pdpIA5RwyTHKLMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/contact-lens-pdp.vue").then(m => m.default || m)
  },
  {
    name: contact_45lens_45pdpIA5RwyTHKLMeta?.name ?? "p-contact-lens-pdp___fr-ch",
    path: contact_45lens_45pdpIA5RwyTHKLMeta?.path ?? "/p/contact-lens-pdp/",
    meta: contact_45lens_45pdpIA5RwyTHKLMeta || {},
    alias: contact_45lens_45pdpIA5RwyTHKLMeta?.alias || [],
    redirect: contact_45lens_45pdpIA5RwyTHKLMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/contact-lens-pdp.vue").then(m => m.default || m)
  },
  {
    name: contact_45lens_45pdpIA5RwyTHKLMeta?.name ?? "p-contact-lens-pdp___it-ch",
    path: contact_45lens_45pdpIA5RwyTHKLMeta?.path ?? "/p/contact-lens-pdp/",
    meta: contact_45lens_45pdpIA5RwyTHKLMeta || {},
    alias: contact_45lens_45pdpIA5RwyTHKLMeta?.alias || [],
    redirect: contact_45lens_45pdpIA5RwyTHKLMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/contact-lens-pdp.vue").then(m => m.default || m)
  },
  {
    name: glasses_45pdprxs24d0dlyMeta?.name ?? "p-glasses-pdp___de-at",
    path: glasses_45pdprxs24d0dlyMeta?.path ?? "/p/glasses-pdp/",
    meta: glasses_45pdprxs24d0dlyMeta || {},
    alias: glasses_45pdprxs24d0dlyMeta?.alias || [],
    redirect: glasses_45pdprxs24d0dlyMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: glasses_45pdprxs24d0dlyMeta?.name ?? "p-glasses-pdp___de-de",
    path: glasses_45pdprxs24d0dlyMeta?.path ?? "/p/glasses-pdp/",
    meta: glasses_45pdprxs24d0dlyMeta || {},
    alias: glasses_45pdprxs24d0dlyMeta?.alias || [],
    redirect: glasses_45pdprxs24d0dlyMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: glasses_45pdprxs24d0dlyMeta?.name ?? "p-glasses-pdp___cs-cz",
    path: glasses_45pdprxs24d0dlyMeta?.path ?? "/p/glasses-pdp/",
    meta: glasses_45pdprxs24d0dlyMeta || {},
    alias: glasses_45pdprxs24d0dlyMeta?.alias || [],
    redirect: glasses_45pdprxs24d0dlyMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: glasses_45pdprxs24d0dlyMeta?.name ?? "p-glasses-pdp___pl-pl",
    path: glasses_45pdprxs24d0dlyMeta?.path ?? "/p/glasses-pdp/",
    meta: glasses_45pdprxs24d0dlyMeta || {},
    alias: glasses_45pdprxs24d0dlyMeta?.alias || [],
    redirect: glasses_45pdprxs24d0dlyMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: glasses_45pdprxs24d0dlyMeta?.name ?? "p-glasses-pdp___de-ch",
    path: glasses_45pdprxs24d0dlyMeta?.path ?? "/p/glasses-pdp/",
    meta: glasses_45pdprxs24d0dlyMeta || {},
    alias: glasses_45pdprxs24d0dlyMeta?.alias || [],
    redirect: glasses_45pdprxs24d0dlyMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: glasses_45pdprxs24d0dlyMeta?.name ?? "p-glasses-pdp___fr-ch",
    path: glasses_45pdprxs24d0dlyMeta?.path ?? "/p/glasses-pdp/",
    meta: glasses_45pdprxs24d0dlyMeta || {},
    alias: glasses_45pdprxs24d0dlyMeta?.alias || [],
    redirect: glasses_45pdprxs24d0dlyMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: glasses_45pdprxs24d0dlyMeta?.name ?? "p-glasses-pdp___it-ch",
    path: glasses_45pdprxs24d0dlyMeta?.path ?? "/p/glasses-pdp/",
    meta: glasses_45pdprxs24d0dlyMeta || {},
    alias: glasses_45pdprxs24d0dlyMeta?.alias || [],
    redirect: glasses_45pdprxs24d0dlyMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: merch_45pdppSfWTlodf1Meta?.name ?? "p-merch-pdp___de-at",
    path: merch_45pdppSfWTlodf1Meta?.path ?? "/p/merch-pdp/",
    meta: merch_45pdppSfWTlodf1Meta || {},
    alias: merch_45pdppSfWTlodf1Meta?.alias || [],
    redirect: merch_45pdppSfWTlodf1Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/merch-pdp.vue").then(m => m.default || m)
  },
  {
    name: merch_45pdppSfWTlodf1Meta?.name ?? "p-merch-pdp___de-de",
    path: merch_45pdppSfWTlodf1Meta?.path ?? "/p/merch-pdp/",
    meta: merch_45pdppSfWTlodf1Meta || {},
    alias: merch_45pdppSfWTlodf1Meta?.alias || [],
    redirect: merch_45pdppSfWTlodf1Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/merch-pdp.vue").then(m => m.default || m)
  },
  {
    name: merch_45pdppSfWTlodf1Meta?.name ?? "p-merch-pdp___cs-cz",
    path: merch_45pdppSfWTlodf1Meta?.path ?? "/p/merch-pdp/",
    meta: merch_45pdppSfWTlodf1Meta || {},
    alias: merch_45pdppSfWTlodf1Meta?.alias || [],
    redirect: merch_45pdppSfWTlodf1Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/merch-pdp.vue").then(m => m.default || m)
  },
  {
    name: merch_45pdppSfWTlodf1Meta?.name ?? "p-merch-pdp___pl-pl",
    path: merch_45pdppSfWTlodf1Meta?.path ?? "/p/merch-pdp/",
    meta: merch_45pdppSfWTlodf1Meta || {},
    alias: merch_45pdppSfWTlodf1Meta?.alias || [],
    redirect: merch_45pdppSfWTlodf1Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/merch-pdp.vue").then(m => m.default || m)
  },
  {
    name: merch_45pdppSfWTlodf1Meta?.name ?? "p-merch-pdp___de-ch",
    path: merch_45pdppSfWTlodf1Meta?.path ?? "/p/merch-pdp/",
    meta: merch_45pdppSfWTlodf1Meta || {},
    alias: merch_45pdppSfWTlodf1Meta?.alias || [],
    redirect: merch_45pdppSfWTlodf1Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/merch-pdp.vue").then(m => m.default || m)
  },
  {
    name: merch_45pdppSfWTlodf1Meta?.name ?? "p-merch-pdp___fr-ch",
    path: merch_45pdppSfWTlodf1Meta?.path ?? "/p/merch-pdp/",
    meta: merch_45pdppSfWTlodf1Meta || {},
    alias: merch_45pdppSfWTlodf1Meta?.alias || [],
    redirect: merch_45pdppSfWTlodf1Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/merch-pdp.vue").then(m => m.default || m)
  },
  {
    name: merch_45pdppSfWTlodf1Meta?.name ?? "p-merch-pdp___it-ch",
    path: merch_45pdppSfWTlodf1Meta?.path ?? "/p/merch-pdp/",
    meta: merch_45pdppSfWTlodf1Meta || {},
    alias: merch_45pdppSfWTlodf1Meta?.alias || [],
    redirect: merch_45pdppSfWTlodf1Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/merch-pdp.vue").then(m => m.default || m)
  },
  {
    name: rx_45glasses_45pdpSBAAcBoEqFMeta?.name ?? "p-rx-glasses-pdp___de-at",
    path: rx_45glasses_45pdpSBAAcBoEqFMeta?.path ?? "/p/rx-glasses-pdp/",
    meta: rx_45glasses_45pdpSBAAcBoEqFMeta || {},
    alias: rx_45glasses_45pdpSBAAcBoEqFMeta?.alias || [],
    redirect: rx_45glasses_45pdpSBAAcBoEqFMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/rx-glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: rx_45glasses_45pdpSBAAcBoEqFMeta?.name ?? "p-rx-glasses-pdp___de-de",
    path: rx_45glasses_45pdpSBAAcBoEqFMeta?.path ?? "/p/rx-glasses-pdp/",
    meta: rx_45glasses_45pdpSBAAcBoEqFMeta || {},
    alias: rx_45glasses_45pdpSBAAcBoEqFMeta?.alias || [],
    redirect: rx_45glasses_45pdpSBAAcBoEqFMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/rx-glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: rx_45glasses_45pdpSBAAcBoEqFMeta?.name ?? "p-rx-glasses-pdp___cs-cz",
    path: rx_45glasses_45pdpSBAAcBoEqFMeta?.path ?? "/p/rx-glasses-pdp/",
    meta: rx_45glasses_45pdpSBAAcBoEqFMeta || {},
    alias: rx_45glasses_45pdpSBAAcBoEqFMeta?.alias || [],
    redirect: rx_45glasses_45pdpSBAAcBoEqFMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/rx-glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: rx_45glasses_45pdpSBAAcBoEqFMeta?.name ?? "p-rx-glasses-pdp___pl-pl",
    path: rx_45glasses_45pdpSBAAcBoEqFMeta?.path ?? "/p/rx-glasses-pdp/",
    meta: rx_45glasses_45pdpSBAAcBoEqFMeta || {},
    alias: rx_45glasses_45pdpSBAAcBoEqFMeta?.alias || [],
    redirect: rx_45glasses_45pdpSBAAcBoEqFMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/rx-glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: rx_45glasses_45pdpSBAAcBoEqFMeta?.name ?? "p-rx-glasses-pdp___de-ch",
    path: rx_45glasses_45pdpSBAAcBoEqFMeta?.path ?? "/p/rx-glasses-pdp/",
    meta: rx_45glasses_45pdpSBAAcBoEqFMeta || {},
    alias: rx_45glasses_45pdpSBAAcBoEqFMeta?.alias || [],
    redirect: rx_45glasses_45pdpSBAAcBoEqFMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/rx-glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: rx_45glasses_45pdpSBAAcBoEqFMeta?.name ?? "p-rx-glasses-pdp___fr-ch",
    path: rx_45glasses_45pdpSBAAcBoEqFMeta?.path ?? "/p/rx-glasses-pdp/",
    meta: rx_45glasses_45pdpSBAAcBoEqFMeta || {},
    alias: rx_45glasses_45pdpSBAAcBoEqFMeta?.alias || [],
    redirect: rx_45glasses_45pdpSBAAcBoEqFMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/rx-glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: rx_45glasses_45pdpSBAAcBoEqFMeta?.name ?? "p-rx-glasses-pdp___it-ch",
    path: rx_45glasses_45pdpSBAAcBoEqFMeta?.path ?? "/p/rx-glasses-pdp/",
    meta: rx_45glasses_45pdpSBAAcBoEqFMeta || {},
    alias: rx_45glasses_45pdpSBAAcBoEqFMeta?.alias || [],
    redirect: rx_45glasses_45pdpSBAAcBoEqFMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/p/rx-glasses-pdp.vue").then(m => m.default || m)
  },
  {
    name: ropo_45basketmTSzJt8DH9Meta?.name ?? "ropo-basket___de-at",
    path: ropo_45basketmTSzJt8DH9Meta?.path ?? "/anprobeliste/",
    meta: ropo_45basketmTSzJt8DH9Meta || {},
    alias: ropo_45basketmTSzJt8DH9Meta?.alias || [],
    redirect: ropo_45basketmTSzJt8DH9Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/ropo-basket.vue").then(m => m.default || m)
  },
  {
    name: ropo_45basketmTSzJt8DH9Meta?.name ?? "ropo-basket___de-de",
    path: ropo_45basketmTSzJt8DH9Meta?.path ?? "/anprobeliste/",
    meta: ropo_45basketmTSzJt8DH9Meta || {},
    alias: ropo_45basketmTSzJt8DH9Meta?.alias || [],
    redirect: ropo_45basketmTSzJt8DH9Meta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/ropo-basket.vue").then(m => m.default || m)
  },
  {
    name: ropo_45plpVOtPSZhmJtMeta?.name ?? "ropo-plp___de-at",
    path: ropo_45plpVOtPSZhmJtMeta?.path ?? "/anprobevorort/",
    meta: ropo_45plpVOtPSZhmJtMeta || {},
    alias: ropo_45plpVOtPSZhmJtMeta?.alias || [],
    redirect: ropo_45plpVOtPSZhmJtMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/ropo-plp.vue").then(m => m.default || m)
  },
  {
    name: ropo_45plpVOtPSZhmJtMeta?.name ?? "ropo-plp___de-de",
    path: ropo_45plpVOtPSZhmJtMeta?.path ?? "/anprobevorort/",
    meta: ropo_45plpVOtPSZhmJtMeta || {},
    alias: ropo_45plpVOtPSZhmJtMeta?.alias || [],
    redirect: ropo_45plpVOtPSZhmJtMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/ropo-plp.vue").then(m => m.default || m)
  },
  {
    name: searchFHwENevToFMeta?.name ?? "search___de-at",
    path: searchFHwENevToFMeta?.path ?? "/search/",
    meta: searchFHwENevToFMeta || {},
    alias: searchFHwENevToFMeta?.alias || [],
    redirect: searchFHwENevToFMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchFHwENevToFMeta?.name ?? "search___de-de",
    path: searchFHwENevToFMeta?.path ?? "/search/",
    meta: searchFHwENevToFMeta || {},
    alias: searchFHwENevToFMeta?.alias || [],
    redirect: searchFHwENevToFMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchFHwENevToFMeta?.name ?? "search___cs-cz",
    path: searchFHwENevToFMeta?.path ?? "/search/",
    meta: searchFHwENevToFMeta || {},
    alias: searchFHwENevToFMeta?.alias || [],
    redirect: searchFHwENevToFMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchFHwENevToFMeta?.name ?? "search___pl-pl",
    path: searchFHwENevToFMeta?.path ?? "/search/",
    meta: searchFHwENevToFMeta || {},
    alias: searchFHwENevToFMeta?.alias || [],
    redirect: searchFHwENevToFMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchFHwENevToFMeta?.name ?? "search___de-ch",
    path: searchFHwENevToFMeta?.path ?? "/search/",
    meta: searchFHwENevToFMeta || {},
    alias: searchFHwENevToFMeta?.alias || [],
    redirect: searchFHwENevToFMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchFHwENevToFMeta?.name ?? "search___fr-ch",
    path: searchFHwENevToFMeta?.path ?? "/search/",
    meta: searchFHwENevToFMeta || {},
    alias: searchFHwENevToFMeta?.alias || [],
    redirect: searchFHwENevToFMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchFHwENevToFMeta?.name ?? "search___it-ch",
    path: searchFHwENevToFMeta?.path ?? "/search/",
    meta: searchFHwENevToFMeta || {},
    alias: searchFHwENevToFMeta?.alias || [],
    redirect: searchFHwENevToFMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/search.vue").then(m => m.default || m)
  },
  {
    name: auftragsstatusVXzmIvJBbHMeta?.name ?? "service-auftragsstatus___de-at",
    path: auftragsstatusVXzmIvJBbHMeta?.path ?? "/service/auftragsstatus/",
    meta: auftragsstatusVXzmIvJBbHMeta || {},
    alias: auftragsstatusVXzmIvJBbHMeta?.alias || [],
    redirect: auftragsstatusVXzmIvJBbHMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/auftragsstatus.vue").then(m => m.default || m)
  },
  {
    name: auftragsstatusVXzmIvJBbHMeta?.name ?? "service-auftragsstatus___de-de",
    path: auftragsstatusVXzmIvJBbHMeta?.path ?? "/service/auftragsstatus/",
    meta: auftragsstatusVXzmIvJBbHMeta || {},
    alias: auftragsstatusVXzmIvJBbHMeta?.alias || [],
    redirect: auftragsstatusVXzmIvJBbHMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/auftragsstatus.vue").then(m => m.default || m)
  },
  {
    name: auftragsstatusVXzmIvJBbHMeta?.name ?? "service-auftragsstatus___pl-pl",
    path: auftragsstatusVXzmIvJBbHMeta?.path ?? "/uslugi/status/",
    meta: auftragsstatusVXzmIvJBbHMeta || {},
    alias: auftragsstatusVXzmIvJBbHMeta?.alias || [],
    redirect: auftragsstatusVXzmIvJBbHMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/auftragsstatus.vue").then(m => m.default || m)
  },
  {
    name: auftragsstatusVXzmIvJBbHMeta?.name ?? "service-auftragsstatus___de-ch",
    path: auftragsstatusVXzmIvJBbHMeta?.path ?? "/service/auftragsstatus/",
    meta: auftragsstatusVXzmIvJBbHMeta || {},
    alias: auftragsstatusVXzmIvJBbHMeta?.alias || [],
    redirect: auftragsstatusVXzmIvJBbHMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/auftragsstatus.vue").then(m => m.default || m)
  },
  {
    name: auftragsstatusVXzmIvJBbHMeta?.name ?? "service-auftragsstatus___fr-ch",
    path: auftragsstatusVXzmIvJBbHMeta?.path ?? "/service/statut/",
    meta: auftragsstatusVXzmIvJBbHMeta || {},
    alias: auftragsstatusVXzmIvJBbHMeta?.alias || [],
    redirect: auftragsstatusVXzmIvJBbHMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/auftragsstatus.vue").then(m => m.default || m)
  },
  {
    name: auftragsstatusVXzmIvJBbHMeta?.name ?? "service-auftragsstatus___it-ch",
    path: auftragsstatusVXzmIvJBbHMeta?.path ?? "/servizi/stato/",
    meta: auftragsstatusVXzmIvJBbHMeta || {},
    alias: auftragsstatusVXzmIvJBbHMeta?.alias || [],
    redirect: auftragsstatusVXzmIvJBbHMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/auftragsstatus.vue").then(m => m.default || m)
  },
  {
    name: gebrauchsanweisungenakdmtwld5eMeta?.name ?? "service-gebrauchsanweisungen___de-at",
    path: gebrauchsanweisungenakdmtwld5eMeta?.path ?? "/service/gebrauchsanweisungen/",
    meta: gebrauchsanweisungenakdmtwld5eMeta || {},
    alias: gebrauchsanweisungenakdmtwld5eMeta?.alias || [],
    redirect: gebrauchsanweisungenakdmtwld5eMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/gebrauchsanweisungen.vue").then(m => m.default || m)
  },
  {
    name: gebrauchsanweisungenakdmtwld5eMeta?.name ?? "service-gebrauchsanweisungen___de-de",
    path: gebrauchsanweisungenakdmtwld5eMeta?.path ?? "/service/gebrauchsanweisungen/",
    meta: gebrauchsanweisungenakdmtwld5eMeta || {},
    alias: gebrauchsanweisungenakdmtwld5eMeta?.alias || [],
    redirect: gebrauchsanweisungenakdmtwld5eMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/gebrauchsanweisungen.vue").then(m => m.default || m)
  },
  {
    name: gebrauchsanweisungenakdmtwld5eMeta?.name ?? "service-gebrauchsanweisungen___cs-cz",
    path: gebrauchsanweisungenakdmtwld5eMeta?.path ?? "/servis/navod-k-pouziti/",
    meta: gebrauchsanweisungenakdmtwld5eMeta || {},
    alias: gebrauchsanweisungenakdmtwld5eMeta?.alias || [],
    redirect: gebrauchsanweisungenakdmtwld5eMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/gebrauchsanweisungen.vue").then(m => m.default || m)
  },
  {
    name: gebrauchsanweisungenakdmtwld5eMeta?.name ?? "service-gebrauchsanweisungen___pl-pl",
    path: gebrauchsanweisungenakdmtwld5eMeta?.path ?? "/uslugi/instrukcje-uzytkowania/",
    meta: gebrauchsanweisungenakdmtwld5eMeta || {},
    alias: gebrauchsanweisungenakdmtwld5eMeta?.alias || [],
    redirect: gebrauchsanweisungenakdmtwld5eMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/gebrauchsanweisungen.vue").then(m => m.default || m)
  },
  {
    name: gebrauchsanweisungenakdmtwld5eMeta?.name ?? "service-gebrauchsanweisungen___de-ch",
    path: gebrauchsanweisungenakdmtwld5eMeta?.path ?? "/service/gebrauchsanweisungen/",
    meta: gebrauchsanweisungenakdmtwld5eMeta || {},
    alias: gebrauchsanweisungenakdmtwld5eMeta?.alias || [],
    redirect: gebrauchsanweisungenakdmtwld5eMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/gebrauchsanweisungen.vue").then(m => m.default || m)
  },
  {
    name: gebrauchsanweisungenakdmtwld5eMeta?.name ?? "service-gebrauchsanweisungen___fr-ch",
    path: gebrauchsanweisungenakdmtwld5eMeta?.path ?? "/service/mode-demploi/",
    meta: gebrauchsanweisungenakdmtwld5eMeta || {},
    alias: gebrauchsanweisungenakdmtwld5eMeta?.alias || [],
    redirect: gebrauchsanweisungenakdmtwld5eMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/gebrauchsanweisungen.vue").then(m => m.default || m)
  },
  {
    name: gebrauchsanweisungenakdmtwld5eMeta?.name ?? "service-gebrauchsanweisungen___it-ch",
    path: gebrauchsanweisungenakdmtwld5eMeta?.path ?? "/servizi/istruzioni-per-l-uso/",
    meta: gebrauchsanweisungenakdmtwld5eMeta || {},
    alias: gebrauchsanweisungenakdmtwld5eMeta?.alias || [],
    redirect: gebrauchsanweisungenakdmtwld5eMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/gebrauchsanweisungen.vue").then(m => m.default || m)
  },
  {
    name: konformitaetserklaerungenoPVdGOzYRfMeta?.name ?? "service-konformitaetserklaerungen___de-at",
    path: konformitaetserklaerungenoPVdGOzYRfMeta?.path ?? "/service/konformitaetserklaerungen/",
    meta: konformitaetserklaerungenoPVdGOzYRfMeta || {},
    alias: konformitaetserklaerungenoPVdGOzYRfMeta?.alias || [],
    redirect: konformitaetserklaerungenoPVdGOzYRfMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/konformitaetserklaerungen.vue").then(m => m.default || m)
  },
  {
    name: konformitaetserklaerungenoPVdGOzYRfMeta?.name ?? "service-konformitaetserklaerungen___de-de",
    path: konformitaetserklaerungenoPVdGOzYRfMeta?.path ?? "/service/konformitaetserklaerungen/",
    meta: konformitaetserklaerungenoPVdGOzYRfMeta || {},
    alias: konformitaetserklaerungenoPVdGOzYRfMeta?.alias || [],
    redirect: konformitaetserklaerungenoPVdGOzYRfMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/konformitaetserklaerungen.vue").then(m => m.default || m)
  },
  {
    name: konformitaetserklaerungenoPVdGOzYRfMeta?.name ?? "service-konformitaetserklaerungen___de-ch",
    path: konformitaetserklaerungenoPVdGOzYRfMeta?.path ?? "/service/konformitaetserklaerungen/",
    meta: konformitaetserklaerungenoPVdGOzYRfMeta || {},
    alias: konformitaetserklaerungenoPVdGOzYRfMeta?.alias || [],
    redirect: konformitaetserklaerungenoPVdGOzYRfMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/konformitaetserklaerungen.vue").then(m => m.default || m)
  },
  {
    name: konformitaetserklaerungenoPVdGOzYRfMeta?.name ?? "service-konformitaetserklaerungen___fr-ch",
    path: konformitaetserklaerungenoPVdGOzYRfMeta?.path ?? "/service/certificat-de-conformite/",
    meta: konformitaetserklaerungenoPVdGOzYRfMeta || {},
    alias: konformitaetserklaerungenoPVdGOzYRfMeta?.alias || [],
    redirect: konformitaetserklaerungenoPVdGOzYRfMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/konformitaetserklaerungen.vue").then(m => m.default || m)
  },
  {
    name: konformitaetserklaerungenoPVdGOzYRfMeta?.name ?? "service-konformitaetserklaerungen___it-ch",
    path: konformitaetserklaerungenoPVdGOzYRfMeta?.path ?? "/servizi/dichiarazione-di-conformita/",
    meta: konformitaetserklaerungenoPVdGOzYRfMeta || {},
    alias: konformitaetserklaerungenoPVdGOzYRfMeta?.alias || [],
    redirect: konformitaetserklaerungenoPVdGOzYRfMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/service/konformitaetserklaerungen.vue").then(m => m.default || m)
  },
  {
    name: signinHDEBWpeZkmMeta?.name ?? "signin___de-at",
    path: signinHDEBWpeZkmMeta?.path ?? "/signin/",
    meta: signinHDEBWpeZkmMeta || {},
    alias: signinHDEBWpeZkmMeta?.alias || [],
    redirect: signinHDEBWpeZkmMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signinHDEBWpeZkmMeta?.name ?? "signin___de-de",
    path: signinHDEBWpeZkmMeta?.path ?? "/signin/",
    meta: signinHDEBWpeZkmMeta || {},
    alias: signinHDEBWpeZkmMeta?.alias || [],
    redirect: signinHDEBWpeZkmMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signinHDEBWpeZkmMeta?.name ?? "signin___cs-cz",
    path: signinHDEBWpeZkmMeta?.path ?? "/signin/",
    meta: signinHDEBWpeZkmMeta || {},
    alias: signinHDEBWpeZkmMeta?.alias || [],
    redirect: signinHDEBWpeZkmMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signinHDEBWpeZkmMeta?.name ?? "signin___pl-pl",
    path: signinHDEBWpeZkmMeta?.path ?? "/signin/",
    meta: signinHDEBWpeZkmMeta || {},
    alias: signinHDEBWpeZkmMeta?.alias || [],
    redirect: signinHDEBWpeZkmMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signinHDEBWpeZkmMeta?.name ?? "signin___de-ch",
    path: signinHDEBWpeZkmMeta?.path ?? "/signin/",
    meta: signinHDEBWpeZkmMeta || {},
    alias: signinHDEBWpeZkmMeta?.alias || [],
    redirect: signinHDEBWpeZkmMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signinHDEBWpeZkmMeta?.name ?? "signin___fr-ch",
    path: signinHDEBWpeZkmMeta?.path ?? "/signin/",
    meta: signinHDEBWpeZkmMeta || {},
    alias: signinHDEBWpeZkmMeta?.alias || [],
    redirect: signinHDEBWpeZkmMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signinHDEBWpeZkmMeta?.name ?? "signin___it-ch",
    path: signinHDEBWpeZkmMeta?.path ?? "/signin/",
    meta: signinHDEBWpeZkmMeta || {},
    alias: signinHDEBWpeZkmMeta?.alias || [],
    redirect: signinHDEBWpeZkmMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: successZCNJSmmWCtMeta?.name ?? "success___de-at",
    path: successZCNJSmmWCtMeta?.path ?? "/success/",
    meta: successZCNJSmmWCtMeta || {},
    alias: successZCNJSmmWCtMeta?.alias || [],
    redirect: successZCNJSmmWCtMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successZCNJSmmWCtMeta?.name ?? "success___de-de",
    path: successZCNJSmmWCtMeta?.path ?? "/success/",
    meta: successZCNJSmmWCtMeta || {},
    alias: successZCNJSmmWCtMeta?.alias || [],
    redirect: successZCNJSmmWCtMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successZCNJSmmWCtMeta?.name ?? "success___cs-cz",
    path: successZCNJSmmWCtMeta?.path ?? "/success/",
    meta: successZCNJSmmWCtMeta || {},
    alias: successZCNJSmmWCtMeta?.alias || [],
    redirect: successZCNJSmmWCtMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successZCNJSmmWCtMeta?.name ?? "success___pl-pl",
    path: successZCNJSmmWCtMeta?.path ?? "/success/",
    meta: successZCNJSmmWCtMeta || {},
    alias: successZCNJSmmWCtMeta?.alias || [],
    redirect: successZCNJSmmWCtMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successZCNJSmmWCtMeta?.name ?? "success___de-ch",
    path: successZCNJSmmWCtMeta?.path ?? "/success/",
    meta: successZCNJSmmWCtMeta || {},
    alias: successZCNJSmmWCtMeta?.alias || [],
    redirect: successZCNJSmmWCtMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successZCNJSmmWCtMeta?.name ?? "success___fr-ch",
    path: successZCNJSmmWCtMeta?.path ?? "/success/",
    meta: successZCNJSmmWCtMeta || {},
    alias: successZCNJSmmWCtMeta?.alias || [],
    redirect: successZCNJSmmWCtMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successZCNJSmmWCtMeta?.name ?? "success___it-ch",
    path: successZCNJSmmWCtMeta?.path ?? "/success/",
    meta: successZCNJSmmWCtMeta || {},
    alias: successZCNJSmmWCtMeta?.alias || [],
    redirect: successZCNJSmmWCtMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/success.vue").then(m => m.default || m)
  },
  {
    name: wishlists2nZdBkxyIMeta?.name ?? "wishlist___de-at",
    path: wishlists2nZdBkxyIMeta?.path ?? "/wishlist/",
    meta: wishlists2nZdBkxyIMeta || {},
    alias: wishlists2nZdBkxyIMeta?.alias || [],
    redirect: wishlists2nZdBkxyIMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlists2nZdBkxyIMeta?.name ?? "wishlist___de-de",
    path: wishlists2nZdBkxyIMeta?.path ?? "/wishlist/",
    meta: wishlists2nZdBkxyIMeta || {},
    alias: wishlists2nZdBkxyIMeta?.alias || [],
    redirect: wishlists2nZdBkxyIMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlists2nZdBkxyIMeta?.name ?? "wishlist___cs-cz",
    path: wishlists2nZdBkxyIMeta?.path ?? "/wishlist/",
    meta: wishlists2nZdBkxyIMeta || {},
    alias: wishlists2nZdBkxyIMeta?.alias || [],
    redirect: wishlists2nZdBkxyIMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlists2nZdBkxyIMeta?.name ?? "wishlist___pl-pl",
    path: wishlists2nZdBkxyIMeta?.path ?? "/wishlist/",
    meta: wishlists2nZdBkxyIMeta || {},
    alias: wishlists2nZdBkxyIMeta?.alias || [],
    redirect: wishlists2nZdBkxyIMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlists2nZdBkxyIMeta?.name ?? "wishlist___de-ch",
    path: wishlists2nZdBkxyIMeta?.path ?? "/wishlist/",
    meta: wishlists2nZdBkxyIMeta || {},
    alias: wishlists2nZdBkxyIMeta?.alias || [],
    redirect: wishlists2nZdBkxyIMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlists2nZdBkxyIMeta?.name ?? "wishlist___fr-ch",
    path: wishlists2nZdBkxyIMeta?.path ?? "/wishlist/",
    meta: wishlists2nZdBkxyIMeta || {},
    alias: wishlists2nZdBkxyIMeta?.alias || [],
    redirect: wishlists2nZdBkxyIMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlists2nZdBkxyIMeta?.name ?? "wishlist___it-ch",
    path: wishlists2nZdBkxyIMeta?.path ?? "/wishlist/",
    meta: wishlists2nZdBkxyIMeta || {},
    alias: wishlists2nZdBkxyIMeta?.alias || [],
    redirect: wishlists2nZdBkxyIMeta?.redirect,
    component: () => import("/builds/aboutyou/cloud-agency/shop-application/fim-sfa/pages/wishlist.vue").then(m => m.default || m)
  }
]