import type { Order, PublicShopConfig } from '@scayle/storefront-nuxt'
import type {
  EcommerceData,
  TrackingEvent,
  TrackingPayload,
} from '../trackingTypes'

const usePurchaseEvents = (
  track: (event: TrackingEvent, payload: TrackingPayload) => any,
  $currentShop: PublicShopConfig,
) => ({
  trackPurchaseEvent: (cbdData: Order) => {
    const { $i18n } = useNuxtApp()
    const ecommerce = mapCbdDataToEcommerceData(
      cbdData,
      unref($currentShop)?.currency,
      $i18n,
    )

    const isActiveUser =
      cbdData?.customer?.status?.isActive &&
      !cbdData?.customer?.status?.isGuestCustomer

    const user: EcommerceData['user'] = {
      email: cbdData?.customer?.email ?? '',
      loginStatus: isActiveUser ? 'logged in' : 'logged out',
      loginType: isActiveUser ? 'Account' : 'Guest',
    }

    track('FielmannBasic_EC_Purchase', {
      ecommerce,
      user,
    })
  },
})

export default usePurchaseEvents
