<template>
  <div class="w-full lg:relative">
    <!-- MOBILE ONLY Search Button -->
    <div class="md:hidden">
      <FimButton
        v-if="!showSearchBarMobile"
        key="search-btn"
        class="h-[42px] border-transparent"
        type="ghost"
        :aria-label="$t('search.label')"
        no-padding
        @click="openSearchBarForMobile"
      >
        <IconFielmannSearch class="size-7" />
      </FimButton>
      <FimButton
        v-if="showSearchBarMobile"
        key="close-btn"
        class="h-[42px] border-none"
        type="ghost"
        :aria-label="$t('search.close_label')"
        no-padding
        @click="
          () => {
            showSearchBarMobile = false
            resetSearch()
          }
        "
      >
        <IconFielmannClose class="size-7" />
      </FimButton>
    </div>
    <div class="z-30 w-full">
      <div
        class="container-search-mobile absolute left-0 z-30 w-full border border-x-0 border-gray-300 bg-white px-6 py-2 before:border-l before:border-t before:border-gray-300 md:relative md:top-0 md:block md:border-transparent md:bg-transparent md:p-0 header:py-2"
        :class="{ hidden: !showSearchBarMobile }"
      >
        <div class="relative rounded-md border border-gray-300">
          <div class="absolute right-0 top-0 h-full">
            <div
              class="flex h-full items-center last:rounded-l-none last:border-0"
            >
              <FadeInTransition>
                <FimButton
                  v-if="searchQuery.length > 0"
                  type="ghost"
                  class="h-full w-10 border-x-0 border-transparent"
                  :class="{ 'border-black': isFocused }"
                  :aria-label="$t('search.reset')"
                  data-testid="header_search_button_reset"
                  no-padding
                  @click="resetSearch"
                >
                  <template #icon>
                    <IconFielmannClose class="size-5" />
                  </template>
                </FimButton>
              </FadeInTransition>
              <FimButton
                :type="isFocused ? 'primary' : 'secondary'"
                :aria-label="$t('search.submit')"
                class="h-full w-10 border-transparent disabled:!opacity-100"
                :disabled="searchQuery === ''"
                data-testid="header_search_button_submit"
                no-padding
                @click="goToSearchPage"
              >
                <template #icon>
                  <IconFielmannSearch class="size-6" />
                </template>
              </FimButton>
              <button
                v-if="isSyteEnabled"
                href="javascript:void(0)"
                class="--syte-start-camera-upload h-full w-10 text-center"
                data-camera-button-placement="search-bar"
                data-tracking-label="Image Search | search-bar"
                :aria-label="$t('search.syte_button')"
                @click="trackClick"
              >
                <IconFielmannCameraBordered
                  class="pointer-events-none inline-block size-6"
                />
              </button>
            </div>
          </div>
          <!-- without the @input the searchQuery is not updated on mobile phones -->
          <input
            id="search"
            ref="searchInput"
            v-model="searchQuery"
            data-testid="header_search_input"
            :placeholder="$t('search.placeholder')"
            autocomplete="off"
            class="w-full rounded-md border border-transparent bg-white py-1.5 pl-3 pr-10 outline-none focus:border-black focus:ring-0"
            type="text"
            @focus="onInputFocus"
            @blur="onInputBlur"
            @keydown.enter="goToSearchPage"
          />
        </div>
      </div>
    </div>

    <Flyout :is-open="isFocused && showSuggestions">
      <!-- The height needs to be an absolute value (or relative with vh/vw) to be scrollable on mobile phones. VH considers the actual height even with the mobile keyboard open. 126px is 2 times the header height on mobile -->
      <LazySearchResults
        v-if="showSuggestions"
        class="h-[calc(100vh-126px)] overflow-auto md:max-h-[600px]"
        :brands="brands"
        :categories="categories"
        :fetching="pending"
        :product-suggestions="productSuggestions"
        :results-count="count"
        :search-term="searchQuery"
        @on-flyout-dismiss="close"
        @click:result="trackFimSearchResult"
      />
    </Flyout>
  </div>
</template>

<script setup lang="ts">
import { debounce } from 'radash'
import type { TypeaheadSuggestion } from '@scayle/storefront-nuxt'
import {
  PRODUCT_LIMIT,
  DEBOUNCED_SEARCH_DURATION,
  MIN_CHARS_FOR_SEARCH,
} from '~/constants/search'
import { rxLensProductMasterKeys } from '~/constants/rx'
import { buildSearchData } from '~/utils/tracking'
import useSearchCounter from '~/composables/tracking/useSearchCounter'
import type { Product } from '@scayle/storefront-core'
import type { SearchResultData } from '~/composables/tracking/trackingTypes'
const scope = effectScope()
onUnmounted(() => scope.stop())
const { incrementSearchCounter } = useSearchCounter()
const searchTrackingData = ref<SearchResultData>()

const { data, search, searchQuery, resetSearch, pending } = useSearch({
  key: 'header-search',
  params: {
    with: {
      products: {
        lowestPriorPrice: true,
        attributes: 'all',
        advancedAttributes: 'all',
      },
      categories: {
        parents: 'all',
        children: 10,
      },
    },
  },
})
const { isSyteEnabled } = await useFeatureToggles()
const {
  trackButtonClick,
  trackSearchResultClick,
  trackSearchResultView,
  trackSelectItem,
  trackViewItemList,
} = await useTrackingEvents()
const localePath = useFimLocalePath()
const router = useRouter()
const isMobile = () => useViewport().isLessThan('md')

const route = useRoute()
const showSuggestions = computed(() => {
  if (searchQuery.value) {
    return true
  }
  return hasResults.value
})
const showSearchBarMobile = ref(false)

const searchInput = ref<HTMLCanvasElement | null>(null)
const openSearchBarForMobile = () => {
  showSearchBarMobile.value = true

  nextTick(function () {
    searchInput.value?.focus()
  })
}

const onInputFocus = () => {
  isFocused.value = true
  // Solves https://aboutyou.atlassian.net/browse/SCFIM-1538
  document.querySelector('html')?.setAttribute('data-is-search-focused', '')
}

const trackSearchView = () => {
  if (!searchTrackingData.value) {
    return
  }

  trackSearchResultView(searchTrackingData.value)
  trackViewItemList(
    [],
    {
      id: CategoryListingMetadata.ID,
      name: CategoryListingMetadata.NAME,
    },
    undefined,
    undefined,
    undefined,
    searchTrackingData.value,
  )
}

const onInputBlur = () => {
  trackSearchView()

  if (isMobile()) {
    return
  }
  isFocused.value = false
  document.querySelector('html')?.removeAttribute('data-is-search-focused')
}

const trackClick = (event: Event) => {
  const element = event?.target as HTMLButtonElement
  trackButtonClick(element)
}

const { products, brands, categories } = useTypeaheadSuggestions(data)

const productSuggestions = computed(
  () =>
    products.value.filter(
      (product) =>
        // Hide rx Lens products
        !rxLensProductMasterKeys.includes(
          product.productSuggestion.product.masterKey || '',
        ),
    ) || [],
)

scope.run(() => {
  if (import.meta.server) {
    return
  }
  // used to store the Search Tracking data
  watch(productSuggestions, () => {
    searchTrackingData.value = buildSearchData(
      searchQuery.value,
      productSuggestions.value.length,
      categories.value.length,
      1,
      'search_results_flyout',
    )
  })
})

const debouncedSearch = debounce(
  { delay: DEBOUNCED_SEARCH_DURATION },
  (value: string) => {
    if (value === '' || value.length < MIN_CHARS_FOR_SEARCH) {
      return
    }
    incrementSearchCounter()
    search({
      term: searchQuery.value,
      productLimit: PRODUCT_LIMIT,
    })
  },
)

const routeFullPath = computed(() => route.fullPath)
watch(routeFullPath, (to, from) => {
  // When changing routes close the open search bar
  if (to !== from) {
    close()
    if (showSearchBarMobile.value) {
      showSearchBarMobile.value = false
    }
  }
})

watch(searchQuery, () => {
  const { value: query = '' } = searchQuery
  if (!query) {
    close()
    return
  }
  debouncedSearch(searchQuery.value)
})

const close = () => {
  searchQuery.value = ''
  resetSearch()
}

const trackFimSearchResult = (item: TypeaheadSuggestion) => {
  const element = {} as {
    label: string
    id: string
  }

  let product

  if ('productSuggestion' in item) {
    element.id = item.productSuggestion.suggestion
    element.label = item.type
    product = item.productSuggestion.product as Product
  } else {
    element.id = item.brandOrCategorySuggestion.suggestion
    element.label = 'category'
  }

  const searchData = buildSearchData(
    searchQuery.value,
    productSuggestions.value.length,
    brands.value.length,
    1,
    'search_results_flyout',
    true,
  )
  trackSearchResultClick(searchData, element)

  if (product) {
    trackSelectItem(
      product,
      {
        categoryId: CategoryListingMetadata.ID,
        categoryName: CategoryListingMetadata.NAME,
      },
      undefined,
      undefined,
      undefined,
      searchData,
    )
  }

  close()
}

const goToSearchPage = async () => {
  if (searchQuery.value?.trim().length > 0) {
    const searchQueryCopy = `${searchQuery.value?.trim()}`
    close()
    await router.push(localePath(getSearchRoute(searchQueryCopy)))
  }
}

const count = computed(() => data?.value?.suggestions.length)
const hasResults = computed(() =>
  Boolean(productSuggestions.value.length || categories.value.length),
)
const isFocused = ref(false)
</script>

<style lang="scss">
.disabled-link {
  pointer-events: none;
}

.container-search-mobile::before {
  @media screen and (width < 768px) {
    content: '';
  }

  width: 0.75rem;
  height: 0.75rem;
  top: -0.45rem;
  right: 9.9rem;
  position: absolute;
  transform: rotate(45deg);
  background-color: #ffffff;

  @media screen and (width >= 375px) {
    right: 13.5rem;
  }

  @media screen and (width >= 768px) {
    display: none;
  }
}
</style>
