export default defineNuxtRouteMiddleware(async (to, _from) => {
  const { isLoggedIn, user } = await useUser()
  const getLocalPath = useFimLocalePath()
  const localePath = (routePath: LinkList[keyof LinkList]['path']) => {
    return getLocalPath(routePath) || routePath
  }

  if (to.path === routeList.signin.path && isLoggedIn.value) {
    return await navigateTo({ path: localePath(routeList.account.path) })
  }

  const getProtectedRoutes = (exclude: (keyof LinkList)[] = []) => {
    const routes = Object.entries(
      usePick(routeList, [
        'account',
        'user',
        'appointments',
        'order',
        'orderDetail',
        'branchOrder',
        'branchOrderDetail',
        'checkout',
      ]),
    ).reduce((routeMap, [name, value]) => {
      if (exclude.includes(name)) {
        return routeMap
      }

      Object.assign(routeMap, { [name]: value })
      return routeMap
    }, {} as Partial<LinkList>)

    return Object.values(routes).map((it) => localePath(it.path))
  }

  const isGuest = !!user.value?.status?.isGuestCustomer

  const isProtectedRoute = getProtectedRoutes().includes(to.path)
  const isProtectRouteForGuest =
    isGuest && getProtectedRoutes(['checkout']).includes(to.path)

  if (!isLoggedIn.value && isProtectedRoute) {
    return await navigateTo({
      path: localePath(routeList.signin.path),
      query: {
        redirectUrl: to.fullPath,
        showBasket: `${to.fullPath === routeList.checkout.path}`,
      },
    })
  }
  if (isLoggedIn.value && isProtectRouteForGuest) {
    const redirectPath = (to.query.redirectUrl as string) || routeList.home.path
    return await navigateTo({ path: redirectPath })
  }
})
