import type { TrackingEvent, TrackingPayload } from '../trackingTypes'

const useFilterEvents = (
  track: (event: TrackingEvent, payload: TrackingPayload) => any,
) => ({
  trackFilter: (filterName: string, filterValues: string) => {
    track('filter', {
      filter_name: filterName,
      filter_values: filterValues,
    })
  },
})

export default useFilterEvents
