import type { BasketItem, Product, Variant } from '@scayle/storefront-nuxt'
import type {
  ProductListData,
  TrackingEvent,
  TrackingPayload,
} from '../trackingTypes'

const collectBasketItems = (
  items: any[],
  options: { listName: string; listId: string },
) => {
  return items.map(
    (basketItem: any, idx: number): ProductListData => ({
      product: basketItem.product,
      list: {
        name: options.listName,
        id: options.listId,
        index: idx + 1,
      },
      quantity: basketItem.quantity,
    }),
  )
}

const useCheckoutEvents = (
  track: (event: TrackingEvent, payload: TrackingPayload) => any,
) => ({
  trackBeginCheckout: (
    items: BasketItem<Product, Variant>[] = [],
    listName: string,
    listId: string,
  ) => {
    track('FielmannBasic_EC_BeginCheckout', {
      items: collectBasketItems(items, { listName, listId }),
    })
  },
  collectBasketItems,
})
export default useCheckoutEvents
