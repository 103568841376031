import { rpcCall } from '@scayle/storefront-nuxt'
import type { LoggingParams } from '~/rpcMethods/logging'

export const useLogging = () => {
  const nuxtApp = useNuxtApp()
  const currentShop = useCurrentShop()

  const log = (
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) =>
    rpcCall(
      nuxtApp,
      'logMessage',
      currentShop.value,
    )({ message, extras, level: 'log' })
  const warn = (
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) =>
    rpcCall(
      nuxtApp,
      'logMessage',
      currentShop.value,
    )({ message, extras, level: 'warn' })
  const error = (
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) =>
    rpcCall(
      nuxtApp,
      'logMessage',
      currentShop.value,
    )({ message, extras, level: 'error' })
  const trace = (
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) =>
    rpcCall(
      nuxtApp,
      'logMessage',
      currentShop.value,
    )({ message, extras, level: 'trace' })
  const debug = (
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) =>
    rpcCall(
      nuxtApp,
      'logMessage',
      currentShop.value,
    )({ message, extras, level: 'debug' })
  const table = (
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) =>
    rpcCall(
      nuxtApp,
      'logMessage',
      currentShop.value,
    )({ message, extras, level: 'table' })

  const logger = { log, warn, error, trace, debug, table }

  return {
    logger,
  }
}
