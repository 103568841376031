<template>
  <ErrorStack v-if="isDevMode" :error="error" />
  <div
    v-if="pending"
    class="container mx-auto space-y-2 py-4"
    data-testid="error-template"
  >
    <div class="w-full max-w-xl">
      <Skeleton class="mb-10 w-full" full-width />
      <Skeleton class="mb-10 w-full" full-width />
      <Skeleton class="mb-10 w-full" full-width />
    </div>
  </div>
  <div
    v-else-if="storyData"
    class="container mx-auto mt-10 max-w-7xl py-4"
    data-testid="error-template"
  >
    <!-- @todo -->
    <!-- <LazyHydrate when-visible> -->
    <div>
      <component
        :is="blok.component"
        v-for="blok in contentBlocks"
        :key="blok._uid"
        :preload-media="true"
        :blok="blok"
        class="mx-auto max-w-7xl"
      />
    </div>
    <!-- </LazyHydrate> -->
  </div>
  <div
    v-else
    class="mx-auto mt-10 flex max-w-6xl flex-col px-4 md:px-12"
    data-testid="error-template"
  >
    <section>
      <Headline tag="h2" class="mb-6 text-4xl md:text-5xl">
        {{ title }}
      </Headline>
      <p class="mt-6 font-semibold">{{ message }}</p>
      <I18nT keypath="error.instructions" tag="p" class="mt-6">
        <template #homepage_link>
          <FimLink
            :to="links[0].linkUrl"
            :only-exact-active="true"
            type="underline"
            text-size="sm"
          >
            {{ links[0].linkText }}
          </FimLink>
        </template>
      </I18nT>
    </section>
    <section class="mt-6 flex flex-col items-start">
      <FimLink
        v-for="{ linkText, linkUrl } in links"
        :key="linkText"
        :to="linkUrl"
        type="underline"
        class="mb-2"
        :only-exact-active="true"
        text-size="sm"
      >
        {{ linkText }}
        <template #iconAfter>
          <IconFielmannArrowRight class="size-4" />
        </template>
      </FimLink>
    </section>
  </div>
</template>

<script setup lang="ts">
/* eslint sonarjs/no-duplicate-string: 1 */

import type { NuxtError } from 'nuxt/app'
import type { SbUtilityPage } from '~/storyblok/types/storyblok'

type CustomError = {
  url: string
  statusCode: number
  statusMessage: string
  message: string
  description: string
  data?: any
  stack?: string
}

type AppError = NuxtError | Error | CustomError | string | null | undefined

type UnifiedError = {
  message: string
  status: number
  stack?: string
}

const props = defineProps({
  error: {
    type: Object as PropType<AppError>,
    default: undefined,
  },
})

defineEmits(['clear-error'])

const $i18n = useI18n()
const router = useRouter()
const isDevMode = import.meta.dev

/**
 * tracking
 */

const trackingPromise = useTrackingEvents()
const track = async () => {
  if (!is404.value) {
    return
  }

  const { trackPageView } = await trackingPromise
  // store.commit('SET_PAGETYPE', 'error')
  trackPageView({
    eventName: 'FielmannBasic_404Error',
    contentName: router.currentRoute.value?.path || '',
    title: cmsMeta.value.title ?? message.value,
    pageType: 'error',
    pageTypeId: router.currentRoute.value?.params?.slug?.toString() || '',
    clickOrigin: '',
    error: {
      referringURL: document.referrer,
      errorURL: window.location.href,
    },
  })
}

onBeforeMount(() => {
  track()
})

/**
 * error
 */

const error = computed(() => wrapError(props.error))

const isNuxtError = (error: AppError): error is NuxtError =>
  (error?.constructor as any)?.__h3_error__ === true

const hasKeys = (input: any, keys: string[]) => {
  const objectKeys = Object.keys(input || {})
  return keys.every((key) => objectKeys.includes(key))
}

const isCustomError = (error: AppError): error is CustomError =>
  hasKeys(error, ['url', 'statusCode', 'statusMessage', 'message'])

const wrapError = (error: AppError): UnifiedError => {
  const fallbackMessage = $i18n.t('error.status_message_unknown')
  if (typeof error === 'string') {
    try {
      const data: any = JSON.parse(error)
      return {
        message: data?.message || data?.response || fallbackMessage,
        status: data?.status || data?.statusCode || 500,
        stack: undefined,
      }
    } catch {
      return { message: error, status: 500, stack: '' }
    }
  }

  if (isNuxtError(error) || isCustomError(error)) {
    return {
      status: error.statusCode,
      message: error.statusMessage || error.message || fallbackMessage,
      stack: error.stack,
    }
  }

  if (error instanceof Error) {
    return {
      message: error.message,
      status: 500,
      stack: error.stack,
    }
  }

  return {
    message: fallbackMessage,
    status: 500,
    stack: undefined,
  }
}

/**
 * content data
 */

const is404 = computed(() => wrapError(props.error).status === 404)
const title = computed(() =>
  is404.value ? $i18n.t('error.404_title') : $i18n.t('error.generic_title'),
)
const message = computed(() =>
  is404.value ? $i18n.t('error.404_message') : $i18n.t('error.generic_message'),
)

const cmsParams = computed(() => ({
  slug: is404.value ? 'not-found' : 'error',
  baseFolder: CmsBaseFolder.Global,
}))

const { data: storyData, pending } = useCmsStory<SbUtilityPage>({
  params: cmsParams,
  key: is404.value ? 'cms-not-found-page' : 'cms-error-page',
})

const contentBlocks = computed(() => storyData.value?.content?.content ?? [])

const cmsMeta = computed<CmsMeta>(() => {
  if (storyData.value && storyData.value.content) {
    return getCmsMeta(storyData.value?.content)
  }
  return {}
})

const links = [
  {
    linkText: $i18n.t('global.homepage'),
    linkUrl: routeList.home.path,
  },
]

/**
 * meta
 */

useSeoMeta(cmsMeta.value)
useHead({ title: title.value })
defineOptions({ name: 'ErrorLayout' })
</script>
