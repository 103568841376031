import type { NuxtApp } from 'nuxt/app'
import { isEqual } from 'radash'
import {
  type CorrectionalPrescriptionValue,
  formatSightSpecificationForDisplay,
} from './sightSpecification'
import { ProductCategories } from './product'
import type { Quantity } from '~/composables/pdp/useLenses'
import { type EyePositionIndex } from '~/utils/contactLenses'

export type IProductSightSpecification = {
  prescriptionValues: {
    left?: CorrectionalPrescriptionValue
    right?: CorrectionalPrescriptionValue
  }
}

export type IProductSightSpecificationOldFormat = {
  eye: EyePositionIndex
  itemDetails: CorrectionalPrescriptionValue
}

export interface CorrectionalPrescriptionValueKlapp
  extends Omit<CorrectionalPrescriptionValue, 'contactLensColor'> {
  // contactLensColor can be an object for Klapp orders, ex.: { de_DE: Grey }
  contactLensColor?: string | { [key: string]: string }
}

export interface MerchBasketItemCustomData {
  weeeRegistrationNumber?: string
}

export const getSightSpecificationFromCustomData = (
  customData: Record<string, unknown> | undefined,
): IProductSightSpecification | undefined => {
  const hasSightSpecification =
    customData &&
    ((customData.itemDetails && customData.eye) ||
      customData.prescriptionValues)
  if (hasSightSpecification) {
    return mapCustomDataSightPrescription(
      customData as
        | IProductSightSpecification
        | IProductSightSpecificationOldFormat,
    )
  }
}

const ensureColorFormat = (
  itemDetails: CorrectionalPrescriptionValueKlapp,
): CorrectionalPrescriptionValue => {
  return {
    ...itemDetails,
    ...(itemDetails.contactLensColor && {
      contactLensColor:
        typeof itemDetails.contactLensColor === 'string'
          ? itemDetails.contactLensColor
          : Object.values(itemDetails.contactLensColor)[0],
    }),
  } as CorrectionalPrescriptionValue
}

export const mapCustomDataSightPrescription = (
  customData: IProductSightSpecification | IProductSightSpecificationOldFormat,
): IProductSightSpecification => {
  if ('eye' in customData && 'itemDetails' in customData) {
    const oldFormat = customData as IProductSightSpecificationOldFormat
    if (customData.eye === 'both') {
      return {
        prescriptionValues: {
          right: ensureColorFormat(oldFormat.itemDetails),
          left: ensureColorFormat(oldFormat.itemDetails),
        },
      }
    }
    return {
      prescriptionValues: {
        [oldFormat.eye]: ensureColorFormat(oldFormat.itemDetails),
      },
    }
  }

  return customData
}

export const isSightSpecificationEqual = (
  sightSpecification: IProductSightSpecification,
): boolean => {
  if (
    'left' in sightSpecification.prescriptionValues &&
    'right' in sightSpecification.prescriptionValues
  ) {
    return isEqual(
      sightSpecification.prescriptionValues.left,
      sightSpecification.prescriptionValues.right,
    )
  }
  return false
}

export const getEyeFromSightSpecification = (
  sightSpecification: IProductSightSpecification,
) => {
  if (isSightSpecificationEqual(sightSpecification)) {
    return 'both'
  }
  return sightSpecification.prescriptionValues.left ? 'left' : 'right'
}

export const getQuantity = (
  eyePosition: EyePositionIndex,
  quantity: number,
): Quantity => {
  const result: Quantity = { left: quantity, right: quantity }
  if (eyePosition === 'left') {
    result.right = 0
  }
  if (eyePosition === 'right') {
    result.left = 0
  }
  return result
}

export const getCustomDataForBasket = (
  eyeData: IProductSightSpecification['prescriptionValues'],
  eyePosition: EyePositionIndex,
  $currentShop: NuxtApp['$currentShop'],
  quantity: Quantity,
  productCategory?: ProductCategories,
) => {
  let sightSpec = eyeData
  let customDataQuantity: Quantity = { left: 0, right: 0 }
  if (eyePosition === 'both') {
    sightSpec = {
      left: eyeData.left,
      right: eyeData.left,
    }
    customDataQuantity = {
      left: quantity.left,
      right: quantity.right,
    }
  } else {
    sightSpec = {
      [eyePosition]: eyeData[eyePosition],
    }
    customDataQuantity[eyePosition] = quantity[eyePosition]
  }

  const prescriptionValuesDisplay = formatSightSpecificationForDisplay(
    sightSpec,
    $currentShop,
    false,
  )

  const result = {
    prescriptionValues: sightSpec,
    quantity: customDataQuantity,
    prescriptionValuesDisplay,
  }

  if (productCategory === ProductCategories.contactLenses) {
    return {
      ...result,
      isContactLens: true,
    }
  }

  return result
}
