import {
  getAppliedReductionsByCategory,
  getFirstAttributeValue,
  getImageFromList,
  getLowestPrice as getLowestVariantPrice,
  getPrice,
  ProductImageType,
  type BasketItemDisplayData,
  type BaskteItemDisplayDataItem,
  type FetchProductsByCategoryParams,
  type Price,
  type Product,
  type PublicShopConfig,
  type Variant,
} from '@scayle/storefront-nuxt'
import type { NuxtApp } from 'nuxt/app'
import { DisplayDataAttributes } from '../types/pdp'
import { RxConfMasterKeys } from '../rpcMethods/rxTypes'
import { RX_CONFIGURATION_ITEM_FRAME_ID } from '../constants/rx'
import {
  PRODUCT_LIFECYCLE_STATUS_OFFLINE,
  PRODUCT_LIFECYCLE_STATUS_PUBLISHED,
  PRODUCT_LIFECYCLE_STATUS_TEMPORARY_NO_SELLABLE,
  PRODUCT_LIFECYCLE_STATUSES,
  PRODUCT_RECOMMENDATION_ATTRIBUTE_REF_RELATED,
  PRODUCT_RECOMMENDATION_ATTRIBUTE_REF_UPSELL,
  PRODUCT_RECOMMENDATION_ATTRIBUTE_TITLE_TRANSLATIONS,
  type ProductLifecycleStatus,
  type ProductRecommendationAttribute,
} from '../constants/product'
import type {
  ProductConfigurationItem,
  ProductConfigurationMainItem,
  ProductConfigurationRxItem,
  ProductConfigurationRxMainItem,
} from '../components/ui/productConfiguration/ProductConfigurationItem'
import {
  isCareProducts,
  isContactLens,
  isCorrectionalGlasses,
  isGlasses,
  isMerch,
  isReadyMadeGlasses,
} from './pdp'
import { getImageForVariant } from './image'
import { getAdvancedAttribute, getFirstAttributeLabel } from './attribute'
import { getLensName, type RxBasketItemDisplayDataKey } from './rx'
import { getIsoCountry } from './locale'
import type { RecommendationLinkFieldNames } from '~/storyblok/types/storyblok'

export {
  ProductImageType,
  getBadgeLabel,
  getPrice,
  getProductColors,
  getSizeFromSpecificVariant,
  getSizeFromVariant,
  getVariant,
} from '@scayle/storefront-nuxt'

export type VariantAvailability = {
  available: boolean
  type: 'immediate' | 'soon' | 'unavailable'
  text: string
  textArgs?: any
}

export enum ProductCategories {
  'glasses' = 'glasses',
  'contactLenses' = 'contactLenses',
  'sunglasses' = 'sunglasses',
  'careproducts' = 'careproducts',
  'merch' = 'merch',
  'readyMadeGlasses' = 'readyMadeGlasses',
  'readingAidGlasses' = 'readingAidGlasses',
}

export const getLowestPriceBetweenVariants = (product: Product) => {
  return product.variants && getLowestPrice(product)
}

export const getVariantWithLowestPrice = (
  variants?: Variant[],
): Variant | undefined => {
  if (!variants?.length) {
    return
  }
  return variants.reduce((m: Variant, x: Variant) =>
    m.price.withoutTax < x.price.withoutTax ? m : x,
  )
}

export const getColorCodeForId = (id: number): string | string[] => {
  const color = Object.values(ColorMap).find((it) => it.id === id)

  if (color) {
    return color.hex
  }

  console.log('No color found for', id)

  return ColorMap.WHITE.hex
}

export const getSalesRelativeAmountByCategory = (
  product: Product,
  category: 'sale' | 'campaign',
) => {
  const variantsLowestPrice = getLowestPriceBetweenVariants(product)
  return variantsLowestPrice
    ? getAppliedReductionsByCategory(variantsLowestPrice, category)
    : []
}

export function getVariantAvailability(
  variant: Variant,
  minimumQuantityForImmediateAvailability = MINIMUM_QUANTITY_IMMEDIATE_AVAILABILITY,
): VariantAvailability {
  const { quantity, isSellableWithoutStock } = variant.stock

  if (quantity > minimumQuantityForImmediateAvailability) {
    return {
      available: true,
      type: 'immediate',
      text: `availability.available`,
    }
  }

  if (quantity > 0) {
    return {
      available: true,
      type: 'soon',
      text: `availability.available_some`,
      textArgs: { quantity },
    }
  }

  if (isSellableWithoutStock) {
    return {
      available: true,
      type: 'soon',
      text: 'availability.available_asap',
    }
  }

  return {
    available: false,
    type: 'soon',
    text: 'availability.available_soon',
  }
}

export const getLowestPrice = (product: Product) => {
  if (product.priceRange?.min) {
    return product.priceRange?.min
  }

  if (product?.variants?.length) {
    return getLowestVariantPrice(product.variants)
  }

  throw new Error(
    `Missing required data to compute lowest price for product "${product.id}". Add priceRange to with params`,
  )
}

export const capitalize = (word: string) =>
  word.charAt(0).toLocaleUpperCase() + word.slice(1)

// Converts to Start Case while keeping - intact
function startCase(text: string) {
  return (
    text &&
    text
      .toLocaleLowerCase()
      .split(' ')
      .map((word) => {
        const parts = word.split('-')
        return parts.map((part) => capitalize(part)).join('-')
      })
      .join(' ')
  )
}

export const getFrameColor = (product: Product) => {
  const attributes = product?.attributes || {}
  const baseColor = getFirstAttributeLabel(attributes, 'baseColor')
  const searchColorEcom = getFirstAttributeLabel(attributes, 'searchColorEcom')

  const frameColor = getFirstAttributeLabel(attributes, 'frameColor')

  return frameColor || baseColor || searchColorEcom
}

const eyewearCategories = [
  ProductCategories.glasses,
  ProductCategories.sunglasses,
  ProductCategories.readyMadeGlasses,
  ProductCategories.readingAidGlasses,
]

export const getPdpPageTitle = (
  product: Product,
  i18n: NuxtApp['$i18n'],
): string => {
  const brand = getFirstAttributeLabel(product?.attributes, 'brand')
  const modelName = getFirstAttributeLabel(product?.attributes, 'modelName')
  const productName = getProductName(
    product,
    [brand, modelName].filter(Boolean).join(' '),
  )

  // custom logic for eyewear pdps based on this ticket: https://aboutyou.atlassian.net/browse/SCFIM-1502
  const category = getProductCategory(product)

  if (category && eyewearCategories.includes(category)) {
    const frameColor = getFrameColor(product)
    const lensBaseColor = getFirstAttributeLabel(
      product?.attributes,
      'lensBaseColor',
    )
    const colors = [frameColor, lensBaseColor].filter(Boolean).join(' / ')

    const basicTitle = colors
      ? i18n.t('pdp.seo.eyewear_basic_title', {
          productName,
          colors,
        })
      : productName

    if (basicTitle.length > 50) {
      return i18n.t('pdp.seo.title_eyewear_short', {
        productName: basicTitle,
        colors,
      })
    }

    return i18n.t('pdp.seo.title_eyewear_base', {
      productName: basicTitle,
      colors,
    })
  }

  // name length restrictions is based on this ticket https://aboutyou.atlassian.net/browse/SCFIM-504
  else if (productName.length <= 22) {
    return i18n.t('pdp.seo.title_long', {
      productName,
    })
  } else if (productName.length <= 29) {
    return i18n.t('pdp.seo.title_medium', {
      productName,
    })
  } else {
    return i18n.t('pdp.seo.title_short', {
      productName,
    })
  }
}

export const getPdpPageDescription = (
  product: Product,
  i18n: NuxtApp['$i18n'],
): string => {
  const frameColor = getFrameColor(product)
  const lensBaseColor = getFirstAttributeLabel(
    product?.attributes,
    'lensBaseColor',
  )
  const colors = [frameColor, lensBaseColor].filter(Boolean).join(' / ')
  const brand = getFirstAttributeLabel(product?.attributes, 'brand')
  const modelName = getFirstAttributeLabel(product?.attributes, 'modelName')
  const productName = getProductName(
    product,
    [brand, modelName].filter(Boolean).join(' '),
  )

  const targetGroup = startCase(
    getFirstAttributeValue(product?.attributes, 'targetGroup')?.label ?? '',
  )

  const isUnisex = targetGroup === 'Unisex'
  let translationKey = ''

  // custom logic for eyewear pdps based on these tickets:
  // https://aboutyou.atlassian.net/browse/SCFIM-733
  // https://aboutyou.atlassian.net/browse/SCFIM-1502
  const category = getProductCategory(product)

  if (category && eyewearCategories.includes(category)) {
    if (colors?.length > 0 && isUnisex) {
      translationKey = 'pdp.seo.description_eyewear_with_color_unisex'
    } else if (colors?.length > 0 && !isUnisex) {
      translationKey = 'pdp.seo.description_eyewear_with_color'
    } else if (!colors.length && isUnisex) {
      translationKey = 'pdp.seo.description_eyewear_unisex'
    } else if (!colors.length && !isUnisex) {
      translationKey = 'pdp.seo.description_eyewear'
    }
  } else if (colors?.length > 0) {
    translationKey = 'pdp.seo.description_with_color'
  } else {
    translationKey = 'pdp.seo.description'
  }

  return i18n.t(translationKey, {
    productName,
    colors,
    targetGroup,
  })
}

export const getProductName = (product: Product, defaultValue: string = '') =>
  getFirstAttributeValue(unref(product)?.attributes, 'name')?.label ??
  defaultValue

export const getProductCategory = (product: Product) => {
  if (isGlasses(product)) {
    return ProductCategories.sunglasses
  }
  if (isCorrectionalGlasses(product)) {
    return ProductCategories.glasses
  }
  if (isCareProducts(product)) {
    return ProductCategories.careproducts
  }
  if (isContactLens(product)) {
    return ProductCategories.contactLenses
  }
  if (isMerch(product)) {
    return ProductCategories.merch
  }
  if (isReadyMadeGlasses(product)) {
    return ProductCategories.readyMadeGlasses
  }
  if (isReadingAidGlasses(product)) {
    return ProductCategories.readingAidGlasses
  }
}

export const isProductValid = (
  product: Product | Ref<Product | null> | undefined,
) => {
  const lifecycleStatus = getProductLifecycle(product)
  const p = unref(product)
  return (
    p &&
    (p?.priceRange?.min || p?.variants?.length) &&
    [
      PRODUCT_LIFECYCLE_STATUS_PUBLISHED,
      PRODUCT_LIFECYCLE_STATUS_TEMPORARY_NO_SELLABLE,
    ].includes(lifecycleStatus)
  )
}

export const isVariantAvailable = (
  product: Product | Ref<Product | null> | undefined,
  variant: Variant | Ref<Variant | null> | undefined,
) => {
  const v = unref(variant)

  const stock = v?.stock?.quantity ?? 0
  const isSellableWithoutStock = v?.stock?.isSellableWithoutStock

  return (
    [PRODUCT_LIFECYCLE_STATUS_PUBLISHED].includes(
      getProductLifecycle(product),
    ) &&
    (stock > 0 || isSellableWithoutStock)
  )
}

export const isProductAvailable = (
  product: Product | Ref<Product | null> | undefined,
) => {
  const p = unref(product)
  if (!p) {
    return undefined
  }
  // no variants on search would show sold out on every item
  const isSomeVariantAvailable = p.variants
    ? p.variants.some((v) => isVariantAvailable(p, v))
    : true

  return (
    [
      PRODUCT_LIFECYCLE_STATUS_PUBLISHED,
      PRODUCT_LIFECYCLE_STATUS_TEMPORARY_NO_SELLABLE,
    ].includes(getProductLifecycle(product)) &&
    Boolean(
      isProductValid(product) &&
        !p?.isSoldOut &&
        p?.isActive &&
        isSomeVariantAvailable,
    )
  )
}

export const getProductLifecycle = (
  product: Product | Ref<Product | null | undefined> | undefined,
): ProductLifecycleStatus => {
  const p = unref(product)
  if (!p) {
    return PRODUCT_LIFECYCLE_STATUS_OFFLINE
  }

  const attribute = getFirstAttributeValue(p.attributes, 'salesStatus')

  for (const status of PRODUCT_LIFECYCLE_STATUSES) {
    if (
      attribute?.value?.includes(status) ||
      attribute?.label.toLowerCase() === status
    ) {
      return status
    }
  }

  // due to backwards comparibility we assume 'published' if no status is found
  return PRODUCT_LIFECYCLE_STATUS_PUBLISHED
}

export const getCheapestVariant = (product: Product): Variant | undefined => {
  const variants =
    product.variants
      ?.filter(
        (variant) =>
          variant.stock.quantity > 0 || variant.stock.isSellableWithoutStock,
      )
      ?.sort((a, b) => {
        return a.price.withTax - b.price.withTax
      }) ?? []

  return variants[0]
}

// export const getVolumeDiscountId = (product: Product) => {
//   const id = getFirstAttributeValue(
//     product.attributes,
//     'volumeDiscountProduct',
//   )?.id
//   return id ? id.toString() : undefined
// }

const removeNonImages = (images: Product['images'] | undefined) =>
  images?.filter((image) => {
    return /\.(jpe?g|png|gif|bmp|avif|webp)($|\?)/i.test(image.hash || '')
  }) || []

export const getPreviewImage = (product: Product) => {
  const images = removeNonImages(product.images)
  return getImageForVariant(
    images,
    getCheapestVariant(product)?.referenceKey ?? '',
    getImageFromList(images, ProductImageType.BUST, 'front') || undefined,
  )
}

export const getFirstProductImage = (product: Product) =>
  removeNonImages(product?.images)?.[0] ?? {
    hash: 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
  }

export const isDummyProduct = (product: { masterKey?: string }) =>
  product?.masterKey === 'DUMMY'

const getValueWithoutReduction = (price: Price): number => {
  const totalReduction =
    price.appliedReductions?.reduce((acc, discount) => {
      return acc + discount.amount.absoluteWithTax
    }, 0) || 0
  return price.withTax + totalReduction
}

export const getProductConfigMainItem = (
  product: Product,
  variant: Variant,
  displayData?: BasketItemDisplayData,
): ProductConfigurationMainItem => {
  const price = getPrice(variant)
  const valueWithoutReduction = getValueWithoutReduction(price)

  return {
    id: variant.id,
    name: getProductName(product),
    description:
      displayData?.[DisplayDataAttributes.sizeAttribute]?.value || '',
    editable: true,
    value: price.withTax,
    valueWithoutReduction,
  }
}

export const getProductConfigRxMainItem = (
  product: Product,
  variant: Variant,
  displayData?: BasketItemDisplayData,
): ProductConfigurationRxMainItem => {
  const price = getPrice(variant)
  const valueWithoutReduction = getValueWithoutReduction(price)

  return {
    id: variant.id,
    name: getFirstAttributeLabel(product.attributes, 'name'),
    masterKey: product.masterKey as RxConfMasterKeys,
    referenceKey: variant.referenceKey,
    description:
      displayData?.[DisplayDataAttributes.sizeAttribute]?.value || '',
    editable: false,
    value: price.withTax,
    valueWithoutReduction,
  }
}

export const getProductConfigItem = (
  name: string,
  value: string | number,
  editable = false,
  id = 0,
): ProductConfigurationItem => ({
  id,
  name,
  value,
  editable,
})

export const getProductConfigRxItem = (
  product: Product,
  variant: Variant,
  price?: Price,
): ProductConfigurationRxItem => ({
  id: variant.id,
  name: getFirstAttributeLabel(product.attributes, 'name'),
  value: getPrice(variant).withTax,
  editable: Boolean(product.masterKey !== 'rx_services'),
  masterKey: product.masterKey as RxConfMasterKeys,
  referenceKey: variant.referenceKey,
  product,
  variant,
  price,
})

export const getProductConfigRxItems = (
  framePrice:
    | ComputedRef<Partial<Price> | undefined>
    | Partial<Price>
    | undefined,
  rxConfigItems: ProductConfigurationRxItem[],
  i18n: NuxtApp['$i18n'],
) => {
  const itemsOrder = [
    RxConfMasterKeys.lensTypes,
    RxConfMasterKeys.lensQuality,
    RxConfMasterKeys.tintingTypes,
    RxConfMasterKeys.freeService,
  ]
  const rxItems = rxConfigItems
    .filter(
      (item) =>
        !(item.masterKey === RxConfMasterKeys.lensTypes && item.value === 0),
    )
    .map((item) => {
      if (item.masterKey === RxConfMasterKeys.freeService) {
        item.editable = false
      }
      if (item.masterKey === RxConfMasterKeys.lensQuality) {
        const product = unref(item.product)
        const lensName = getLensName(product)
        if (lensName) {
          item.name = lensName
        }
      }
      return item
    })
    .sort((a, b) =>
      a.masterKey && b.masterKey
        ? itemsOrder.indexOf(a.masterKey) - itemsOrder.indexOf(b.masterKey)
        : 0,
    )

  const frameItem = getProductConfigItem(
    i18n.t('rxConfigurator.sumbar.frame'),
    unref(framePrice)?.withTax ?? 0,
    false,
    RX_CONFIGURATION_ITEM_FRAME_ID,
  )
  rxItems.unshift(frameItem)
  return rxItems
}

const getProductAttributeConfigItems = (
  product: Product,
  variant: Variant,
  category: Exclude<ProductCategories, 'glasses'>,
) => {
  const result: ProductConfigurationItem[] = []
  const productCategoryAttributesMap: Record<
    Exclude<ProductCategories, 'glasses'>,
    string[]
  > = {
    [ProductCategories.careproducts]: [
      'eyeCareProductType',
      'packingTypeDescription',
    ],
    [ProductCategories.contactLenses]: ['wearingTime', 'numberOfLenses'],
    [ProductCategories.readyMadeGlasses]: [],
    [ProductCategories.merch]: [],
    [ProductCategories.sunglasses]: ['lensBaseColor'],
    [ProductCategories.readingAidGlasses]: ['diopters'],
  }
  const attributeNames = productCategoryAttributesMap[category]
  for (const attributeName of attributeNames) {
    const attribute =
      product.attributes?.[attributeName] ??
      variant.attributes?.[attributeName] ??
      product.variants?.find((v) => v.id === variant.id)?.attributes?.[
        attributeName
      ]
    const attributeLabel = attribute?.label
    const attributeValue = Array.isArray(attribute?.values)
      ? attribute?.values[0].label
      : attribute?.values.label
    if (!attributeLabel || !attributeValue) {
      continue
    }
    result.push(getProductConfigItem(attributeLabel, attributeValue))
  }
  return result
}

const getCostFreeAdditionConfigItems = (
  category: Exclude<ProductCategories, 'glasses'>,
  i18n: NuxtApp['$i18n'],
) => {
  const costFreeAdditionsMap: Record<
    Exclude<ProductCategories, 'glasses'>,
    string[]
  > = {
    [ProductCategories.sunglasses]: [i18n.t('fitting_glasses_case_and_cloth')],
    [ProductCategories.careproducts]: [],
    [ProductCategories.contactLenses]: [],
    [ProductCategories.readyMadeGlasses]: [],
    [ProductCategories.merch]: [],
    [ProductCategories.readingAidGlasses]: [
      i18n.t('fitting_glasses_case_and_cloth'),
    ],
  }
  const costFreeAdditions = costFreeAdditionsMap[category]
  return costFreeAdditions.map((costFreeAddition) =>
    getProductConfigItem(costFreeAddition, 0),
  )
}

export const getProductConfigItems = (
  product: Product,
  variant: Variant,
  category: Exclude<ProductCategories, 'glasses'>,
  i18n: NuxtApp['$i18n'],
  displayData?: Partial<
    Record<RxBasketItemDisplayDataKey, BaskteItemDisplayDataItem>
  >,
) => {
  const eyeData = Object.values(displayData ?? {}).find(
    (entry) => entry?.key === 'eyeType',
  )
  const displayDataConfigItems = eyeData
    ? [getProductConfigItem(eyeData.label, eyeData.value)]
    : []

  const productAttributeConfigItems = getProductAttributeConfigItems(
    product,
    variant,
    category,
  )
  const costFreeAdditionConfigItems = getCostFreeAdditionConfigItems(
    category,
    i18n,
  )

  return displayDataConfigItems
    .concat(productAttributeConfigItems)
    .concat(costFreeAdditionConfigItems)
}

export const isVirtualTryOnReady = (product: Product | Ref<Product | null>) => {
  const virtualTryOnReady =
    product &&
    getFirstAttributeLabel(unref(product)?.attributes, 'virtualTryOnReady')

  return virtualTryOnReady?.toLowerCase() === 'true'
}

export const getVirtualTryOnReference = (
  product: Product | Ref<Product | null>,
) => {
  if (isVirtualTryOnReady(product)) {
    return getAdvancedAttribute({
      product: unref(product),
      property: 'virtualTryOnReference',
    })?.replace(/\n/g, '')
  }
}

export const getRecommendationTitle = (
  recommendationAttribute: ProductRecommendationAttribute,
  i18n: NuxtApp['$i18n'],
) =>
  i18n.t(
    PRODUCT_RECOMMENDATION_ATTRIBUTE_TITLE_TRANSLATIONS[
      recommendationAttribute
    ],
  )

export const getRecommendationAttribute = (product: Product | undefined) => {
  if (!product) {
    return undefined
  }
  if (isContactLens(product) || isCareProducts(product)) {
    return PRODUCT_RECOMMENDATION_ATTRIBUTE_REF_RELATED
  }
  return PRODUCT_RECOMMENDATION_ATTRIBUTE_REF_UPSELL
}

export const getRecommendationLinkFieldName = (
  product: Product | undefined,
): RecommendationLinkFieldNames | undefined => {
  if (!product) {
    return
  }
  if (isReadyMadeGlasses(product)) {
    return 'ready_made_glasses_links'
  } else if (isCorrectionalGlasses(product)) {
    return 'rx_glasses_links'
  } else if (isGlasses(product)) {
    return 'sunglasses_links'
  } else if (isContactLens(product)) {
    return 'contact_lenses_links'
  } else if (isCareProducts(product)) {
    return 'care_products_links'
  } else if (isMerch(product)) {
    return 'merchandise_links'
  }
}

export const getProductSiblingCount = (
  product: Product,
  currentShop: PublicShopConfig,
) => {
  if (product.siblings && Array.isArray(product.siblings)) {
    return product.siblings?.length + 1
  }
  return getAvailablePackageSize(product, currentShop)
}

const toNumber = (value: string | number | null | undefined) => {
  if (!value) {
    return 0
  }
  if (typeof value === 'string') {
    return parseFloat(value) || 0
  }

  return value
}

export const getAvailablePackageSize = (
  product: Product | Ref<Product> | undefined,
  currentShop: PublicShopConfig,
) => {
  const p = unref(product)

  // product is not there
  if (!p || !p?.id) {
    return 0
  }

  const attributeValues = p.advancedAttributes?.availablePackageSizes?.values

  // Fallback if attribute is not available we assume that the product is the only available size
  if (!Array.isArray(attributeValues) || attributeValues.length === 0) {
    return 1
  }

  const country = getIsoCountry(currentShop.locale).toLowerCase()

  const [result] = attributeValues
    .flatMap((value) =>
      value.fieldSet.map((item) =>
        item.flatMap((inner) => Object.values(inner)),
      ),
    )
    .filter(([locale]) => `${locale}`.toLowerCase() === country)
    .map(([, value]) => toNumber(value))

  return result || 1
}

export const handleRxPriceOffsetFilters = (
  cheapestLensQualityPrice: number | undefined,
  where: FetchProductsByCategoryParams['where'] | undefined,
) => {
  if (cheapestLensQualityPrice && where) {
    if (where?.minPrice) {
      where.minPrice = Math.max(0, where?.minPrice - cheapestLensQualityPrice)
    }
    if (where?.maxPrice) {
      where.maxPrice = Math.max(0, where?.maxPrice - cheapestLensQualityPrice)
    }
  }
  return where
}

export const getProductAttributeValue = (
  product: Product | undefined | Ref<Product | undefined>,
  variant: Variant | undefined | Ref<Variant | undefined>,
  key: string,
) => {
  const p = unref(product)
  const v = unref(variant)

  let res
  if (v) {
    res =
      getAttributeValue(v?.attributes, key) ||
      getAdvancedAttribute({
        product: v,
        property: key,
      }) ||
      undefined
  }

  if (res) {
    return res
  }

  return (
    getAttributeValue(p?.attributes, key) ||
    getAdvancedAttribute({
      product: p,
      property: key,
    }) ||
    undefined
  )
}

// HELPER FOR PDP
export const getProductAttribute = (
  product: Product | undefined | Ref<Product | undefined>,
  variant?: Variant | undefined | Ref<Variant | undefined>,
) => {
  const p = unref(product)
  const v = unref(variant)

  return (key: string, full: boolean = false) => {
    if (full) {
      if (p?.attributes && p?.attributes[key]) {
        return p?.attributes[key]
      }

      if (p?.advancedAttributes && p?.advancedAttributes[key]?.values) {
        return p?.advancedAttributes[key]
      }
    }

    return getProductAttributeValue(p, v, key)
  }
}

export const getVariantDiopters = (variant: Variant) =>
  getFirstAttributeValue(variant.attributes, 'diopters')

export const everyVariantHasDiopters = (variants: Variant[] | undefined) =>
  (variants ?? []).every((variant) => Boolean(getVariantDiopters(variant)))
