import { ParseResultType, parseDomain } from 'parse-domain'

export const getSessionCookieDomain = (domain: string) => {
  if (!domain) {
    return ''
  }
  const url = new URL(domain.includes('://') ? domain : `https://${domain}`)
  const parseResult = parseDomain(url.hostname)

  const parts =
    parseResult.type === ParseResultType.Listed
      ? [parseResult.domain, ...parseResult.topLevelDomains]
      : url.hostname.split('.').slice(-2)

  return `.${parts.join('.')}`
}
