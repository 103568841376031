<template>
  <div
    class="anchor-scrolling-none flex min-h-screen flex-col text-primary antialiased"
  >
    <AppHeader
      v-bind="{
        fetching: fetchingNavigationTree,
        navigationTree: navigationTree || [],
      }"
    />
    <LazyToastContainer v-if="$alert.alerts.value.length" />
    <LazyMobileNavigation
      v-if="isLessThan('header')"
      :fetching="fetchingNavigationTree"
      :navigation-tree="navigationTree || []"
    />
    <main class="min-h-[80vh]">
      <ErrorLayout :error="error" @clear-error="resetErrorState" />
    </main>
    <NuxtLazyHydrate when-visible>
      <AppFooter v-once />
    </NuxtLazyHydrate>

    <ClientOnly>
      <LanguageSwitcherOverlay />
    </ClientOnly>

    <div id="ropo-overlay"></div>
    <div id="product-detail-size-portal" data-testid="sizes-overlay"></div>
    <div id="rx-find-portal"></div>
    <div id="rx-sight-prescription-portal"></div>
    <div id="rx-pd-tool-portal"></div>
    <div id="rx-lens-quality-portal"></div>
    <div id="rx-extras-portal"></div>
    <div id="newsletter-overlay"></div>
    <div id="language-overlay"></div>
    <div id="info-overlay" data-testid="info-overlay"></div>
  </div>
</template>

<script setup lang="ts">
import { HttpStatusCode } from '@scayle/storefront-nuxt'

const error = useError()
const localePath = useLocalePath()
const $i18n = useI18n()
const router = useRouter()

const { isLessThan } = useViewport()

const { data: navigationTree, pending: fetchingNavigationTree } =
  useFimNavigationTree()

const is404 = computed(
  () => error?.value?.statusCode === HttpStatusCode.NOT_FOUND,
)

const title = computed(() => {
  return is404.value
    ? $i18n.t('error.404_title')
    : $i18n.t('error.generic_title')
})

useHead({ title: title.value })
defineOptions({ name: 'GlobalError' })

const resetErrorState = async () => {
  const redirect = localePath(routeList.home).toString()
  await clearError({ redirect })
}

router.afterEach(async () => {
  if (error.value) {
    await clearError()
  }
})
</script>
