/* eslint camelcase: "off" */
import type {
  Product,
  Variant,
  BasketItem,
  WishlistItem,
  ShopUser,
  Price as BapiPrice,
} from '@scayle/storefront-nuxt'
import type { VariantDetail } from '@scayle/storefront-api'
import type { NuxtApp } from 'nuxt/app'
import type { ConfiguratorState } from '../useRxConfiguratorState'
import type { IRxStepNameValue } from '~/components/rx/RxConfigurator/RxConfigurator'

const ECOMMERCE_TRACKING_EVENTS = [
  'FielmannBasic_EC_AddToCart',
  'FielmannBasic_EC_AddToWishlist',
  'FielmannBasic_EC_ItemView',
  'FielmannBasic_EC_ItemViewList',
  'FielmannBasic_EC_Purchase',
  'FielmannBasic_EC_RemoveFromCart',
  'FielmannBasic_EC_RemoveFromWishlist',
  'FielmannBasic_EC_SelectItem',
  'FielmannBasic_EC_PromotionClick',
  'FielmannBasic_EC_PromotionView',
] as const
export type EcommerceTrackingEvent = (typeof ECOMMERCE_TRACKING_EVENTS)[number]

export type BasicTrackingEvent =
  | 'shop_init'
  | 'shop_change'
  | 'customer_data'
  | 'content_view'
  | 'FielmannBasic_MenuItemClick'
  | 'FielmannBasic_MenuIconClick'
  | 'FielmannBasic_FooterItemClick'
  | 'FielmannBasic_FooterIconClick'
  | 'FielmannBasic_VirtualPageview'
  | 'FielmannBasic_TextLinkClick'

export type CheckoutTrackingEvent =
  | 'FielmannBasic_EC_BeginCheckout'
  | 'finish_checkout'

const ADDITIONAL_TRACKING_EVENTS = [
  'FielmannBasic_EC_CartView',
  'FielmannBasic_404Error',
  'wishlist',
  'search',
  'filter',
  'login',
  'logout',
  'sign_up',
  'newsletter',
] as const
export declare type AdditionalTrackingEvent =
  (typeof ADDITIONAL_TRACKING_EVENTS)[number]

const PROMOTION_TRACKING_EVENTS = [
  'FielmannBasic_EC_PromotionView',
  'FielmannBasic_EC_PromotionClick',
] as const
export type PromotionTrackingEvent = (typeof PROMOTION_TRACKING_EVENTS)[number]

export declare type PageType =
  | 'browse'
  | 'navigation'
  | 'discovery'
  | 'category'
  | 'category_overview'
  | 'adp'
  | 'FielmannBasic_EC_CartView'
  | 'wishlist'
  | 'brand'
  | 'brand_overview'
  | 'story'
  | 'story_overview'
  | 'outfit'
  | 'outfit_overview'
  | 'account_area'
  | 'checkout'
  | 'osp'
  | 'help'
  | 'off_canvas'
  | 'social_media'
  | 'error'
  | 'other'
  | 'search_results_flyout'
  | 'search_results_page'
  | 'searchresults'

export type TrackingEvent =
  | EcommerceTrackingEvent
  | BasicTrackingEvent
  | AdditionalTrackingEvent
  | CheckoutTrackingEvent

export type AuthTrackingEvent =
  | 'login'
  | 'logout'
  | 'sign_up'
  | 'forgot_password'
  | 'reset_password'
  | 'guest_login'

export interface TrackingCategory {
  name: string
  id: string
}
export interface ListItem {
  name: string
  id: string
  index?: number | undefined
}

export interface SearchResultData {
  search: {
    search_term: string
    search_results_products: any
    search_results_categories: any
    search_results_pages: any
    search_count: number
    search_destination?: string
    search_location: PageType
  }
}

export interface ProductListData {
  product: Product
  variant?: Variant
  category?: TrackingCategory
  list?: ListItem
  quantity?: number
}

export interface ProductViewData extends ProductListData {
  destination: string
  destinationUrl: string
  source: string
  position?: number
  search?: SearchResultData
}
export interface MultipleActionData {
  currencyCode?: string
  items: ProductListData[]
  search?: SearchResultData
}
export interface ProductActionData {
  product: Product
  variant?: Variant
  category?: TrackingCategory
  quantity?: number
  list?: ListItem
}
export interface ProductInfo {
  item_id: string
  item_name: string
  price_gross: number
  price: number
  sale_discount: number
  campaign_discount: number
  original_price: number
  item_brand: string
  item_brand_id: string
}
export interface AdditionalInfo {
  item_category: string
  item_category_id: string
  item_variant: string
  quantity?: number | undefined
  item_list_name?: string
  item_list_id?: string
  index?: number
}
export interface ViewInfo extends AdditionalInfo {
  source: string
  destination: string
  destination_url: string
  sold_out?: boolean
  bi_price: string
}
export interface ShopData {
  shop_id: string | number
  shop_gender: string
  locale: string
  shop_currency: string
}
export interface FilterData {
  filter_name: string
  filter_values: string
}
export interface BasketData {
  items: BasketItem[]
  total_campaign_reduction_with_tax: number
  total_sale_reduction_with_tax: number
  total_with_tax: number
  total_without_tax: number
}
export interface WishlistData {
  items: WishlistItem[]
  total_campaign_reduction_with_tax: number
  total_sale_reduction_with_tax: number
  total_with_tax: number
  total_without_tax: number
}
export interface ShortPromotionItem {
  promotion_id?: string
  promotion_name?: string
}

export interface PromotionItem {
  promotion_id: string
  promotion_name: string
  creative_name: string
  creative_slot: string
  index: number
}
export interface PromotionData {
  items: PromotionItem[]
}
export interface ShopInitData extends ShopData {
  landing_page: string
  shop_version: string
  parameter: string
  referrer: string
}
export interface PageViewData {
  content_name: string
  page_type: PageType
  title: string
  page_type_id: string
  click_origin?: string
  error?: {
    referringURL?: string
    errorURL: string
  }
  meta?: {
    applicationId: string
  }
}
export type SearchAction =
  | 'history_term'
  | 'suggested_product'
  | 'suggested_brand'
  | 'suggested_category'
  | 'search_button'
  | 'search_hotkey'
export interface SearchData {
  search_term: string
  search_term_completed: string
  search_action: SearchAction
  search_destination: string
}
export interface CustomerLogoutData {
  customer_id?: number
  eh: string
}
export interface CustomerData {
  customer_id?: number
  customer_type?: 'guest' | 'new' | 'existing'
  login?: boolean
  method: 'none' | 'facebook' | 'apple' | 'password'
  bi_vp?: boolean | undefined
  bi_sc?: number | undefined
  eh: string
  status?: 'successful' | 'error'
}

export interface EcommerceItem {
  item_id: string
  item_sku?: string
  item_name: string
  affiliation?: string
  coupon?: string
  discount?: number
  item_brand: string
  item_brand_id: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_list_id?: string
  item_list_name?: string
  item_variant: string
  price: number
  quantity: number
  index: number
  price_gross?: number
  sale_discount?: number
  campaign_discount?: number
  original_price?: number
  item_category_id?: string
}

export interface EcommerceData {
  ecommerce: {
    transaction_id: string
    orderTimestamp?: string
    customer_id: string
    payment: string
    affiliation?: string
    value: number
    value_gross: number
    tax: number
    shipping: number
    currency: string
    coupon?: string
    items: EcommerceItem[]
  }
  user: {
    email: string
    loginStatus?: string
    loginType?: string
  }
}

export type TrackingPayload =
  | ProductActionData
  | MultipleActionData
  | ProductViewData
  | ProductListData
  | ShopData
  | ShopInitData
  | PageViewData
  | CustomerData
  | SearchData
  | FilterData
  | BasketData
  | WishlistData
  | PromotionData
  | CustomerLogoutData
  | EcommerceData

export interface AdditionalItem extends AdditionalInfo {
  item_id: string
  item_name: string
  price: number
  sale_discount: number
  campaign_discount: number
  original_price: number
  item_brand: string
  item_brand_id: string
  quantity: number
}
export interface MapToTrackingPayloadOptions {
  isBotTraffic?: boolean
  variant?: Variant | VariantDetail | null
  user?: ShopUser
  context?: any
  isLoggedIn?: boolean
  overridePageType?: PageType
  overridePrice?:
    | BapiPrice
    | ((
        product: Product,
        variant?: Variant | VariantDetail | null,
      ) => BapiPrice | undefined)
  $currentShop?: NuxtApp['$currentShop']
  $i18n?: NuxtApp['$i18n']
  pageState?: any
  route?: any
}

export interface TrackFunction {
  (
    event: TrackingEvent,
    payload: TrackingPayload,
    options?: MapToTrackingPayloadOptions,
  ): any
}

export interface FimMetaTrackingData {
  applicationVersion: string
  applicationId: string
  pageType: string
  country: string
  language: string
  virtualPagePath: string
  virtualPageTitle: string
  ep_bot_traffic: boolean
}

export interface FimUserTrackingData {
  loginStatus: string
  loginType: string
  email: string
}

export interface FimProductTrackingData {
  name: string
  id: string
  sku: string
  brand: string
  category: string

  category_2: string

  category_3: string

  category_4: string

  category_5: string
  price: string
  variant: string
  currency: string
  availability: {
    deliveryDays: string
    deliveryText: string
  }
}

export interface VtoTrackingData {
  event: string
  user: FimUserTrackingData
  meta: FimMetaTrackingData
  element?: {
    label: string
    id: string
  }
  productDetails?: FimProductTrackingData
}

export interface FimButtonTrackingPayload {
  element: HTMLButtonElement | HTMLAnchorElement | { id: string; label: string }
}

export interface FimLinkTrackingPayload {
  element: HTMLAnchorElement
}

export interface FimVtoTrackingPayload extends FimButtonTrackingPayload {
  productDetails: Product
}

export interface FimCTATrackingPayload extends FimButtonTrackingPayload {
  productDetails?: Product | null
}

export interface FimLeadFormSubmitTrackingPayload {
  user: {
    email: string
  }
  form: {
    identifier: string
  }
}

export interface NotificationTrackingPayload {
  error: {
    notification: string
  }
  meta?: {
    applicationId: string
  }
}
export interface FimRxConfigurationMapped {
  configuration: {
    prescriptionType?: 'Prescription Glasses' | 'Non Prescription Glasses'
    prescriptionValues?: 'Given' | 'Not Given'
    glassType?:
      | string
      | 'Basic'
      | 'Basic Plus'
      | 'Smart'
      | 'Select'
      | 'Excellence'
    extras?:
      | 'Plain'
      | 'Blue Blocking'
      | 'Tinted | Brown'
      | 'Tinted | Grey'
      | 'Tinted | Green'
  }
}

export interface FimRxConfiguratorPayload {
  productDetails: Product
  configurator: {
    id: 'RX Configurator' | 'Prescription Configuration'
    step: number
    stepName: IRxStepNameValue | IPrescriptionProp
  }
  configuration?: ConfiguratorState
}

export type PdTrackingPayload = {
  [key: string]: any
}

export interface FimSearchPayload extends FimButtonTrackingPayload {
  search: SearchResultData
}

export type FimTrackingPayload =
  | FimVtoTrackingPayload
  | FimCTATrackingPayload
  | FimLeadFormSubmitTrackingPayload
  | NotificationTrackingPayload
  | FimButtonTrackingPayload
  | FimLinkTrackingPayload
  | FimRxConfiguratorPayload
  | PdTrackingPayload
  | FimSearchPayload

export type VTOTrackingEvent = 'FielmannBasic_VirtualTryOn'
export type LeadFormSubmitEvent = 'FielmannBasic_LeadFormSubmit'
export type CTATrackingEvent = 'FielmannBasic_CTAClick'
export type NotificationTrackingEvent = 'FielmannBasic_ApplicationNotification'
export type ButtonClickEvent = 'FielmannBasic_ButtonClick'
export type RxConfiguratorEvent = 'FielmannBasic_Configurator'
export type PdScanEvent = 'PDScan_VirtualPageview'
export type SearchResultViewEvent = 'FielmannBasic_SearchResultView'
export type SearchResultClickEvent = 'FielmannBasic_SearchResultClick'

export type FimTrackingEvent =
  | TrackingEvent
  | VTOTrackingEvent
  | LeadFormSubmitEvent
  | CTATrackingEvent
  | NotificationTrackingEvent
  | ButtonClickEvent
  | RxConfiguratorEvent
  | PdScanEvent
  | SearchResultViewEvent
  | SearchResultClickEvent

export function isAdditionalTrackingEvent(
  event: TrackingEvent,
): event is AdditionalTrackingEvent {
  return ADDITIONAL_TRACKING_EVENTS.includes(event as AdditionalTrackingEvent)
}

export function isEcommerceTrackingEvent(
  event: TrackingEvent,
): event is EcommerceTrackingEvent {
  return ECOMMERCE_TRACKING_EVENTS.includes(event as EcommerceTrackingEvent)
}

export function isPromotionTrackingEvent(
  event: TrackingEvent,
): event is PromotionTrackingEvent {
  return PROMOTION_TRACKING_EVENTS.includes(event as PromotionTrackingEvent)
}

export function isEcommerceTrackingEventWithPromotion(
  event: TrackingEvent,
): event is EcommerceTrackingEvent {
  return (
    ECOMMERCE_TRACKING_EVENTS.includes(event) &&
    !isPromotionTrackingEvent(event)
  )
}

export function isProductImpressionsData(
  data: TrackingPayload | MultipleActionData,
): data is MultipleActionData {
  return (data as MultipleActionData).items !== undefined
}

export type TrackContentViewEventParams = {
  eventName: TrackingEvent
  contentName: string
  title: string
  pageType: PageType
  pageTypeId: string
  clickOrigin: string
  element?: any
  error?: {
    referringURL?: string
    errorURL: string
  }
  meta?: {
    applicationId: string
  }
}

export type EasySecondPairProgressiveGlassesConversion = {
  label: string
  id: string
}
