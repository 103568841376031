const LANG_COOKIE_NAME = 'fimSelectedLanguage'

export const useLocale = () => {
  const { $i18n, $currentShop } = useNuxtApp()
  const { logger } = useLogging()
  const shopLanguages = computed(
    () =>
      $currentShop.languages?.map((lang) => ({
        ...lang,
        shortLabel: getIsoLanguage(lang.code),
        isActive: lang.code === $currentShop.locale,
      })) ?? [],
  )

  const isMultilanguageShop = computed(() => shopLanguages.value.length > 1)
  const browserLocale = $i18n.getBrowserLocale()
  const userBrowserLanguage = computed(() => {
    if (!browserLocale) {
      return
    }
    // want to compare by language only so we match German DE as well etc.
    return shopLanguages.value.find(
      (lang) => getIsoLanguage(lang.code) === getIsoLanguage(browserLocale),
    )?.code
  })

  const secondLevelDomain = getSessionCookieDomain($currentShop.domain)
  const langCookie = useCookie(LANG_COOKIE_NAME, {
    watch: 'shallow',
    domain: secondLevelDomain,
    path: '/',
    maxAge: 7 * 24 * 60 * 60, // one week? })
  })

  const setLanguageCookie = (lang: string) => {
    if (lang && lang !== langCookie.value) {
      langCookie.value = lang
    }
  }

  const getDomainForConfiguredLocale = () => {
    const domain = shopLanguages.value.find(
      (lang) => lang.code === configuredLocale.value,
    )?.domain
    if (domain) {
      return `https://${domain}`
    }
  }

  // force = true will switch the language even if it's the same as the current one
  // this makes sure we only show language overlay once until user makes a choice
  const switchLanguage = (newLang: string, force = false) => {
    if (!force && (!newLang || newLang === $currentShop.locale)) {
      return
    }
    setLanguageCookie(newLang)
  }

  const redirectToLocalePage = async (path: string, query: string) => {
    const domain = getDomainForConfiguredLocale()
    const fullPath = query ? `${path}?${query}` : path
    if (domain && fullPath) {
      try {
        const url = normalizeUrl(fullPath, domain)
        await navigateTo(url.toString(), { redirectCode: 302, external: true })
      } catch (error) {
        logger.error(error, {
          why: 'redirectToLocalePage',
          where: 'useLocale.ts',
          locale: configuredLocale.value,
        })
      }
    }
  }
  const configuredLocale = computed(
    () => langCookie.value || userBrowserLanguage.value || $currentShop.locale,
  )

  const hasNoLanguagePreference = computed(
    () => !langCookie.value && !userBrowserLanguage.value,
  )

  return {
    isMultilanguageShop,
    shopLanguages,
    switchLanguage,
    getDomainForConfiguredLocale,
    userBrowserLanguage,
    redirectToLocalePage,
    configuredLocale,
    hasNoLanguagePreference,
  }
}
