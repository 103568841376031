import type { NuxtApp } from 'nuxt/app'
import type { ValuesType } from 'utility-types'
import { type ObjectWithLocale, toLocaleDateTimeString } from './locale'
import type {
  IStore,
  ISpecialOpening,
  IOpeningHoursValue,
  IWeekdayKey,
  IStoreOpening,
} from '~/rpcMethods'

export const STORE_STATUS = {
  CLOSED_TODAY: 'CLOSED_TODAY',
  CLOSED: 'CLOSED',
  CLOSING_SOON: 'CLOSING_SOON',
  OPENS_TODAY: 'OPENS_TODAY',
  OPEN: 'OPEN',
} as const

export type STORE_STATUS = ValuesType<typeof STORE_STATUS>

// export enum STORE_STATUS {
//   'CLOSED_TODAY' = 'CLOSED_TODAY',
//   'CLOSED' = 'CLOSED',
//   'CLOSING_SOON' = 'CLOSING_SOON',
//   'OPENS_TODAY' = 'OPENS_TODAY',
//   'OPEN' = 'OPEN',
// }

export const STORE_SERVICE_TYPES = ['ALL', 'GL', 'CL', 'HA', 'AC', 'GL_AC'] // @deprecated GL_AC

/**
 * Parse datestring taking german date format DD.MM.YYYY into account
 * @param date
 * @param time
 * @returns Date object
 */
export const parseDate = (
  date: string | number,
  time: string = '23:59:59',
): Date => {
  let result = new Date(date)
  const [, day, month, year] = /^(\d+)\.(\d+)\.(\d+)$/.exec(`${date}`) || []
  if (day || month || year) {
    result = new Date(`${year}-${month}-${day}`)
  }

  const [hours, minutes, seconds] = (time?.split(':') ?? []).map((str) =>
    parseInt(str, 10),
  )

  if (hours) {
    result.setHours(hours)
  }
  if (minutes) {
    result.setMinutes(minutes)
  }
  if (seconds) {
    result.setSeconds(seconds)
  }

  return result
}

/**
 * Get date for weekday based on reference date
 * @param key
 * @param referenceDate
 * @returns
 */
export const getDateFromKey = (
  key: IWeekdayKey,
  referenceDate: string | number = Date.now(),
): Date => {
  const result = parseDate(referenceDate)
  const current = result.getDay()
  const day = parseInt(key[1], 10)

  result.setDate(result.getDate() - (current - day))
  return result
}

/**
 * Compare dates
 * @param date1
 * @param date2
 * @returns number of days in between. Negative values if date1 is after date2
 */
export const compareDateByDay = (
  date1: Date | string,
  date2: Date | string,
) => {
  const a = typeof date1 === 'string' ? parseDate(date1) : date1
  const b = typeof date2 === 'string' ? parseDate(date2) : date2

  a.setHours(0)
  a.setMinutes(0)
  a.setSeconds(0)
  b.setHours(0)
  b.setMinutes(0)
  b.setSeconds(0)

  return Math.round((b.getTime() - a.getTime()) / 1000 / 3600 / 24)
}

export const getStoreName = (store: IStore) => {
  return store.isCityPage
    ? store.city
    : store?.store_localizations[0]?.branch_title ?? ''
}

export const getStoreCommonName = (store: IStore) => {
  return store?.store_localizations[0]?.common_name ?? ''
}

export const getStoreHeadline = (
  store: IStore,
  i18n: NuxtApp['$i18n'],
  long: boolean = false,
) => {
  if (store?.store_localizations[0]?.headline) {
    return store?.store_localizations[0]?.headline
  }
  const { city: cityName, street, city_part: cityPart } = store
  const commonName = getStoreCommonName(store)
  const serviceOffering = store.appointment_type.includes('HA')
    ? i18n.t(
        'store_finder.details_page.service_offering.optician_hearing_acoustics',
      )
    : i18n.t(`store_finder.details_page.service_offering.optician`)

  if (long && cityPart) {
    return i18n.t('store_finder.details_page.title.full', {
      serviceOffering,
      cityName,
      cityPart,
      commonName: commonName || street,
    })
  } else if (long) {
    return i18n.t('store_finder.details_page.title.default', {
      serviceOffering,
      cityName,
      street: commonName || street,
    })
  } else if (cityPart) {
    return `${cityName} ${cityPart}, ${commonName || street}`
  }
  return `${cityName}, ${commonName || street}`
}

export const getStoreDescriptionHeadline = (store: IStore) => {
  return store?.store_localizations[0]?.description_headline ?? ''
}

export const getStoreDescriptionText = (store: IStore) => {
  return store?.store_localizations[0]?.description_text ?? ''
}

export const getStoreUrl = (
  store: IStore,
  localePath: NuxtApp['$fimLocalePath'],
  showCityPage = false,
) => {
  const isCityPage = showCityPage && store.isCityPage

  const routeName = isCityPage ? routeList.citypage.name : routeList.store.name
  const slugName = isCityPage
    ? store.city_page_url_slug?.replace('/niederlassungen/stadt/', '') ?? ''
    : store.url_slug
  const slugParts = slugName.split('/')

  if (slugParts.length === 2) {
    const params: any = isCityPage
      ? {
          county: slugParts[0],
          city: slugParts[1],
        }
      : {
          city: slugParts[0],
          id: slugParts[1],
        }

    return localePath({
      name: routeName,
      params,
    })
  }
  return localePath({ name: routeList.stores.name })
}

export const getStoreMetaTitle = (store: IStore, i18n: NuxtApp['$i18n']) => {
  if (store?.store_localizations[0]?.meta_title) {
    return store?.store_localizations[0]?.meta_title
  }

  const { city: cityName, street, city_part: cityPart } = store || {}

  const serviceOffering = store.appointment_type.includes('HA')
    ? i18n.t(
        'store_finder.details_page.service_offering.optician_hearing_acoustics',
      )
    : i18n.t(`store_finder.details_page.service_offering.optician`)

  if (cityPart) {
    return i18n.t('store_finder.details_page.seo_title_with_city_part', {
      serviceOffering,
      cityName,
      cityPart,
    })
  }
  return i18n.t('store_finder.details_page.seo_title', {
    serviceOffering,
    cityName,
    street,
  })
}

export const getStoreMetaDescription = (
  store: IStore,
  i18n: NuxtApp['$i18n'],
) => {
  if (store?.store_localizations[0]?.meta_description) {
    return store?.store_localizations[0]?.meta_description
  }

  const serviceOffering = store.appointment_type.includes('HA')
    ? i18n.t(
        'store_finder.details_page.service_offering.optician_hearing_acoustics',
      )
    : i18n.t(`store_finder.details_page.service_offering.optician`)

  const { city: cityName, city_part: cityPart, zip: zipCode } = store || {}

  return i18n.t('store_finder.details_page.seo_description', {
    serviceOffering,
    zipCode,
    cityName,
    cityPart,
  })
}

export const getStoreEmergencyText = (store: IStore) => {
  return store?.store_localizations[0]?.emergency_text ?? ''
}

export const getStoreImportantMsgHeadline = (store: IStore) => {
  return store?.store_localizations[0]?.important_message_headline ?? ''
}

export const getStoreImportantMsgText = (store: IStore) => {
  return store?.store_localizations[0]?.important_message_text ?? ''
}

export const getStoreAppointmentHeadline = (store: IStore) => {
  return store?.store_localizations[0]?.appointments_message_headline ?? ''
}

export const getStoreAppointmentText = (store: IStore) => {
  return store?.store_localizations[0]?.appointments_message_text ?? ''
}

export const formatRegularOpeningHours = (hours: IOpeningHoursValue[]) => {
  return hours.map((hour) => {
    return `${hour.open}-${hour.close}`
  })
}

/**
 * Expects a timestamp in format HH:MM:SS and returns HH:MM
 * API response should be aligned with regular opening hours to remove seconds
 * then this fn can be removed
 * */
const formatOpeningWithoutSeconds = (hours: string) => {
  const parts = hours.split(':')
  return parts.length === 3 ? parts.slice(0, 2).join(':') : hours
}

export const formatSpecialOpeningHours = (
  opening: ISpecialOpening,
  $currentShop: ObjectWithLocale,
  i18n: NuxtApp['$i18n'],
) => {
  const times =
    opening?.times &&
    opening.times.map((hours) => {
      return `${formatOpeningWithoutSeconds(
        hours.open,
      )}-${formatOpeningWithoutSeconds(hours.close)}`
    })
  return {
    dateString: toLocaleDateTimeString(parseDate(opening?.date), $currentShop, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
    date: parseDate(opening?.date),
    day: '',
    status: opening.closed
      ? [i18n.t('store_finder.details_page.content.closed')]
      : times,
    closed: opening.closed,
    day_opening_hours: times,
  }
}

export const getWeekDayKey = (date: Date) =>
  `0${date.getDay() || 7}0` as IWeekdayKey

export const getOpeningDetailsToday = (
  store: IStore,
  $currentShop: ObjectWithLocale,
  i18n: NuxtApp['$i18n'],
  today: Date = new Date(),
) => {
  const specialHours = store?.special_opening_hours
    ? (JSON.parse(store.special_opening_hours) as ISpecialOpening[])
    : []
  const todaySpecialHours = specialHours.find(
    (day) => compareDateByDay(parseDate(day.date), today) === 0,
  )
  if (todaySpecialHours) {
    return formatSpecialOpeningHours(todaySpecialHours, $currentShop, i18n)
  } else {
    return store
  }
}

const parseTimeConvertMs = (
  currentTime: number,
  timeString: string,
): number => {
  // handle 09:30 and 09.30
  const [hours, minutes] = timeString.split(/\.|:/).map(Number)
  const date = new Date(currentTime)
  date.setHours(hours, minutes, 0)
  return date.getTime()
}

const parseOpeningHours = (
  data: IOpeningHoursValue[],
  today: Date = new Date(),
) => {
  if (!Array.isArray(data)) {
    data = [data].filter(Boolean)
  }

  return data.map((openingHours) => {
    const open = parseTimeConvertMs(today.getTime(), openingHours.open)
    let close = parseTimeConvertMs(today.getTime(), openingHours.close)

    if (open >= close) {
      close += 12 * 60 * 60 * 1000 // add 12 hours in ms
    }
    return { open, close }
  })
}
export const getOpeningHoursToday = (
  store: IStore,
  today: Date = new Date(),
) => {
  const specialHours = store?.special_opening_hours
    ? (JSON.parse(store.special_opening_hours) as ISpecialOpening[])
    : []
  const todaySpecialHours = specialHours.find(
    (day) => compareDateByDay(parseDate(day.date), today) === 0,
  )

  // first check if there are special opening hours for today
  if (todaySpecialHours) {
    return todaySpecialHours.closed
      ? []
      : parseOpeningHours(todaySpecialHours.times, today)

    // then check structured opening hours
  } else if (store.structured_opening_hours) {
    const key = getWeekDayKey(today)
    const hours = store.structured_opening_hours.find(
      (data) => data.key === key,
    )
    return parseOpeningHours(hours?.value ?? [], today)
  }

  // fallback to unstructured store data
  if (
    store.closedToday ||
    !store.day_opening_hours ||
    !store.day_opening_hours.includes('-')
  ) {
    return []
  }

  const [open, close] = store.day_opening_hours.split('-')
  return parseOpeningHours([{ open, close }], today)
}

export const getOpeningStatus = (
  openingHoursToday: ReturnType<typeof getOpeningHoursToday>,
  currentTime: number,
) => {
  if (!openingHoursToday?.length) {
    return STORE_STATUS.CLOSED_TODAY
  }

  for (const times of openingHoursToday) {
    const open = times.open
    const close =
      times.close < times.open
        ? (times.close += 12 * 60 * 60 * 1000) // add 12 hours in ms
        : times.close

    if (currentTime < open) {
      return STORE_STATUS.OPENS_TODAY
    } else if (currentTime >= open && currentTime < close) {
      const timeUntilClose = Math.floor((close - currentTime) / 1000)
      if (timeUntilClose <= 3600) {
        // 60 minutes until close
        return STORE_STATUS.CLOSING_SOON
      } else {
        return STORE_STATUS.OPEN
      }
    }
  }

  return STORE_STATUS.CLOSED
}

export const hasAdditionalStoresInCity = (store: IStore) =>
  store.number_of_branches_in_city > 1

export const addCityPageStore = (stores: IStore[]): IStore[] => {
  const cityPagesAlreadyIncluded: string[] = []
  return stores.reduce((result, store) => {
    if (hasAdditionalStoresInCity(store)) {
      if (!cityPagesAlreadyIncluded.includes(store.city)) {
        const cityPageStore = { ...store }
        cityPageStore.isCityPage = true
        result.push(cityPageStore)
      }
      cityPagesAlreadyIncluded.push(store.city)
    }
    result.push(store)
    return result
  }, [] as IStore[])
}

const isDateBetween = (
  timestampToCheck: number,
  start: string,
  end: string,
): boolean =>
  timestampToCheck > Date.parse(start) && timestampToCheck < Date.parse(end)

export const getActiveStoreTeaser = (
  teasers: IStoreOpening['teasers'],
  currentTimestamp = Date.now(),
) => {
  return teasers.find((teaser) =>
    isDateBetween(currentTimestamp, teaser.start, teaser.end),
  )
}
