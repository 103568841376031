export const useRxProducts = () => {
  // const instance = useNuxtApp()
  const { data, pending, execute } = useAsyncRpc(
    'getRxProducts',
    {},
    { server: true, immediate: true, dedupe: 'defer' },
  )

  // return instance.runWithContext(() => {
  const rxLensTypes = computed(() => data.value?.rxLensTypes ?? [])
  const rxLensQualityIndex = computed(
    () => data.value?.rxLensQualityIndex ?? [],
  )
  const rxTintingTypes = computed(() => data.value?.rxTintingTypes ?? [])
  const rxFreeServiceProduct = computed(
    () => data.value?.rxFreeServiceProduct ?? [],
  )
  const rxCheapestLensQualityIndexPrice = computed(() => {
    const prices = rxLensQualityIndex.value
      .filter((p) => p.priceRange?.min?.withTax)
      .map((p) => p.priceRange?.min?.withTax as number)
    return prices.length ? prices.sort((a, b) => a - b)[0] : 0
  })

  return {
    rxLensTypes,
    rxLensQualityIndex,
    rxTintingTypes,
    rxFreeServiceProduct,
    rxCheapestLensQualityIndexPrice,
    fetching: pending,
    fetch: execute,
  }
  // })
}

// export const useSharedRxProducts = createSharedComposable(useRxProducts)
