import type { PublicShopConfig } from '@scayle/storefront-nuxt'
import type { TrackingEvent, TrackingPayload } from '../trackingTypes'

const useShopEvents = (
  track: (event: TrackingEvent, payload: TrackingPayload) => any,
  $currentShop: PublicShopConfig,
) => ({
  trackShopInit: () => {
    const currentShop = unref($currentShop)
    if (!currentShop) {
      return
    }

    return track('shop_init', {
      shop_currency: currentShop.currency,
      shop_id: currentShop.shopId,
      shop_gender: '',
      shop_version: '',
      locale: currentShop.locale,
      landing_page: '',
      parameter: import.meta.client ? window.document.location.search : '',
      referrer: import.meta.client ? window.document.referrer : '',
    })
  },
})

export default useShopEvents
