import {
  defineNuxtPlugin,
  useCookie,
  useRoute,
  useRouter,
  type NuxtApp,
} from 'nuxt/app'
import { getIsoCountry, getIsoLanguage } from '../../../utils/locale'
import usePageState from '../../../composables/usePageState'
import {
  deserializeOptanonConsent,
  isTrackingAllowed,
  TRACKING_GROUP,
  OPTANON_CLOSED_COOKIE_NAME,
  OPTANON_CONSENT_COOKIE_NAME,
} from './helper/optanonHelpers'

export default defineNuxtPlugin((nuxtApp) => {
  const { pageState } = usePageState()
  const $currentShop =
    nuxtApp.$currentShop as unknown as NuxtApp['$currentShop']

  if (!$currentShop) {
    return
  }

  // Want to send event either when user rejects cookies or
  // only once when user leaves this tab
  const listenersController = new AbortController()

  const isCookieBannerClosed = () => {
    const optanonCookieClosed = useCookie(OPTANON_CLOSED_COOKIE_NAME)
    return !!optanonCookieClosed.value
  }

  // If client side tracking is allowed we don't need to do server-side tracking
  const isClientSideTrackingAllowed = () => {
    const optanonCookieConsent = useCookie(OPTANON_CONSENT_COOKIE_NAME)

    const isStatisticsAllowed = isTrackingAllowed(
      deserializeOptanonConsent(optanonCookieConsent.value ?? ''),
      TRACKING_GROUP.STATISTICS,
    )

    if (isCookieBannerClosed() && isStatisticsAllowed) {
      // Consent for browser side tracking is given...
      // just skip server side tracking
      return true
    }
  }

  // Compose the tracking event and send it to the SST API endpoint
  const sendTrackingEvent = ({
    pageType,
    referrer,
    fullPath,
    cbdToken,
  }: {
    pageType: string
    referrer: string
    fullPath: string
    cbdToken?: string
  }) => {
    const path = fullPath
    const language = getIsoLanguage($currentShop.locale).toLowerCase()
    const country = getIsoCountry($currentShop.locale).toLowerCase()

    const pageTitle = document.title
    const data = {
      baseUrl: location.origin,
      path,
      referrer,
      language,
      country,
      pageType,
      pageTitle,
      cbdToken,
      isBotTraffic: pageState.value.isBotTraffic,
    }

    // To avoid duplicate tracking of error pages never track internal redirect to error page
    if (pageType !== 'error') {
      const blob = new Blob([JSON.stringify(data)], {
        type: 'application/json',
      })
      navigator.sendBeacon('/api/dt/report', blob)
    }
  }

  // When navigating to a new page, send tracking event from the client to SST
  // API endpoint, but only if client side tracking was rejected by the user
  let timeout: ReturnType<typeof setTimeout>
  const router = useRouter()
  router.afterEach((to) => {
    if (isClientSideTrackingAllowed() || !isCookieBannerClosed()) {
      return
    }

    const cbdToken = to.query.cbd as string
    if (timeout) {
      clearTimeout(timeout)
    }

    // Remove listeners to prevent duplicate page view events
    listenersController.abort()
    timeout = setTimeout(() => {
      sendTrackingEvent({
        fullPath: to.fullPath,
        referrer: document.referrer,
        pageType: (to.meta?.pageType as unknown as string) ?? '',
        ...(cbdToken && { cbdToken }),
      })
    }, 1000)
  })

  // Handling events when Cookie Consent Banner is closed and tab visibility changes
  nuxtApp.hook('vue:setup', () => {
    const { pageState } = usePageState()
    const route = useRoute()

    const sendEvent = (e: Event) => {
      if (
        e.type === 'visibilitychange' &&
        document.visibilityState === 'visible'
      ) {
        return
      }

      if (isClientSideTrackingAllowed() || listenersController.signal.aborted) {
        return
      }

      const cbdToken = route.query.cbd as string

      // Remove listeners to prevent duplicate page view events
      listenersController.abort()

      sendTrackingEvent({
        fullPath: route.fullPath,
        referrer: document.referrer,
        pageType: pageState.value.pageType,
        ...(cbdToken && { cbdToken }),
      })
    }

    addEventListener(
      'cookie-banner-closed',
      (event: Event) => sendEvent(event),
      {
        once: true,
        signal: listenersController.signal,
      } as AddEventListenerOptions,
    )

    addEventListener(
      'visibilitychange',
      (event: Event) => {
        const optanonCookieClosed = useCookie(OPTANON_CLOSED_COOKIE_NAME)
        // tracking on this event should only be triggered when cookiebox is not closed yet
        if (!optanonCookieClosed.value) {
          sendEvent(event)
        }
      },
      {
        once: true,
        signal: listenersController.signal,
      } as AddEventListenerOptions,
    )
  })
})
