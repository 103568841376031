<template>
  <footer class="w-full">
    <StoreFinderSearchBanner />
    <div class="bg-gray-700 pb-6 text-white">
      <div
        class="mx-auto flex w-full max-w-footer justify-center md:justify-end md:pr-16 lg:pr-[7.5rem] xl:pr-0"
      >
        <FimButton
          class="z-10 mt-[-22px] size-10 rounded-full bg-white"
          type="ghost"
          :no-padding="true"
          :aria-label="$t('global.scroll_up')"
          @click="scrollToTop"
        >
          <IconFielmannArrowUp class="size-5 fill-current text-black" />
        </FimButton>
      </div>
      <div class="mx-4 max-w-footer md:mx-8 xl:mx-auto">
        <div
          v-if="content"
          class="my-9 grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-4"
        >
          <component
            :is="column.component"
            v-for="column in content.Columns"
            :key="column.uuid"
            :blok="column"
          />
        </div>
        <div class="items-center justify-center gap-12 pt-4 sm:flex">
          <FooterLanguageSwitcher />
          <VatDisclaimer :tooltip-text="shippingInfo" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import type { SbFooter } from '~/storyblok/types/storyblok'

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const { data: cmsData } = await useCmsStory<SbFooter>({
  params: { slug: 'footer', baseFolder: CmsBaseFolder.Global },
})

const { data: customData } = await useCustomData()

const content = computed(() => cmsData?.value?.content)
const shippingInfo = computed(
  () =>
    customData.value?.freeShipping?.shippingInfoBoxPdp ?? 'some text goes here',
)
</script>
