<template>
  <component
    v-bind="{ ...$props, ...$attrs, ...(isExternalLink ? { href: to } : {}) }"
    :is="tag"
    :to="!isExternalLink && isLink ? to : null"
    :rel="tag === 'a' ? rel : null"
    class="group inline-flex cursor-pointer items-center gap-2 whitespace-nowrap rounded border fill-current text-base font-normal leading-[1.375rem] tracking-normal transition duration-100 ease-linear focus:outline-none disabled:pointer-events-none disabled:opacity-20"
    :aria-label="ariaLabel"
    :target="target"
    :class="{
      'justify-center': justify === 'center',
      'justify-start': justify === 'start',
      'justify-end': justify === 'end',
      'px-[11px] py-[9px]': !noPadding && !isSmall,
      'hover:text-primary-600 !border-0 text-primary': isGhost,
      'border-primary bg-primary text-white hover:border-neutral-90 hover:bg-neutral-90 ':
        isPrimary,
      'border-neutral-30 bg-white text-primary hover:border-neutral-50':
        isSecondary,
      'border-2 border-primary bg-transparent text-primary  hover:bg-[#ffffff22]':
        isPrimaryAlternative,
      'border-2 border-white bg-transparent text-white  hover:bg-[#ffffff22]':
        isSecondaryAlternative,
      'border-[#f5f6f7] bg-[#f5f6f7] hover:border-neutral-20 hover:bg-white disabled:bg-white':
        isTertiary,
      'border-[#dcdee0] bg-[#dcdee0] text-primary hover:border-neutral-20 hover:bg-white disabled:bg-white':
        isTertiaryAlternative,
      'border-transparent text-primary hover:bg-[#f5f6f7] hover:text-primary':
        isTypeLink,
      'type-underline hover:text-shadow text-md border-transparent text-primary':
        isTypeLinkAlternative,
      'w-full': isFullWidth,
      'animate-pulse': loading,
      'px-[6px] py-1 !text-sm': isSmall && !isTertiaryAlternative && !noPadding,
      'px-3 py-[6px] !text-sm': isSmall && isTertiaryAlternative && !noPadding,
      '!text-sm': isSmall && noPadding,
      'h-10 max-w-[136px] !border-none !p-0': isStoreButton,
    }"
    :disabled="disabled"
    :type="!isExternalLink ? htmlType : false"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
  >
    <template v-if="storeIconSrc">
      <NuxtImg
        provider="local"
        :src="storeIconSrc"
        alt=""
        loading="lazy"
        decoding="async"
      />
      <span class="sr-only"><slot /></span>
    </template>
    <template v-else>
      <slot name="icon" :class="iconClasses" />
      <span
        v-if="hasDefaultSlot"
        class="content pointer-events-none"
        :class="{ 'w-full text-center': isFullWidth }"
      >
        <slot />
      </span>

      <slot name="iconAfter" :class="iconClasses" />
      <slot name="badge" :badge="badge">
        <transition
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          enter-active-class="transition ease-linear duration-200"
          leave-active-class="transition ease-linear duration-200"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <span v-show="badge" :class="textColorClasses"> ({{ badge }}) </span>
        </transition>
      </slot>
    </template>
  </component>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components'
import type { ButtonType } from '#imports'

type Props = {
  type?: ButtonType
  htmlType?: '' | 'submit' | 'reset' | 'button'
  isLink?: boolean
  noPadding?: boolean
  to?: string | object
  disabled?: boolean
  isFullWidth?: boolean
  loading?: boolean
  badge?: number
  isSmall?: boolean
  target?: '_blank' | '_self' | '_top' | '_parent'
  ariaLabel?: string
  justify?: 'start' | 'center' | 'end'
  rel?: HTMLAnchorElement['rel']
}

defineEmits(['mouseenter', 'mouseleave'])

const props = withDefaults(defineProps<Props>(), {
  type: 'primary',
  htmlType: '',
  isLink: false,
  to: '#',
  noPadding: false,
  disabled: false,
  isFullWidth: false,
  loading: false,
  badge: 0,
  isSmall: false,
  justify: 'center',
  target: undefined,
  ariaLabel: undefined,
  rel: undefined,
})

const slots = useSlots()
const currentShop = useCurrentShop()

const isPrimary = computed(() => props.type === 'primary')
const isPrimaryAlternative = computed(
  () => props.type === 'primary-alternative',
)
const isSecondaryAlternative = computed(
  () => props.type === 'secondary-alternative',
)
const isSecondary = computed(() => props.type === 'secondary')
const isTertiary = computed(() => props.type === 'tertiary')
const isTertiaryAlternative = computed(
  () => props.type === 'tertiary-alternative',
)
const isGhost = computed(() => props.type === 'ghost')
const isTypeLink = computed(() => props.type === 'link')
const isTypeLinkAlternative = computed(() => props.type === 'link-alternative')

const iconClasses = computed(() => {
  return {
    'text-neutral-50': isSecondary.value || isTypeLink.value,
    'text-[#939599]': isTertiaryAlternative.value,
  }
})

const tag = computed(() => {
  if (
    props.isLink &&
    typeof props.to === 'string' &&
    (/:\/\//.test(props.to) || /mailto:/.test(props.to))
  ) {
    return 'a'
  } else if (props.isLink && props.to) {
    return NuxtLink
  }

  return 'button'
})

const isExternalLink = computed(() => tag.value === 'a')

const textColorClasses = computed(() => ({
  'text-white': isPrimary.value,
  'text-primary-100': isSecondary.value,
  'text-primary':
    isPrimaryAlternative.value || isTertiary.value || isGhost.value,
}))
const hasDefaultSlot = computed(() => !!slots.default)

// Play store and app store button variants
const isStoreButton = computed(() =>
  ['play-store', 'app-store'].includes(props.type),
)
const storeIconSrc = computed(() => {
  const lang = getIsoLanguage(currentShop.value.locale)
  if (isStoreButton.value) {
    return `/img/store-buttons/${props.type}-${lang}.svg`
  }

  return undefined
})

defineOptions({ name: 'FimButton' })
</script>
