import type {
  RpcMethodReturnType,
  RpcMethodName,
  // RpcMethods,
  RpcMethodParameters,
  RpcContext,
} from '@scayle/storefront-core'
import { hash, objectHash } from 'ohash'
import { rpcCall } from '@scayle/storefront-nuxt'
import type { AsyncDataOptions } from '#app'

export const useAsyncRpc = <
  // N extends RpcMethodName, // Allow an RPC Method or RPC Method string name
  // M extends N extends RpcMethodName ? N : KeysMatching<RpcMethods, N>, // Normalize to the RPC Method name
  // P extends RpcMethodParameters<M>, // Extract the parameters of the RPC Method
  // TResult extends N extends ValueOf<RpcMethods>
  //   ? Awaited<ReturnType<N>>
  //   : Exclude<Awaited<RpcMethodReturnType<M>>, Response>, // Extract the return type
  // TParams = P extends RpcContext ? undefined : P,
  //   TResult extends Exclude<Awaited<RpcMethodReturnType<N>>, Response>,
  // TParams = P extends RpcContext ? undefined : P,
  N extends RpcMethodName,
  P extends RpcMethodParameters<N>,
  TResult extends Exclude<Awaited<RpcMethodReturnType<N>>, Response>,
  TParams = P extends RpcContext ? undefined : P,
>(
  method: N,
  params?: MaybeRefOrGetter<TParams>,
  options: AsyncDataOptions<TResult> = {},
) => {
  const nuxtApp = useNuxtApp()
  const { $currentShop } = nuxtApp

  const wrappedCall = rpcCall(nuxtApp, method, $currentShop)
  const key = `${$currentShop?.shopId}:${method}:${hash(objectHash(toValue(params)))}`

  return useAsyncData(
    key,
    async () => await wrappedCall(toValue(params) as P),
    { dedupe: 'defer', ...options },
  )
}
