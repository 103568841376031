import type { RouteLocationRaw } from '#vue-router'

export enum Action {
  confirm = 'CONFIRM',
  warning = 'WARNING',
  error = 'ERROR',
  reload = 'RELOAD',
  route = 'ROUTE',
}

export interface ActionHandler {
  text: string
  class?: string
  href?: RouteLocationRaw
  onClick?: (_: Event, toastObject: { close: () => void }) => void
}
export interface Alert {
  id: number
  message: string
  duration: number
  actions: ActionHandler[]
}

export default defineNuxtPlugin(() => {
  const { $i18n } = useNuxtApp()
  const alerts = ref<Alert[]>([])
  const actionClasses = 'font-normal text-transform-unset'
  const defaultAction = {
    class: actionClasses,
    text: $i18n.t('toast.ok'),
    onClick: (_: Event, toastObject: ToastObject) => toastObject.close(),
  }

  const getAction = (action: Action, to?: RouteLocationRaw) => {
    const actions = {
      CONFIRM: [defaultAction],
      ERROR: [defaultAction],
      WARNING: [defaultAction],
      ROUTE: [
        {
          class: actionClasses,
          text: $i18n.t('toast.view'),
          href: to,
        },
      ],
      RELOAD: [
        {
          class: actionClasses,
          text: $i18n.t('toast.reload'),
          onClick: (_: Event, toastObject: ToastObject) => toastObject.close(),
        },
        {
          class: actionClasses,
          text: $i18n.t('toast.close'),
          onClick: () => Promise.resolve(refreshNuxtData()),
        },
      ],
    }

    return actions[action]
  }

  const show = (message: string, action: Action, to?: RouteLocationRaw) => {
    alerts.value.push({
      id: Date.now(),
      message,
      duration: 5000,
      actions: getAction(action, to),
    })
  }

  const close = (toastId: number) => {
    alerts.value = alerts.value.filter(({ id }) => id !== toastId)
  }

  return {
    provide: {
      alert: {
        alerts,
        show,
        close,
      },
    },
  }
})
