import { type Loader } from '@googlemaps/js-api-loader'
import type { IStore } from '~/rpcMethods/stores'

/**
 * @todo deprecated
 */
export type MarkerOptions = google.maps.MarkerOptions & {
  store: IStore
  anchor: string
  noClick: boolean
  active?: boolean
}

type LatLngBounds = google.maps.LatLngBounds

const markers = ref<MarkerOptions[]>([])
const latLngBounds = ref<LatLngBounds>()
const mapsLoader = ref<Loader>()

export const useMapMarker = () => {
  const addMarker = (marker: MarkerOptions) => {
    markers.value?.push(marker)
  }

  const resetMarkers = () => {
    markers.value = []
  }

  const setLatLngBounds = async (l: Loader) => {
    mapsLoader.value = l
    const { LatLngBounds } = await mapsLoader.value.importLibrary('core')
    latLngBounds.value = new LatLngBounds()
  }

  const resetLatLngBounds = async () => {
    if (mapsLoader.value) {
      const { LatLngBounds } = await mapsLoader.value.importLibrary('core')
      latLngBounds.value = new LatLngBounds()
    }
  }

  return {
    markers,
    latLngBounds,
    mapsLoader,
    addMarker,
    resetMarkers,
    setLatLngBounds,
    resetLatLngBounds,
  }
}
