import {
  getItemQuantityFromOrder,
  type Order,
  type WishlistResponseData,
  type BasketResponseData,
  getLatestCategory,
  getOriginalPrice,
} from '@scayle/storefront-nuxt'
import type { NuxtApp } from 'nuxt/app'
import type {
  EcommerceData,
  EcommerceItem,
  SearchResultData,
} from '../composables/tracking/trackingTypes'
import {
  getFloatedReducedPriceForCategoryOrNull,
  getProductCategoryTrackingValues,
} from '../composables/tracking/helpers'
import { categoryTrackingAttributes } from '../composables/tracking/helpers/trackingMaps'
import { groupItems } from './order'
import { isRxMainItem } from '~/utils/rx'
import { sumUpPrice } from '~/utils/price'
import type { ProductCategories } from '~/utils/product'
import useSearchCounter from '~/composables/tracking/useSearchCounter'

// TODO: Add tests

const toFloat = (price: number) => price / 100

/**
 * Compares data of type WishlistResponseData (wishlist or basket)
 * @param oldData previous data state (eg wishlist data {items, key})
 * @param newData new data state (eg wishlist data {items, key})
 * @returns boolean if a tracking significant change is detected
 */
export const didWishlistOrBasketDataChange = (
  oldData: BasketResponseData | WishlistResponseData | undefined,
  newData: BasketResponseData | WishlistResponseData | undefined,
): boolean => {
  return !isEqual(
    {
      items: oldData?.items.map((item) => ({
        productId: item.product?.id,
        variantId: item.variant?.id,
        price: item.product?.priceRange,
        quantity: (item as any).quantity,
        soldOut: item.product?.isSoldOut,
      })),
      key: oldData?.key,
    },
    {
      items: newData?.items.map((item) => ({
        productId: item.product?.id,
        variantId: item.variant?.id,
        price: item.product?.priceRange,
        quantity: (item as any).quantity,
        soldOut: item.product?.isSoldOut,
      })),
      key: newData?.key,
    },
  )
}

export const getEmailHash = async (email: string | undefined) => {
  if (!email) {
    return ''
  }
  const hashBuffer = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(email.replace(/ /g, '')?.toLowerCase()),
  )
  return Array.from(new Uint8Array(hashBuffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
}

export const formatPrice = (
  value: number,
  locale: string,
  currency: string,
): string => {
  const currencyFractionDigits = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).resolvedOptions().maximumFractionDigits

  return toFloat(value).toLocaleString(locale, {
    minimumFractionDigits: currencyFractionDigits,
    useGrouping: false,
  })
}

export const mapCbdDataToEcommerceData = (
  cbdData: Order,
  currency: string,
  i18n: NuxtApp['$i18n'],
): EcommerceData['ecommerce'] => {
  const shippingCost = cbdData.cost?.appliedFees?.find(
    ({ category }) => category === 'delivery',
  )

  const distinctGroupedItems = groupItems(cbdData.items)?.filter(
    (item, index, arr) =>
      arr.findIndex((t) => t.variant.id === item.variant.id) === index,
  )

  const result = {
    transaction_id: `${cbdData.id}`,
    customer_id: `${cbdData!.customer!.id}`,
    value: +(cbdData.cost.withoutTax / 100).toFixed(2),
    value_gross: +(cbdData.cost.withTax / 100).toFixed(2),
    tax: +formatPrice(cbdData.cost.tax.vat?.amount || 0, 'en-EN', currency),
    shipping: +formatPrice(
      shippingCost?.amount.withoutTax || 0,
      'en-EN',
      currency,
    ),
    currency,
    payment: cbdData.payment?.[0].key || '',
    items:
      distinctGroupedItems?.map(
        (basketItem: any, index: number): EcommerceItem => {
          const price = isRxMainItem(basketItem)
            ? sumUpPrice([
                basketItem.price,
                ...basketItem.customData.items.map((item: any) => item.price),
              ])
            : basketItem.price

          const itemCategories = getProductCategoryTrackingValues(
            basketItem.product,
            i18n,
            basketItem.variant,
          )
          return {
            price_gross: +(cbdData.cost.withTax / 100).toFixed(2),
            sale_discount: getFloatedReducedPriceForCategoryOrNull(
              price,
              'sale',
            ),
            campaign_discount: getFloatedReducedPriceForCategoryOrNull(
              price,
              'campaign',
            ),
            original_price: toFloat(
              price?.appliedReductions?.length
                ? getOriginalPrice(price)
                : price.withTax,
            ),

            item_category_id:
              getLatestCategory(
                basketItem.product.categories,
              )?.categoryId.toString() || '',
            item_id: `${basketItem.product.id}`,
            item_name: basketItem.product.name,
            price: +formatPrice(price.withoutTax, 'en-EN', currency),
            item_brand: basketItem.product.attributes?.brand?.values?.label,
            item_brand_id: `${basketItem.product.attributes?.brand?.values?.id}`,
            item_variant: `${basketItem.variant.id}`,
            quantity:
              getItemQuantityFromOrder(cbdData, basketItem.variant.id) || 0,
            index: index + 1,
            ...itemCategories,
          }
        },
      ) ?? [],
  }

  if (cbdData.confirmedAt) {
    const orderTimestamp = new Date(cbdData.confirmedAt).getTime().toString()
    return { ...result, orderTimestamp }
  }

  return result
}

export const addTrackingAttributesToQuery = (
  attributes: string[],
  category?: ProductCategories,
): string[] => {
  let trackingAttributes: string[]
  if (!category) {
    trackingAttributes = Object.values(categoryTrackingAttributes).flatMap(
      (trackingAttribute) => trackingAttribute,
    )
  } else {
    trackingAttributes = categoryTrackingAttributes[category]
  }
  const attributesWihoutDuplicates = new Set([
    ...attributes,
    ...trackingAttributes,
  ])
  return Array.from(attributesWihoutDuplicates.values())
}

export const buildSearchData = (
  searchTerm: string,
  searchResultsProducts: number = 0,
  searchResultsCategories: number,
  searchResultsPages: number = 1,
  location: 'search_results_flyout' | 'search_results_page',
  searchDestination?: boolean,
): SearchResultData => {
  const { searchCounter } = useSearchCounter()

  return {
    search: {
      search_term: searchTerm,
      search_results_products: searchResultsProducts,
      search_results_categories: searchResultsCategories,
      search_results_pages: searchResultsPages,
      search_count: searchCounter.value,
      search_location: location,
      search_destination: searchDestination
        ? `/search/?term=${searchTerm}`
        : undefined,
    },
  }
}
