export const useGlassesLenses = () => {
  const { rxLensQualityIndex, fetch } = useRxProducts()

  const lensesVariants = computed(() => {
    return (
      rxLensQualityIndex.value?.map((i) => {
        const variant = i.variants || []
        return variant[0]
      }) || []
    )
  })

  return {
    fetch,
    lensesVariants,
    lensesProducts: rxLensQualityIndex,
  }
}
